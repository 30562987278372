import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './../../../services/auth.service';
import { DadosLogin } from './../../../model/dados-login.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-redefinicao-senha',
  templateUrl: './redefinicao-senha.component.html',
  styleUrls: ['./redefinicao-senha.component.scss']
})
export class RedefinicaoSenhaComponent implements OnInit {

  @Input() autocomplete: string = 'off';

  dadosLogin: DadosLogin = new DadosLogin();
  mensagemErroForm: string = "";
  loading = true;
  habilitadoRedefinirSenha = false;
  showMessagemRedirecionarTelaLogin = false;
  mensagemErro!: string;

  login!: string;
  code!: string;
  senha!: string;
  novaSenha!: string;

  constructor(
    private jwtService: AuthService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params: any) => {
      this.login = params.login;
      this.code = params.code;
      this.loading = true;
      this.jwtService.redefinicaoSenha(params.login, params.code).subscribe({
        next: (response) => {
          if (response.successfully) {
            this.habilitadoRedefinirSenha = true;
          } else {
            this.habilitadoRedefinirSenha = false;
            this.mensagemErro = response.result;
            if (this.mensagemErro == "Ocorreu erro: null") {
              this.mensagemErro = "Link de redefinição de senha já utilizado.";
            }
          }
        },
        error: (error) => {
          this.habilitadoRedefinirSenha = false;
          this.mensagemErro = error.message;
        }
      }).add(() => {
        this.loading = false;
      });
    });
  }

  verificarNovaSenha() {
    const senha = this.dadosLogin.password!;

    // Regras de validação
    const temMinuscula = /[a-z]/.test(senha);
    const temMaiuscula = /[A-Z]/.test(senha);
    const temNumero = /\d/.test(senha);
    const temEspecial = /[!@#$%^&*(),.?":{}|<>]/.test(senha);
    const tamanhoValido = senha.length >= 8;

    // Atualiza a lista de requisitos
    this.atualizarRequisito('minusc', temMinuscula);
    this.atualizarRequisito('maiusc', temMaiuscula);
    this.atualizarRequisito('numerico', temNumero);
    this.atualizarRequisito('especial', temEspecial);
    this.atualizarRequisito('tamanho', tamanhoValido);
  }

  private atualizarRequisito(id: string, valido: boolean) {
    const item = document.getElementById(id);
    let icone = document.getElementById('icone' + id.charAt(0).toUpperCase() + id.slice(1));

    if (valido && item && icone) {
      item.classList.remove('inativo');
      item.classList.add('ativo');
      icone.innerHTML = '<i class="pi pi-check"></i>';

    } else {
      item?.classList.remove('ativo');
      item?.classList.add('inativo');
      icone!.innerHTML = '<i class="pi pi-times"></i>';
    }
  }

  todosRequesitosAtendidos(): boolean {
    const requisitos = ['minusc', 'maiusc', 'numerico', 'especial', 'tamanho'];
    const todosAtendidos = requisitos.every(id => {
      if (document.getElementById(id)) {
        return document.getElementById(id)!.classList.contains('ativo');
      }
      return false;
    });
    return todosAtendidos;
  }

  validarNovaSenha () {
    if (this.validarCamposParaTrocaSenha()) {
      this.jwtService.redefiniSenha(this.login, this.code, this.dadosLogin.password!).subscribe((data: any) => {
        if (data.successfully) {
          this.showMessagemRedirecionarTelaLogin = true;
        } else {
          this.mensagemErroForm = data.result;
        }
      });
    }
  }

  private validarCamposParaTrocaSenha(): boolean {
    var senha = this.dadosLogin.password;
    var novaSenha = this.dadosLogin.newPassword;
    if (!senha || !novaSenha) {
      this.mensagemErroForm = "Campos devem estar preenchidos.";
      return false;
    }
    if (senha != novaSenha) {
      this.mensagemErroForm = "As senhas não coincidem";
      return false;
    }
    return true;
  }

  irParaLogin() {
    this.router.navigate(['login']);
  }
}
