<div *ngIf="isEnableCtePage; else notEnabledCte">
  <p-contextMenu #cm [model]="itemsContextMenu" (onHide)="removeHighlightContextmenu()"></p-contextMenu>

  <div class="container">
    <div class="container-width">
      <!-- TITULO -->
      <div class="d-flex flex-row align-items-baseline" style="margin-top: -10px;">
        <div class="flex-grow-1">
          <div style="width: 200px;">
            <h4 style="line-height: 2">
              CT-e
            </h4>
          </div>
        </div>
        <div style="margin-top: -10px;">
          <button pButton class="p-button-rounded p-button-text me-5 ms-4" pTooltip="Recarrega dados da tabela" [showDelay]="500" (click)="refresh()">
            <i class="pi pi-refresh"></i>
          </button>
        </div>
      </div>

      <!-- Botões de ações -->
      <div class="d-flex mt-4">
        <div class="flex-grow-1">
          <div class="d-flex flex-row">
            <div>
              <p-inputGroup>
                <span class="p-float-label">
                  <input
                    id="filtroChaveAcessoNfe"
                    pInputText
                    style="width: 370px;"
                    [(ngModel)]="cteRequest.chaveAcesso" />
                  <label for="filtroChaveAcessoNfe">Chave de acesso</label>
                </span>
                <button type="button" pButton icon="pi pi-times" class="p-button-outlined p-button-secondary button-grouped" (click)="limpaFiltro('chaveAcesso')"></button>
              </p-inputGroup>
            </div>
            <div>
              <p-button icon="pi pi-search" [rounded]="true" [text]="true" [raised]="true" severity="info" class="ms-4" (onClick)="onClickFiltrarChaveAcesso()"></p-button>
            </div>
          </div>
        </div>
        <div class="flex-grow-1"></div>
        <div>
          <div class="d-flex">
            <div class="margin-r-3">
              <button pButton type="button" icon="pi pi-cloud-download" label="Busca novas notas" class="p-button-outlined botao-custom"
                (click)="consultaWebserviceNfeDistribuicaoCte()" [disabled]="selectedEmpresa == undefined">
              </button>
            </div>
            <div class="me-2">
              <button pButton type="button" class="p-button-rounded p-button-secondary" pTooltip="Download das notas selecionadas" tooltipPosition="top"
                icon="pi pi-download" (click)="downloadCteSelecionadas()" [disabled]="selectedRows.length == 0">
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-3">
        <!-- Tabela com dados -->
        <p-table #dt
          class="table-dfe"
          [value]="ctePage.content"
          [lazy]="true"
          (onLazyLoad)="lazyLoad($event)"
          [totalRecords]="ctePage.totalElements"
          [(selection)]="selectedRows"
          dataKey="chaveAcesso"
          [showCurrentPageReport]="true"
          [rowsPerPageOptions]="pageSizes"
          [rows]="pageSizes[0]"
          [loading]="loading"
          [paginator]="true"
          [currentPageReportTemplate]="ctePage.totalElements === 0 ? 'Sem dados' : '{first} até {last} de ' + ctePage.totalElements + ' entradas'"
          [scrollable]="true"
          scrollHeight="calc(100% - 200px)"
          [contextMenu]="cm"
          [(contextMenuSelection)]="selectedCteContextMenu">
          <!-- HEADER -->
          <ng-template pTemplate="header">
            <tr>
              <th style="max-width:50px">
                <div style="padding-left: 5px; padding-top: 5px; cursor: pointer;"
                  pTooltip="Seleciona os itens da página"
                  tooltipPosition="left" [showDelay]="1000" [hideDelay]="300"
                  (click)="onClickSelectedAllRows()">
                  <i class="bi bi-check-all" style="font-size: 1.5rem; color: #999999;"></i>
                </div>
              </th>
              <th pSortableColumn="chaveAcesso" style="min-width: 372px">
                Chave de acesso
                <p-sortIcon field="chaveAcesso"></p-sortIcon>
              </th>
              <th pSortableColumn="numero" style="min-width: 100px">
                Número
                <p-sortIcon field="numero"></p-sortIcon>
              </th>
              <th pSortableColumn="nsu" style="min-width: 100px">
                NSU
                <p-sortIcon field="nsu"></p-sortIcon>
              </th>
              <th pSortableColumn="dataEmissao" style="min-width: 100px">
                Data Emissão
                <p-sortIcon field="dataEmissao"></p-sortIcon>
              </th>
              <th style="min-width:100px">
                Tipo
              </th>
              <th style="min-width:125px" pSortableColumn="origem">
                Origem
                <p-sortIcon field="origem"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <!-- BODY -->
          <ng-template pTemplate="body" let-cte>
            <tr [pContextMenuRow]="cte" class="table-row-body">
              <td style="max-width:50px">
                <p-tableCheckbox [value]="cte" class="ml-1"></p-tableCheckbox>
              </td>
              <td>{{cte.chaveAcesso}}</td>
              <td style="min-width:100px">{{cte.numero}}</td>
              <td style="min-width:100px">{{cte.nsu}}</td>
              <td style="min-width:100px">
                {{cte.dataEmissao | date:'dd/MM/yyyy' }}&nbsp;<span style="color: rgb(165, 165, 165); font-size: 12px;">{{cte.dataEmissao | date:'HH:mm:ss' }}</span>
              </td>
              <td style="min-width:100px">Completa</td>
              <td style="min-width:100px;">{{cte.origem}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div *ngIf="ctePage.totalElements > 0">
        <b>Selecionadas:</b> {{selectedRows.length}}
      </div>
    </div>
  </div>
</div>

<ng-template #notEnabledCte>
  <div class="centered-container">
    <div class="centered-content">
      <img src="assets/images/danger.png" alt="Danger">
      <p>Módulo <b>CT-e</b> não habilitado para essa empresa</p>
    </div>
  </div>
</ng-template>

<p-toast position="bottom-center"></p-toast>

<!-- Modal DACTE -->
<p-dialog
  header="DACTE"
  [modal]="false"
  [(visible)]="showModalDanfe"
  [breakpoints]="{'960px': '75vw'}"
  [style]="{width: '85vw', height: '90vw'}"
  [draggable]="false"
  [resizable]="false">
  <div [ngClass]="{'h-100' : !showProgressSpinModalDanfe, 'd-none': showProgressSpinModalDanfe}">
    <iframe id="pdfViewer" [src]="base64Pdf" width="100%" height="100%"></iframe>
  </div>
  <div class="d-flex align-items-center justify-content-center h-100" *ngIf="showProgressSpinModalDanfe">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-dialog>
