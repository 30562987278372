<div class="container">
  <div class="container-width">
    <!-- TITULO E BOTOES DE ACOES -->
    <div class="d-flex flex-row titulo-btn-acoes">
      <div class="flex-grow-1">
        <h4>Minhas empresas</h4>
      </div>
      <div>
        <button pButton class="p-button-rounded p-button-text me-5 ms-4" pTooltip="Recarrega página" [showDelay]="500" (click)="refresh()">
          <i class="pi pi-refresh"></i>
        </button>
      </div>
    </div>

    <div class="mt-4"></div>

    <div class="d-flex flex-row justify-content-between">
      <div *ngIf="loggedUser?.clienteTipo == ClienteTipo.SUPORTE">
        <div class="d-flex justify-content-end mb-2 mt-2">
          <button pButton
            icon="pi pi-plus"
            label="empresa"
            class="p-button-outlined p-button-sm"
            pTooltip="Adiciona uma nova empresa"
            [showDelay]="500"
            (click)="openModalCadastroEmpresa(false)">
          </button>
        </div>
      </div>
    </div>

    <div class="mt-1">
      <!-- Tabela com dados das empresas do cliente logado -->
      <p-table #dt
        class="table-dfe"
        [value]="empresas.content"
        dataKey="id"
        [rows]="10"
        [showCurrentPageReport]="empresas.totalElements > 0"
        [rowsPerPageOptions]="[10, 15, 20, 50, 100]"
        [loading]="loading"
        [paginator]="empresas.totalElements > 0"
        currentPageReportTemplate="{{'Exibindo'}} {first} {{'para'}} {last} {{'de'}} {{empresas.content ? empresas.content.length : ''}} {{'entradas'}}"
        [scrollable]="true"
        scrollHeight="calc(100% - 200px)"
        [globalFilterFields]="['id', 'nome']">
        <!-- SEARCH -->
        <ng-template pTemplate="caption">
          <div class="flex">
            <span class="p-input-icon-left ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" [style]="{width: '300px'}" (input)="procura($event)" placeholder="Procura pelo CNPJ/CPF ou nome" />
            </span>
          </div>
        </ng-template>
        <!-- HEADER -->
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{'width': '175px'}">CNPJ</th>
            <th [style]="{'width': 'auto'}">Nome</th>
            <th [ngStyle]="{'width': '62px', 'text-align': 'center'}">
              Ativa
            </th>
            <th [ngStyle]="{'width': '152px', 'text-align': 'center'}">
              Validade do certificado
            </th>
            <th [ngStyle]="{'width': '66px'}"></th>
          </tr>
        </ng-template>
        <!-- BODY -->
        <ng-template pTemplate="body" let-empresa>
          <tr class="table-row-body">
            <td>{{empresa.id | mask : "00.000.000/0000-00"}}</td>
              <td>{{empresa.nome}}</td>
              <td>
                <div class="d-flex justify-content-center">
                  <div *ngIf="empresa.ativa == true" class="ativa" pTooltip="Ativa"></div>
                  <div *ngIf="empresa.ativa == false" class="ativa inativa" pTooltip="Inativa"></div>
                </div>
              </td>
              <td>
                <div
                  class="d-flex justify-content-center"
                  style="text-align: center;"
                  [ngStyle]="{
                    'color': !empresa.certificado?.validoAte ? 'gray' : (dataVencida(empresa.certificado) ? 'red' : 'black'),
                    'font-weight': dataVencida(empresa.certificado) ? '600' : 'normal'
                  }">
                  {{empresa.certificado?.validoAte ? (empresa.certificado?.validoAte | date : 'dd/MM/yyyy') : 'Sem certificado' }}
                </div>
              </td>
              <td>
                <div class="d-flex flex-row" *ngIf="loggedUser?.clienteTipo == ClienteTipo.SUPORTE">
                  <button pButton class="p-button-rounded p-button-text" pTooltip="Edita empresa" [showDelay]="500" (click)="editaEmpresa(empresa)">
                    <i class="pi pi-pencil"></i>
                  </button>
                </div>
                <div class="d-flex flex-row" *ngIf="loggedUser?.clienteTipo == ClienteTipo.CLIENTE">
                  <button pButton class="p-button-rounded p-button-text" pTooltip="Visualiza o cadastro da empresa" [showDelay]="500" (click)="editaEmpresa(empresa); somenteVisualizar = true;">
                    <i class="pi pi-eye"></i>
                  </button>
                </div>
              </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <td colspan="8" class="p-3 bg-white" *ngIf="!(empresas.totalElements > 0)">
            <span class="d-flex justify-content-center">Nenhuma empresa encontrada</span>
          </td>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<!-- Modal - Cadastro Empresa -->
<p-dialog
  [modal]="true"
  [(visible)]="showModalEmpresa"
  styleClass="modalSize"
  [draggable]="false"
  [resizable]="false"
  [closable]="false">
  <ng-template pTemplate="header">
    <b>{{empresa?.id ? empresa?.nome: "Cadastro de nova empresa"}}</b>
  </ng-template>
  <app-form-cadastro-empresa
    [(empresa)]="empresa"
    [somenteVisualizar]="somenteVisualizar"
    (onClickCancelar)="closeModalEmpresaContador()"
    (onClickSalvar)="salvarEmpresa($event)">
  </app-form-cadastro-empresa>
</p-dialog>
