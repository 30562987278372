import { PageRequest } from './page-request.model';

export class DfeRequest extends PageRequest {

  cnpjCpfDestinatario: String | undefined;
  cnpjCpfEmitente: String | undefined;
  chaveAcesso!: String;
  nsu!: String;
  dataEmissaoInicioStr?: string;
  dataEmissaoFimStr?: string;
  dataInicio: Date | undefined;
  dataFim: Date | undefined;
  numNotaDe!: number | string;
  numNotaAte!: number | string;
}
