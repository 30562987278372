export class Page<T> {

  public content!: T[];
  public totalElements!: number;
  public totalPages!: number;
  public size!: number;
  public number!: number;
  public first!: Boolean;
  public last!: Boolean;
  public empty!: Boolean;
  public sort!: Sort;
}

export class Sort {
  sorted!: boolean;
  unsorted!: boolean;
  empty!: boolean;
}
