<div class="corpo">

  <div class="lado-titulo">
    <div class="titulo">
      Redefinição de senha
    </div>
  </div>

  <div class="lado-redefinicao-senha">

    <div class="box-redefinicao">

      <div *ngIf="!loading">

        <div *ngIf="habilitadoRedefinirSenha">

          <div *ngIf="!showMessagemRedirecionarTelaLogin">
            <form id="myform" autocomplete="off" (ngSubmit)="validarNovaSenha()">
              <div class="mt-4">
                <div class="field">
                  <label for="txtNovaSenha" class="block">Nova senha</label>
                  <p-password
                    autocomplete="off"
                    id="txtNovaSenha"
                    [(ngModel)]="dadosLogin.password"
                    [ngModelOptions]="{standalone: true}"
                    [toggleMask]="true"
                    [feedback]="false"
                    (input)="verificarNovaSenha()">
                  </p-password>
                </div>
                <div class="mt-2" style="line-height: 1.5">
                  <ul class="pl-2 ml-2 mt-0" style="list-style-type: none; line-height: 1.5;">
                    <li id="minusc" class="inativo"><span class="icone" id="iconeMinusc"><i class="pi pi-times"></i></span>Pelo menos uma letra minúscula</li>
                    <li id="maiusc" class="inativo"><span class="icone" id="iconeMaiusc"><i class="pi pi-times"></i></span>Pelo menos uma letra maiúscula</li>
                    <li id="numerico" class="inativo"><span class="icone" id="iconeNumerico"><i class="pi pi-times"></i></span>Pelo menos um número</li>
                    <li id="especial" class="inativo"><span class="icone" id="iconeEspecial"><i class="pi pi-times"></i></span>Pelo menos um caractere especial</li>
                    <li id="tamanho" class="inativo"><span class="icone" id="iconeTamanho"><i class="pi pi-times"></i></span>No mínimo 8 caracteres</li>
                  </ul>
                </div>
                <div class="field">
                  <label for="txtConfirmaNovaSenha" class="block">Confirma nova senha</label>
                  <p-password
                    autocomplete="off"
                    id="txtConfirmaNovaSenha"
                    [(ngModel)]="dadosLogin.newPassword"
                    [ngModelOptions]="{standalone: true}"
                    [toggleMask]="true"
                    [feedback]="false">
                  </p-password>
                </div>
                <div class="pt-4 w-100">
                  <button
                    pButton
                    id="btnEntrar"
                    type="submit"
                    class="p-button-primary"
                    [disabled]="!todosRequesitosAtendidos()">
                    &nbsp;&nbsp;&nbsp;Salvar&nbsp;&nbsp;&nbsp;
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div *ngIf="showMessagemRedirecionarTelaLogin">
            <div>
              <div class="pt-2 me-5">
                <h4 class="mt-3" style="color: var(--cor-principal)">Senha redefinida com sucesso</h4>
                <h5 class="text-black-50 mt-1" style="margin-left: -17px">
                  <button pButton pRipple type="button" label="Clique aqui para ir para a tela de Login" class="p-button-text" (click)="irParaLogin()"></button>
                </h5>
              </div>
            </div>
          </div>

        </div>

        <div *ngIf="!habilitadoRedefinirSenha" class="me-5">
          <div class="pt-2 me-5">
            <span [ngStyle]="{color: 'var(--red-400)', fontSize: '16px'}"><b>Falha:</b></span>
            <h5 class="mt-4" [ngStyle]="{color: 'var(--gray-500)', fontSize: '16px'}">{{mensagemErro}}</h5>
            <div style="margin-left: -10px; margin-top: 10px;">
              <button
                pButton
                pRipple
                type="button"
                label="Clique aqui para ir para a tela de Login"
                class="p-button-text"
                (click)="irParaLogin()">
              </button>
            </div>
          </div>
        </div>

      </div>

      <div *ngIf="loading" class="mt-5 pt-4">
        <p-progressSpinner strokeWidth="4"></p-progressSpinner>
      </div>

      <div class="mt-3" style="height: 2rem;">
        <small *ngIf="mensagemErroForm != ''" class="p-error block">{{mensagemErroForm}}</small>
      </div>

    </div>

  </div>

  <!-- Mensagem via Service -->
  <p-toast position="bottom-center" [style]="{'width':'800px'}"></p-toast>

</div>
