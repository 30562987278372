import { DfeRequest } from '../model/dfe-request.model';
import { DFeResponse } from '../model/dfe-response.model';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Page } from 'src/model/page.model';
import { DfeCteSefazRequest } from 'src/model/dfe-cte-sefaz-request.model';
import { UploadRequest } from 'src/model/upload-request';
import { DownloadNotasGeralRequest } from 'src/model/download-notas-geral.request';

const ENDPOINT_CONSULTA_SEFAZ_CTE = environment.API_MAIN + "/cte/consulta-sefaz";
const ENDPOINT_GET_NSU_CONSULTADAS = environment.API_MAIN + "/cte/consulta";
const ENDPOINT_DOWNLOAD_NSU_CONSULTADAS = environment.API_MAIN + "/cte/download";
const ENDPOINT_DOWNLOAD_NSU_CONSULTADAS_LOTE = environment.API_MAIN + "/cte/download-lote";
const ENDPOINT_CONSULTA_CTE_RELATORIO = environment.API_MAIN + "/cte/relatorio";
const ENDPOINT_GET_PDF_CTE = environment.API_MAIN + "/cte/dacte/pdf/"
const ENDPOINT_UPLOAD_CTE = environment.API_MAIN + "/cte/upload-xml"
const ENDPOINT_DOWNLOAD_PACOTE_CTE = environment.API_MAIN + "/cte/pacote-cte"

@Injectable({
  providedIn: 'root'
})
export class CteService {

  constructor(private http: HttpClient) {}

  /*
    Obtem o status da solicitação de consulta NSU de interesse
  */
  public consultaSefaz(request: DfeCteSefazRequest): Observable<void> {
    let params: URLSearchParams = this.getParams(request);
    const url = `${ENDPOINT_CONSULTA_SEFAZ_CTE}?${params.toString()}`;
    return this.http.get<void>(url);
  }

  /*
    Obtem a lista de NSU consultadas
  */
  public consultaCte(request: DfeRequest): Observable<Page<DFeResponse>> {
    let params: URLSearchParams = this.getParams(request);
    const url = `${ENDPOINT_GET_NSU_CONSULTADAS}?${params.toString()}`;
    return this.http.get<Page<DFeResponse>>(url);
  }

  /*
    Download da NFe contida no banco de dados
  */
  public downloadCte(id: String): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.post<HttpResponse<Blob>>(ENDPOINT_DOWNLOAD_NSU_CONSULTADAS, id, options);
  }

  /*
    Download da NFe selecionadas contida no banco de dados
  */
  public downloadCteLote(ids: String[]): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.post<HttpResponse<Blob>>(ENDPOINT_DOWNLOAD_NSU_CONSULTADAS_LOTE, ids, options);
  }

   /*
    Download de todas os CTE por data
   */
  public downloadPacoteConhecimentoTransporteEletronico(request: DownloadNotasGeralRequest): Observable<HttpResponse<Blob>> {
      let options: {} = {
        responseType: 'blob',
        observe: 'response'
      }
      return this.http.post<HttpResponse<Blob>>(ENDPOINT_DOWNLOAD_PACOTE_CTE, request, options);
  }

  /*
    Faz o envio de uma lista de Arquivos
  */
  public sendUploadedXml(request: UploadRequest): Observable<void> {
    const formData = new FormData();
    if (request.arquivos?.length == 0 || request.idEmpresa == undefined || request.clienteLogin == undefined) {
      throw Error("Todos os campos da request devem ser preenchidos")
    } else {
      formData.append('idEmpresa', request.idEmpresa);
      formData.append('clienteLogin', request.clienteLogin);
      for (let i = 0; i < request.arquivos.length; i++) {
        formData.append('arquivos', request.arquivos[i]);
      }
    }
    return this.http.post<void>(ENDPOINT_UPLOAD_CTE, formData);
  }

  /*
    Obtem a lista de NFe para geração da planilha do relatório
  */
  public consultaCTeRelatorio(request: DfeRequest): Observable<Page<DFeResponse>> {
    let params: URLSearchParams = this.getParams(request);
    const url = `${ENDPOINT_CONSULTA_CTE_RELATORIO}?${params.toString()}`;
    return this.http.get<Page<DFeResponse>>(url);
  }

  public getDanfePdf(chaveAcesso: String): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.get<HttpResponse<Blob>>(ENDPOINT_GET_PDF_CTE + chaveAcesso, options);
  }

  public generateUrlGetPdf(chaveAcesso: String): string {
    return ENDPOINT_GET_PDF_CTE + chaveAcesso + "#zoom=100&view=FitH";
  }

  getParams(request: any): URLSearchParams {
    let params = new URLSearchParams();
    for (var propName in request) {
      if (!(propName == "dataInicio" || propName == "dataFim")) {
        if (request[propName]) {
          params.set(propName, request[propName]);
        }
      }
    }
    return params;
  }
}
