import { AuthService } from '../../../../services/auth.service';
import { Cliente } from 'src/model/cliente.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControleXMLService } from 'src/services/controle-xml.service';
import { MainComponent } from '../main.component';
import { DashboardReportDTO } from 'src/model/dashboard-report.model';
import { EmpresaPagedRequest } from 'src/model/empresa-paged-request.model';
import { EmpresaService } from 'src/services/empresas.service';

enum TipoDoc {
  NFSePrestada = "NFS-e prestadas",
  NFSeTomada = "NFS-e tomadas",
  NFe = "NF-e completas",
  CTe = "CT-e",
  CFe = "CF-e",
  PortalNacional = "Sistema Nacional NFS-e"
};

interface DashboardItem {
  cor: string;
  tipoDoc: TipoDoc;
  quantidadeDocs: number;
  valorTotal: number;
  conteudoCarregou: boolean;
};

interface PanelMenuItem {
  borderStyle?: string;
  id?: string;
  expanded?: boolean; //controla se itens filhos vao ser mostrados por padrao ou nao
  icon: string;
  textContent?: string;
  badgeContent?: number | undefined;
  items: PanelMenuItem[];
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, OnDestroy {

  userLogged!: Cliente;

  dashboardContent: DashboardItem[] = [
    {
      cor: "#fff875",
      tipoDoc: TipoDoc.PortalNacional,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    },
    {
      cor: "#d0e1fd",
      tipoDoc: TipoDoc.NFSePrestada,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    },
    {
      cor: "#caf1d8",
      tipoDoc: TipoDoc.NFSeTomada,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    },
    {
      cor: "#ffaca7",
      tipoDoc: TipoDoc.NFe,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    },
    {
      cor: "#ead6fd",
      tipoDoc: TipoDoc.CTe,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    },
    {
      cor: "#fcc39b",
      tipoDoc: TipoDoc.CFe,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    }
  ];

  conteudosCarregaram: boolean = false;

  panelMenuRootContent: PanelMenuItem[] = [];

  empresasLogin: Array<{ id: string | undefined, nomeEmpresa: string | undefined }> = [];

  subscriptionPeriodo: any;
  subscriptionEmpresa: any;
  subscriptionNFSeNacional: any;
  subscriptionNFSe: any;
  subscriptionNFe: any;
  subscriptionCTe: any;
  subscriptionCFe: any;

  chartData = {
    labels: this.dashboardContent.map(item => { return item.tipoDoc }),
    datasets: [
      {
        label: '',
        backgroundColor: this.dashboardContent.map(item => { return item.cor }),
        data: this.dashboardContent.map(item => { return item.quantidadeDocs })
      }
    ]
  };

  chartOptions = {
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          color: "#334155"
        }
      }
    }
  };

  constructor(
    private authService: AuthService,
    private controleXMLService: ControleXMLService,
    private main: MainComponent,
    private empresaService: EmpresaService
  ) { };

  ngOnInit(): void {
    this.userLogged = this.authService.getUsuario()!;
    this.consultaInfos();
    this.renderizaGrafico();

    const empresaRequest: EmpresaPagedRequest = new EmpresaPagedRequest();
    empresaRequest.ativa = true;
    empresaRequest.clienteLogin = this.userLogged.login;
    empresaRequest.unpaged = true;

    this.subscriptionEmpresa = this.empresaService.getEmpresasByFilters(empresaRequest).subscribe({
      next: (empresas) => {
        this.empresasLogin = empresas.content.map(empresa => { return { id: empresa.id, nomeEmpresa: empresa.nome } })
      }
    })

    this.subscriptionPeriodo = this.main.periodoChange.subscribe(() => {
      this.limpaDashboard();
      this.consultaInfos();
    });
  };

  ngOnDestroy(): void {
    this.subscriptionNFe?.unsubscribe();
    this.subscriptionCTe?.unsubscribe();
    this.subscriptionCFe?.unsubscribe();
    this.subscriptionNFSe?.unsubscribe();
    this.subscriptionPeriodo?.unsubscribe();
    this.subscriptionNFSeNacional?.unsubscribe();
    this.subscriptionEmpresa?.unsubscribe();
  };

  private consultaInfos() {
    this.carregaValoresControleXML();
    this.consultaInfosNFSe();
    this.consultaInfosDFe();
    this.consultaInfosCTe();
    this.consultaInfosCFe();
    this.consultaInfosPortalNacional();
  };

  private carregaValoresControleXML() {
    const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho','Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const mesAtual = meses[this.main.periodo.dataInicio!.getMonth()];

    this.panelMenuRootContent = [{
      id: "panelMenuRoot",
      icon: "pi pi-file",
      textContent: `Total de XMLs capturados no período de <b>${mesAtual} - ${this.main.periodo.dataInicio?.getFullYear()}</b> :`,
      badgeContent: 0,
      items: []
    }];
  };

  private consultaInfosNFSe() {
    this.subscriptionNFSe = this.controleXMLService.getDadosNFSeDashboard(this.userLogged.login, this.main.periodo.dataInicio?.getFullYear(), this.main.periodo.dataInicio?.getMonth()! + 1).subscribe(infoNFSeList => {
      const infoNotasTomadas: Array<DashboardReportDTO> = infoNFSeList.filter(infoNota => infoNota.tipoDoc == "NFS-e Tomada");
      const infoNotasPrestadas: Array<DashboardReportDTO> = infoNFSeList.filter(infoNota => infoNota.tipoDoc == "NFS-e Prestada");

      this.atualizaValores(
        TipoDoc.NFSeTomada,
        infoNotasTomadas,
        true
      );

      this.atualizaValores(
        TipoDoc.NFSePrestada,
        infoNotasPrestadas,
        true
      );
    });
  };

  private consultaInfosDFe() {
    this.subscriptionNFe = this.controleXMLService.getDadosDFeDashboard(this.userLogged.login, this.main.periodo.dataInicio?.getFullYear(), this.main.periodo.dataInicio?.getMonth()! + 1).subscribe((infoDFeList) => {
      this.atualizaValores(
        TipoDoc.NFe,
        infoDFeList,
        true
      );
    });
  };

  private consultaInfosCTe() {
    this.subscriptionCTe = this.controleXMLService.getDadosCTeDashboard(this.userLogged.login, this.main.periodo.dataInicio?.getFullYear(), this.main.periodo.dataInicio?.getMonth()! + 1).subscribe((infoCTeList) => {
      this.atualizaValores(
        TipoDoc.CTe,
        infoCTeList,
        true
      );
    });
  };

  private consultaInfosCFe() {
    this.subscriptionCFe = this.controleXMLService.getDadosCFeDashboard(this.userLogged.login, this.main.periodo.dataInicio?.getFullYear(), this.main.periodo.dataInicio?.getMonth()! + 1).subscribe((infoCFeList) => {
      this.atualizaValores(
        TipoDoc.CFe,
        infoCFeList,
        true
      );
    });
  };

  private consultaInfosPortalNacional() {
    this.subscriptionNFSeNacional = this.controleXMLService.getDadosPortalNacionalDashboard(this.userLogged.login, this.main.periodo.dataInicio?.getFullYear(), this.main.periodo.dataInicio?.getMonth()! + 1).subscribe((infoPortalNacionalList) => {
      this.atualizaValores(
        TipoDoc.PortalNacional,
        infoPortalNacionalList,
        true
      );
    });
  };

  private atualizaValores(tipo: TipoDoc, reportContent: Array<DashboardReportDTO>, carregou: boolean) {
    const quantidadeCalculada = reportContent.reduce((total, infoNota) => total + infoNota.quantidadeDocs!!, 0);
    const valorCalculado = reportContent.reduce((total, infoNota) => total + infoNota.valorTotalDocs!!, 0);
    this.dashboardContent = this.dashboardContent.map(item => {
      if (item.tipoDoc == tipo) {
        return {
          ...item,
          quantidadeDocs: quantidadeCalculada,
          valorTotal: valorCalculado,
          conteudoCarregou: carregou
        };
      };
      return item;
    });
    this.conteudosCarregaram = this.dashboardContent.every(content => content.conteudoCarregou);
    this.atualizaInformacoesPainel(tipo, reportContent);
    this.renderizaGrafico();
  };

  private atualizaInformacoesPainel(tipo: TipoDoc, dashboardReportContent: Array<DashboardReportDTO>) {
    dashboardReportContent.forEach(report => {
      const isNfse = (tipo == TipoDoc.NFSePrestada || tipo == TipoDoc.NFSeTomada);

      const nomeEmpresa = report.nomeEmpresa ?? this.empresasLogin.find(empresa => empresa.id == report.cnpjEmpresa)?.nomeEmpresa;

      const empresaTextContent = `${report.cnpjEmpresa} - ${nomeEmpresa ?? ""}`.toUpperCase();

      const newMunicipioPanel: PanelMenuItem = {
        id: tipo + report.nomeMunicipio + report.cnpjEmpresa,
        icon: "pi pi-map-marker",
        textContent: report.nomeMunicipio,
        badgeContent: report.quantidadeDocs,
        items: []
      }

      const newDocumentPanel: PanelMenuItem = {
        id: tipo + report.cnpjEmpresa,
        icon: "pi pi-file-check",
        textContent: tipo,
        badgeContent: report.quantidadeDocs,
        items: (isNfse ? [newMunicipioPanel] : [])
      }
      
      const newEmpresaPanel: PanelMenuItem = {
        borderStyle: "2px solid #ebebeb",
        id: report.cnpjEmpresa,
        icon: "pi pi-building",
        textContent: empresaTextContent,
        badgeContent: report.quantidadeDocs,
        items: [newDocumentPanel]
      }

      const currentPanelState: Array<PanelMenuItem> = this.panelMenuRootContent[0].items!;
      const empresaPanel = currentPanelState!.find(currContent => currContent.id == report.cnpjEmpresa)!;

      //se a empresa nao tiver sido adicionada ao painel
      if (empresaPanel == undefined) {
        currentPanelState.push(newEmpresaPanel);
      } else {
        empresaPanel.badgeContent! += report.quantidadeDocs!;
        empresaPanel.textContent = empresaTextContent;
        
        const documentPanel = empresaPanel?.items?.find(currDoc => currDoc.textContent == tipo.toString());

        //se o tipo de documento nao tiver sido adicionado ao painel da empresa
        if (documentPanel == undefined) {
          empresaPanel.items!.push(newDocumentPanel);
        } else { 
          documentPanel.badgeContent! += report.quantidadeDocs!;

          //se for nfse adicionar o painel de municipios dentro do painel de documentos
          //nao precisamos atualizar os valores do painel de municipio pois so ha 1 municipio por report de nfse
          if (isNfse) {
            documentPanel.items!.push(newMunicipioPanel)
          }
        }
      }
    })

    const updatedPanelMenu = this.panelMenuRootContent[0];
    updatedPanelMenu.badgeContent! += dashboardReportContent.reduce((total, infoNota) => total + infoNota.quantidadeDocs!!, 0);
    updatedPanelMenu.items = updatedPanelMenu.items.sort(
      (a, b) =>  `${b.textContent!}${b.badgeContent}`.length - `${a.textContent!}${a.badgeContent}`.length
    );
    this.panelMenuRootContent = [updatedPanelMenu];
  }

  private limpaDashboard() {
    Object.keys(TipoDoc).forEach(category => {
      const item = TipoDoc[category as keyof typeof TipoDoc];
      this.atualizaValores(item, [] , false);
    })
  }

  private renderizaGrafico() {
    this.chartData = {
      labels: this.dashboardContent.map(item => { return item.tipoDoc }),
      datasets: [
        {
          label: '',
          backgroundColor: this.dashboardContent.map(item => { return item.cor }),
          data: this.dashboardContent.map(item => { return item.quantidadeDocs })
        }
      ]
    };
  };

  escurecerCor(cor: string, percent: number): string {
    return '#' + cor.replace(/^#/, '').replace(/../g, color => {
      const num = parseInt(color, 16) - Math.min(255, Math.round(255 * percent));
      const newColor = num < 0 ? 0 : num;
      return newColor.toString(16).padStart(2, '0');
    });
  };

}
