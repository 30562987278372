<div *ngIf="isEnableCfePage; else notEnabledCfe">
<p-contextMenu #cm [model]="itemsContextMenu" (onHide)="removeHighlightContextmenu()"></p-contextMenu>

<div class="container">
  <div class="container-width">
    <!-- TITULO -->
    <div class="d-flex flex-row align-items-baseline" style="margin-top: -10px;">
      <div class="flex-grow-1">
        <div style="width: 200px;">
          <h4 style="line-height: 2">
            CF-e
          </h4>
        </div>
      </div>
      <div style="margin-top: -10px;">
        <button pButton class="p-button-rounded p-button-text me-5 ms-4" pTooltip="Recarrega dados da tabela" [showDelay]="500" (click)="refresh()">
          <i class="pi pi-refresh"></i>
        </button>
      </div>
    </div>

    <!-- Botões de ações -->
    <div class="d-flex mt-4">
      <div class="flex-grow-1">
        <div class="d-flex flex-row">
          <div>
            <p-inputGroup>
              <span class="p-float-label">
                <input
                  id="filtroChaveAcessoNfe"
                  pInputText
                  style="width: 370px;"
                  [(ngModel)]="cfeRequest.chaveAcesso" />
                <label for="filtroChaveAcessoNfe">Chave de acesso</label>
              </span>
              <button type="button" pButton icon="pi pi-times" class="p-button-outlined p-button-secondary button-grouped" (click)="limpaFiltro('chaveAcesso')"></button>
            </p-inputGroup>
          </div>
          <div>
            <p-button icon="pi pi-search" [rounded]="true" [text]="true" [raised]="true" severity="info" class="ms-4" (onClick)="onClickFiltrarChaveAcesso()"></p-button>
          </div>
        </div>
      </div>
      <div class="flex-grow-1"></div>
      <div>
        <div class="d-flex">
          <div class="me-2">
            <button pButton type="button" class="p-button-rounded p-button-secondary" pTooltip="Download das notas selecionadas" tooltipPosition="top"
              icon="pi pi-download" (click)="downloadCfeSelecionadas()" [disabled]="selectedRows.length == 0">
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <!-- Tabela com dados -->
      <p-table #dt
        class="table-cfe"
        [value]="cfePage.content"
        [lazy]="true"
        (onLazyLoad)="lazyLoad($event)"
        [totalRecords]="cfePage.totalElements"
        [(selection)]="selectedRows"
        dataKey="chaveAcesso"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="pageSizes"
        [rows]="pageSizes[0]"
        [loading]="loading"
        [paginator]="true"
        currentPageReportTemplate="{first} {{'até'}} {last} {{'de'}} {{cfePage.totalElements}} {{'entradas'}}"
        [scrollable]="true"
        scrollHeight="calc(100% - 200px)"
        [contextMenu]="cm"
        [(contextMenuSelection)]="selectedCfeContextMenu">
        <!-- HEADER -->
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{'width': '50px'}">
              <div style="padding-left: 5px; padding-top: 5px; cursor: pointer;"
                pTooltip="Seleciona os itens da página"
                tooltipPosition="left" [showDelay]="1000" [hideDelay]="300"
                (click)="onClickSelectedAllRows()">
                <i class="bi bi-check-all" style="font-size: 1.5rem; color: #999999;"></i>
              </div>
            </th>
            <th [style]="{'width': '90px', 'text-align': 'center'}">Status</th>
            <th [style]="{'width': '90px', 'text-align': 'center'}">Equipamento</th>
            <th [style]="{'width': '372px'}">Chave de acesso</th>
            <th [style]="{'width': '100px', 'text-align': 'center'}">Número do CF</th>
            <th [style]="{'width': '100px', 'text-align': 'center'}">Recibo</th>
            <th [style]="{'width': '100px', 'text-align': 'center'}">Data de emissão</th>
            <th [style]="{'width': '125px', 'text-align': 'center'}">Origem</th>
          </tr>
        </ng-template>
        <!-- BODY -->
        <ng-template pTemplate="body" let-cfe>
          <tr [pContextMenuRow]="cfe" class="table-row-body" [style]="{'font-size': '12px'}">
            <td>
              <p-tableCheckbox [value]="cfe" class="ml-1"></p-tableCheckbox>
            </td>
            <td [style]="{'text-align': 'center'}">{{cfe.status}}</td>
            <td [style]="{'text-align': 'center'}">{{cfe.equipamento}}</td>
            <td>{{cfe.chaveAcesso}}</td>
            <td [style]="{'text-align': 'center'}">{{cfe.numCF}}</td>
            <td [style]="{'text-align': 'center'}">{{cfe.numRecibo}}</td>
            <td [style]="{'text-align': 'center'}">
              {{cfe.dataEmissao | date:'dd/MM/yyyy' }}&nbsp;<span style="color: rgb(165, 165, 165); font-size: 12px;">{{cfe.dataEmissao | date:'HH:mm:ss' }}</span>
            </td>
            <td [style]="{'text-align': 'center'}">{{cfe.origem}}</td>
          </tr>
        </ng-template>
      </p-table>
        <div *ngIf="cfePage.totalElements == 0" class="mt-3 w-100 d-flex justify-content-center">
          <div>Sem dados</div>
        </div>
      </div>
        <div *ngIf="cfePage.totalElements > 0">
          <b>Selecionadas:</b> {{selectedRows.length}}
        </div>
    </div>
  </div>
</div>

<ng-template #notEnabledCfe>
  <div class="centered-container">
    <div class="centered-content">
      <img src="assets/images/danger.png" alt="Danger">
      <p>Empresa não cadastrada no módulo CF-e</p>
    </div>
  </div>
</ng-template>

<p-toast position="bottom-center"></p-toast>

<!-- Modal DACTE -->
<p-dialog
  header="DACTE"
  [modal]="false"
  [(visible)]="showModalDanfe"
  [breakpoints]="{'960px': '75vw'}"
  [style]="{width: '85vw', height: '90vw'}"
  [draggable]="false"
  [resizable]="false">
  <div [ngClass]="{'h-100' : !showProgressSpinModalDanfe, 'd-none': showProgressSpinModalDanfe}">
    <iframe id="pdfViewer" [src]="base64Pdf" width="100%" height="100%"></iframe>
  </div>
  <div class="d-flex align-items-center justify-content-center h-100" *ngIf="showProgressSpinModalDanfe">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-dialog>
