import { ControleDownloadXML } from "./controle-download-xml.model";
import { ClienteTipo } from "./enums/cliente-tipo.enum";

export class Cliente {

  login!: string;
  clienteTipo?: ClienteTipo;
  suporteLogin?: string;
  empresas?: string[] = [];
  ativo!: boolean;
  nome!: string;
  email!: string;
  mudarSenha!: boolean;
  token!: string;
  dataRegistro!: Date;
  controleDownloadXML: ControleDownloadXML = {};
}
