import { HttpResponse } from '@angular/common/http';
import { NFSeDownloadRequest } from './../model/nfse-download.request';
import { NFSeConsultaWsRequest } from '../model/nfse-consulta-ws.request';
import { Page } from '../model/page.model';
import { NFSePagedRequest } from '../model/nfse-paged-request.model';
import { Observable } from 'rxjs';
import { NFSeResponse } from '../model/nfse-response.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { UploadRequest } from 'src/model/upload-request';
import { DownloadGeralRequest } from 'src/model/download-notas-geral.request';

const API_CONSULTA_BASE_NFSE = environment.API_MAIN + "/nfse/consulta-paginada";
const API_CONSULTA_NFSE_POR_DATAS = environment.API_MAIN + "/nfse/consulta-notas-por-datas";
const API_CONSULTA_NFSE_A_PARTIR_DA_ULTIMA = environment.API_MAIN + "/nfse/consulta-apartir-da-ultima-nota";
const API_NFSE_DOWNLOAD_XML = environment.API_MAIN + "/nfse/download-xml";
const ENDPOINT_GET_PDF_NFSE = environment.API_MAIN + "/nfse/danfse/pdf/";
const ENDPOINT_RELATORIO = environment.API_MAIN + "/nfse/relatorio";
const API_UPLOAD_XML_FILES = environment.API_MAIN + "/nfse/upload-xml";
const API_DOWNLOAD_PACOTE_NFSE = environment.API_MAIN + "/nfse/pacote-nfse";
const API_DOWNLOAD_PACOTE_PDF = environment.API_MAIN + "/nfse/pacote-pdf";

@Injectable({
    providedIn: 'root'
})
export class NFSeService {

  endpointConsultaNFSe!: string;

  constructor(
    private http: HttpClient
  ) {}

  /*
    Obtem a lista paginada de Notas Fiscais
  */
  public getNotasFiscaisRecebidas(request: NFSePagedRequest): Observable<Page<NFSeResponse>> {
    const url = API_CONSULTA_BASE_NFSE + "?" + this.getParams(request).toString();
    return this.http.get<Page<NFSeResponse>>(url);
  }

  /*
    Faz o envio de uma lista de Arquivos
  */
  public sendUploadedXml(request: UploadRequest): Observable<void> {
    const formData = new FormData();
    if (request.arquivos?.length == 0 || request.idEmpresa == undefined || request.codigoMun == undefined || request.clienteLogin == undefined) {
      throw Error("Todos os campos da request devem ser preenchidos")
    } else {
      formData.append('clienteLogin', request.clienteLogin);
      formData.append('idEmpresa', request.idEmpresa);
      formData.append('codigoMun', request.codigoMun.toString());
      for (let i = 0; i < request.arquivos.length; i++) {
        formData.append('arquivos', request.arquivos[i]);
      }
    }
    return this.http.post<void>(API_UPLOAD_XML_FILES, formData);
  }

    /*
    Download de todos os PDFs por data
  */
    public downloadPacotePdfNFse(request: DownloadGeralRequest): Observable<HttpResponse<Blob>> {
      let options: {} = {
        responseType: 'blob',
        observe: 'response'
      }
      return this.http.post<HttpResponse<Blob>>(API_DOWNLOAD_PACOTE_PDF, request, options);
  }
  
  /*
    Download de todas as notas fiscais por data
  */
    public downloadPacoteNotasFiscaisEletronicas(request: DownloadGeralRequest): Observable<HttpResponse<Blob>> {
        let options: {} = {
          responseType: 'blob',
          observe: 'response'
        }
        return this.http.post<HttpResponse<Blob>>(API_DOWNLOAD_PACOTE_NFSE, request, options);
    }

  /*
    Busca novas notas por datas
  */
  public consultaNotasPorData(request: NFSeConsultaWsRequest): Observable<number> {
    const url = API_CONSULTA_NFSE_POR_DATAS + "?" + this.getParams(request).toString();
    return this.http.get<number>(url);
  }

  /*
    Busca novas notas a partir da data da ultima nota obtida
  */
  private consultaNotasAPartirDaUltima(request: NFSeConsultaWsRequest): Observable<number> {
    const url = API_CONSULTA_NFSE_A_PARTIR_DA_ULTIMA + "?" + this.getParams(request).toString();
    return this.http.get<number>(url);
  }

  /*
    Download das Notas Fiscais passando uma lista de ids
  */
  public downloadNotasFiscais(request: NFSeDownloadRequest): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.post<HttpResponse<Blob>>(API_NFSE_DOWNLOAD_XML, request, options);
  }

  /*
    Obtem a nota pelo código do municipio e id
  */
  public getDanfsePdf(codMun: Number, id: String): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.get<HttpResponse<Blob>>(ENDPOINT_GET_PDF_NFSE + codMun + "/" + id, options);
  }

  /*
    Download relatório em planilha
  */

  public consultaNFSeRelatorio(request: NFSePagedRequest): Observable<Page<NFSePagedRequest>> {
    let params: URLSearchParams = this.getParams(request);
    const url = `${ENDPOINT_RELATORIO}?${params.toString()}`;
    return this.http.get<Page<NFSePagedRequest>>(url);
  }

  public generateUrlGetDanfsePdf(codMun: Number, id: String, nomeArquivo: String): string {
    return ENDPOINT_GET_PDF_NFSE + codMun + "/" + id  + "/" + nomeArquivo  + "#view=FitH";
  }

  private getParams(request: Object): URLSearchParams {
    let params = new URLSearchParams();
    const keys = Object.keys(request);
    const values = Object.values(request);
    for (let i = 0; i < keys.length; i++) {
        if (values[i] instanceof Date) {
          params.set(keys[i], new Date(values[i]).toISOString().split("T")[0]);
        } else if (values[i] != '' && values[i] != null && values[i] != undefined) {
          params.set(keys[i], values[i]);
        } else if (values[i] == 'PRESTADA') {
          params.set(keys[i], 'PRESTADA');
        } else if (values[i] == 'TOMADA') {
          params.set(keys[i], 'TOMADA');
        }
    }

    return params;
  }
}
