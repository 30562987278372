import { Modulos } from './modulos';
import { CertificadoEmpresa } from './certificado-empresa.model';

export class Empresa {

  id?: string;
  suporteLogin?: string;
  ativa?: boolean;
  dataRegistro?: Date;
  nome?: string;
  codigoUf?: number;
  modulos: Modulos = new Modulos();
  certificado?: CertificadoEmpresa;
}
