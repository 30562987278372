<router-outlet></router-outlet>

<span class="version">{{pkgVersion}}</span>

<p-dialog
  [modal]="true"
  [closable]="false"
  [draggable]="false"
  [resizable]="false"
  [visible]="modalLoadingShow"
  class="progress-spinner">
  <div class="d-flex justify-content-center">
    <p-progressSpinner strokeWidth="8"></p-progressSpinner>
  </div>
</p-dialog>

<!-- Mensagem via MensagemService -->
<p-toast key="to-message-service" position="bottom-right" [style]="{'width':'600px'}">
  <ng-template let-message pTemplate="message">
    <div class="w-100">
      <p style="margin-bottom: 0; font-weight: 600;" innerHtml="{{message.summary}}"></p>
      <p style="margin-bottom: 0; padding-top: .4rem;" innerHtml="{{message.detail}}"></p>
    </div>
  </ng-template>
</p-toast>

<!-- Mensagem via Service (bottom rigth) -->
<p-toast key="toastBottomRigth800px" position="bottom-right" [style]="{'width':'800px'}">
  <ng-template let-message pTemplate="message">
    <div class="w-100">
      <p style="margin-bottom: 0; font-weight: 600;" innerHtml="{{message.summary}}"></p>
      <p style="margin-bottom: 0; padding-top: .4rem;" innerHtml="{{message.detail}}"></p>
    </div>
  </ng-template>
</p-toast>

<!-- Mensagem via Service -->
<p-toast key="toastTopRight800px" position="top-right" [style]="{'width':'800px'}">
  <ng-template let-message pTemplate="message">
    <div class="w-100">
      <p style="margin-bottom: 0; font-weight: 600;" innerHtml="{{message.summary}}"></p>
      <p style="margin-bottom: 0; padding-top: .4rem;" innerHtml="{{message.detail}}"></p>
    </div>
  </ng-template>
</p-toast>

<!-- Mensagem via Service -->
<p-toast key="toastTopCenter800px" position="top-center" [style]="{'width':'800px'}">
  <ng-template let-message pTemplate="message">
    <div class="w-100">
      <p style="margin-bottom: 0; font-weight: 600;" innerHtml="{{message.summary}}"></p>
      <p style="margin-bottom: 0; padding-top: .4rem;" innerHtml="{{message.detail}}"></p>
    </div>
  </ng-template>
</p-toast>
