import { ClienteTipo } from './../../../../model/enums/cliente-tipo.enum';
import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/services/auth.service';
import { MainComponent } from '../main.component';
import { ClienteService } from 'src/services/clientes.service';
import { Cliente } from 'src/model/cliente.model';

@Component({
  selector: 'app-minha-conta',
  templateUrl: './minha-conta.component.html',
  styleUrls: ['./minha-conta.component.scss']
})
export class MinhaContaComponent implements OnInit {

  loadPage = false;
  timeout: any;

  loggedUser: Cliente | null;
  cliente: Cliente | undefined;
  ClienteTipo = ClienteTipo;

  constructor(
    private clienteService: ClienteService,
    private messageService: MessageService,
    private authService: AuthService,
    private main: MainComponent
  ) {
    this.loggedUser = this.authService.getUsuario();
  }

  ngOnInit(): void {
    this.loadCliente();
  }

  private loadCliente() {
    this.showModalLoading()
    this.clienteService.getDadosClienteLogado().subscribe({
      next: (cliente) => {
        setTimeout(() => {
          this.cliente = cliente;
        });
      },
      error: (e) => {
        this.showMensagemErro(e.name, e.message);
      }
    }).add(() => {
      this.loadPage = true;
      this.hideModalLoading();
    });
  }

  salvarCliente(event: any) {
    const cliente = event.target;
    this.clienteService.atualizaCliente(cliente).subscribe({
      next: (response) => {
        if (response.successfully) {
          this.showMensagemSucesso();
        } else {
          this.showMensagemErro("Erro ao salvar", response.result);
        }
      },
      error: (e) => {
        this.showMensagemErro(e.name, e.message);
      }
    });
  }

  private showMensagemSucesso() {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'success',
      summary: 'Cadastro atualizado com sucesso',
      detail: '',
      life: 5000
    });
  }

  private showMensagemErro(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  private showModalLoading() {
    this.main.showModalLoading();
  }

  private hideModalLoading() {
    this.main.hideModalLoading();
  }
}
