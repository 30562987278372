import { AuthService } from '../../../../services/auth.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MessageService, MenuItem } from 'primeng/api';
import { Table, TableLazyLoadEvent} from 'primeng/table';
import { saveAs } from 'file-saver';
import { Page } from 'src/model/page.model';
import { Cliente } from 'src/model/cliente.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MainComponent } from '../main.component';
import { Empresa } from 'src/model/empresa.model';
import { NotificacoesService } from 'src/services/notificacoes.service';
import { PortalNacionalNFSeRequest } from 'src/model/portal-nacional-request.model';
import { PortalNaciocalService } from 'src/services/portal-nacional-nfse.service';
import { PortalNacionalNFSe } from 'src/model/portal-nacional-nfse.model';

@Component({
  selector: 'app-portal-nfse',
  templateUrl: './portal-nacional.component.html',
  styleUrls: ['./portal-nacional.component.scss']
})
export class PortalNacionalComponent implements OnInit, OnDestroy {

  @ViewChild('dt') table!: Table;

  userLogged!: Cliente;

  timeout: any;

  portalRequest: PortalNacionalNFSeRequest = new PortalNacionalNFSeRequest();

  base64Pdf?: any;

  pageSizes = [10, 15, 20, 50, 100];

  selectedEmpresa?: Empresa;

  rowsPerPageSelected?: number = 10;
  checkedSelectionAll: boolean = false;

  portalPage: Page<PortalNacionalNFSe> = new Page<PortalNacionalNFSe>();
  selectedRows: any[] = [];

  // Context Menu - Linha da Grid
  selectedNotaContextMenu?: PortalNacionalNFSe;
  itemsContextMenu: MenuItem[];

  loading: boolean = false;

  subscriptionEmpresaChange: any;
  subscriptionPeriodoChange: any;
  subscriptionNotificacoes: any;

  isEnablePortalNacionalPage = true;

  constructor(
    private portalService: PortalNaciocalService,
    private messageService: MessageService,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    private main: MainComponent,
    private notificacoesService: NotificacoesService
  ) {
    this.base64Pdf = this.domSanitizer.bypassSecurityTrustResourceUrl('');
    this.itemsContextMenu = [
      {label: 'Visualizar DANFSe', icon: 'pi pi-fw pi-eye', command: () => { this.openModalDanfse(this.selectedEmpresa?.id!, this.selectedNotaContextMenu?.chaveAcesso!) }},
      {label: 'Baixar XML', icon: 'pi pi-fw pi-download', command: () => { this.downloadNotaPortal(this.selectedNotaContextMenu?.chaveAcesso!) }}
    ];
  }

  ngOnInit(): void {
    this.portalPage.totalElements = 0;

    this.userLogged = this.authService.getUsuario()!;

    if (this.main.selectedEmpresa) {
      this.selectedEmpresa = this.main.selectedEmpresa;
      this.onChangeEmpresa();
    }

    this.subscriptionEmpresaChange = this.main.empresaChange.subscribe((empresa: Empresa) => {
      this.selectedEmpresa = empresa;
      this.onChangeEmpresa();
      this.isEnablePortalNacionalPage = empresa.modulos.portalNacionalModulo.portalNacionalEnable;
    });

    this.subscriptionPeriodoChange = this.main.periodoChange.subscribe(() => {
      this.portalRequest.chaveAcesso = "";
      this.portalRequest.dataInicio = this.main.periodo.dataInicio;
      this.portalRequest.dataFim = this.main.periodo.dataFim;
      this.buscaPortalConsultadas(this.portalRequest);
    });

    this.subscriptionNotificacoes = this.notificacoesService.onReceiveNovasNotas.subscribe((modulo: string) => {
      if (modulo == "NFS-e Nacional") {
        this.refresh();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptionEmpresaChange?.unsubscribe();
    this.subscriptionPeriodoChange?.unsubscribe();
    this.subscriptionNotificacoes?.unsubscribe();
  }

  removeHighlightContextmenu() {
    $(".p-highlight-contextmenu").removeClass("p-highlight-contextmenu");
  }

  onClickBusca() {
    this.selectedRows = [];
    this.portalRequest.cnpjEmpresa = this.selectedEmpresa?.id;
    this.buscaPortalConsultadas(this.portalRequest);
  }

  private onChangeEmpresa() {
    this.selectedRows = [];
    this.portalRequest.clienteLogin = this.userLogged.login;
    this.portalRequest.cnpjEmpresa = this.selectedEmpresa?.id;
    this.portalRequest.pageNum = 0;
    if (this.rowsPerPageSelected) {
      this.portalRequest.pageSize = this.rowsPerPageSelected;
    }
    this.buscaPortalConsultadas(this.portalRequest);
  }

  onSelectionChange(event: []) {
    this.selectedRows = event;
  }

  onClickFiltrarChaveAcesso() {
    console.log("com chave de acesso")
    this.portalRequest.chaveAcesso = this.portalRequest.chaveAcesso?.trim();
    this.buscaPortalConsultadas(this.portalRequest);
  }

  limpaFiltro(campo: string) {
    (this.portalRequest as any)[campo] = "";
    this.buscaPortalConsultadas(this.portalRequest);
  }

  private buscaPortalConsultadas(portalRequest: PortalNacionalNFSeRequest) {
    if (portalRequest.cnpjEmpresa) {
      if (!this.portalRequest.chaveAcesso) {
        const dataInicio = portalRequest.dataInicio ? portalRequest.dataInicio.toISOString().split('T')[0] :  this.main.periodo.dataInicio?.toISOString().split('T')[0];
        const dataFim = portalRequest.dataFim ? portalRequest.dataFim.toISOString().split('T')[0] :  this.main.periodo.dataFim?.toISOString().split('T')[0];

        this.portalRequest.dataEmissaoInicioStr = dataInicio;
        this.portalRequest.dataEmissaoFimStr = dataFim;
      } else {
        this.portalRequest.dataEmissaoInicioStr = undefined;
        this.portalRequest.dataEmissaoFimStr = undefined;
      }

      this.loading = true;
      this.showModalLoading();

      this.portalService.consultaNFSeBanco(portalRequest).subscribe({
        next: (data) => {
          this.portalPage = data;
          this.loading = false;
          this.hideModalLoading();
        },
        error: (e) => {
          this.showMensagemErro("Erro", e.message);
          this.loading = false;
          this.hideModalLoading();
        }
      });
    }
  }

  consultaPortalNacionalNfse() {
    if (this.portalRequest.cnpjEmpresa) {
      let currentDate: Date = new Date();
      this.portalRequest.dataConsulta = currentDate.toLocaleString();
      this.portalService.consultaPortal(this.portalRequest).subscribe({
        next: () => {
          this.showMensagemInfo("Requisição enviada", "Aguardar a notificação da conclusão do processamento.");
        },
        error: (e) => {
          this.showMensagemErro("Erro", e.message);
        }
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Consulta NFS-e Nacional',
        detail: 'CNPJ/CPF não informado',
        life: 10000
      });
    }
  }

  public lazyLoad(event: TableLazyLoadEvent) {
    if (event.first != undefined && event.rows != undefined) {
      this.portalRequest.pageNum = event.first / event.rows;
      this.portalRequest.pageSize = event.rows;
    }

    if (event.sortField) {
      this.portalRequest.sortField = event.sortField;
      this.portalRequest.sortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    this.buscaPortalConsultadas(this.portalRequest);

  }

  refresh() {
    this.buscaPortalConsultadas(this.portalRequest);
  }

  public onClickSelectedAllRows() {
    let selection: any = [];
    this.selectedRows.forEach(item => {
      selection.push(item);
    });
    this.selectAllPageItems(selection);
  }

  // fazer apos trazer as notas
  private selectAllPageItems(selection: any) {
    this.portalPage.content.forEach(cfe => {
      const found = this.selectedRows.find(item => cfe.chaveAcesso == item.chaveAcesso);
      if (found == undefined) {
        selection.push(cfe);
      }
    });
    this.selectedRows = selection;
  }

  //fazer apos trazer as notas
  downloadNotaPortal(chaveAcesso: String) {
    this.portalService.downloadNotaPortal(chaveAcesso).subscribe(res => {
      let filename = res.headers.get('content-disposition')?.split('filename=')[1];
      if (res.body) {
        saveAs(res.body, filename);
      }
    });
  }

  //fazer apos trazer as notas
  downloadPortalNotasSelecionadas() {
    let ids: String[] = [];
    this.selectedRows.forEach(item => {
      ids.push(item.chaveAcesso);
    });
    if (ids.length == 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Nenhum registro selecionado contendo NFS-e para download.',
        life: 10000
      });
    } else {
      this.portalService.downloadNotaPortalLote(ids).subscribe(res => {
        let filename = res.headers.get('content-disposition')?.split('filename=')[1];
        if (res.body) {
          saveAs(res.body, filename);
        }
      });
    }
  }

  private showMensagemInfo(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'info',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 5000
    });
  }

  private showMensagemErro(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  private showMensagemSuccess(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'success',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 5000
    });
  }

  private showMensagemAlerta(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  private showModalLoading() {
    this.loading = this.main.showModalLoading();
  }

  private hideModalLoading() {
    this.loading = this.main.hideModalLoading();
  }

  showModalDanfse = false;
  showProgressSpinModalDanfse = true;

  openModalDanfse(idEmpresa: string, chaveAcesso: String) {
    this.showModalDanfse = true;
    this.showProgressSpinModalDanfse = true;
    this.base64Pdf = this.domSanitizer.bypassSecurityTrustResourceUrl(this.portalService.generateUrlGetDanfsePdf(idEmpresa, chaveAcesso));
    setTimeout(() => {
      document.getElementById("pdfViewer")!.onload = () => {
        this.showProgressSpinModalDanfse = false;
      };
    });
  }

  closeModalDanfse() {
    ($('#modal-danfe') as any).modal('hide');
  }
}
