<div class="container">
  <div class="container-width">
    <!-- TITULO E BOTOES DE ACOES -->
    <div class="d-flex flex-row align-items-center titulo-btn-acoes">
      <div class="flex-grow-1">
        <h4>Minha conta</h4>
      </div>
    </div>

    <div class="mt-4"></div>

    <app-form-cadastro-cliente
      [cliente]="cliente"
      (onClickSalvar)="salvarCliente($event)"
      [showBotaoCancelar]="false"
      [somenteVisualizar]="loggedUser?.clienteTipo == ClienteTipo.CLIENTE">
    </app-form-cadastro-cliente>
  </div>
</div>
