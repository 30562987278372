import { DateMaskDirective } from './../shared/date-mask.directive';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';

import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { TabViewModule } from 'primeng/tabview';
import { ListboxModule } from 'primeng/listbox';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { MenuModule } from 'primeng/menu';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MultiSelectModule } from 'primeng/multiselect';
import { DividerModule } from 'primeng/divider';
import { FieldsetModule } from 'primeng/fieldset';
import { KeyFilterModule } from 'primeng/keyfilter';
import { RadioButtonModule } from 'primeng/radiobutton';
import { BadgeModule } from 'primeng/badge';
import { CardModule } from 'primeng/card';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { PanelModule } from 'primeng/panel';
import { InputNumberModule } from 'primeng/inputnumber';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputGroupModule } from 'primeng/inputgroup';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ChartModule } from 'primeng/chart';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { InterceptorModule } from './interceptors/interceptor.module';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import localePt from '@angular/common/locales/pt';

import { registerLocaleData } from '@angular/common';

import { AppComponent } from './app.component';
import { DfeComponent } from './pages/main/dfe/dfe.component';
import { CteComponent } from './pages/main/cte/cte.component';
import { CfeComponent } from './pages/main/cfe/cfe.component';
import { MainComponent } from './pages/main/main.component';
import { MinhasEmpresasComponent } from './pages/main/minhas-empresas/minhas-empresas.component';
import { RedefinicaoSenhaComponent } from './pages/redefinicao-senha/redefinicao-senha.component'
import { LoginComponent } from './pages/login/login.component';
import { NfseComponent } from './pages/main/nfse/nfse.component';
import { MinhaContaComponent } from './pages/main/minha-conta/minha-conta.component';
import { RelatoriosComponent } from './pages/main/relatorios/relatorios.component';
import { NotificacoesComponent } from './pages/main/notificacoes/notificacoes.component';

import { FormCadastroEmpresaComponent } from '../shared/form-cadastro-empresa/form-cadastro-empresa.component';
import { FormCertificadoDigitalComponent } from 'src/shared/form-certificado-digital/form-certificado-digital.component';
import { FormCadastroClienteComponent } from 'src/shared/form-cadastro-cliente/form-cadastro-cliente.component';
import { CadastroTodasEmpresasComponent } from './pages/admin/cadastro-todas-empresas/cadastro-todas-empresas.component';
import { CadastroTodosClientesComponent } from './pages/admin/cadastro-todos-clientes/cadastro-todos-clientes.component';
import { PortalNacionalComponent } from './pages/main/portal-nacional-nfse/portal-nacional.component';
import { DashboardComponent } from './pages/main/dashboard/dashboard.component';
import { ImportarExportarXmlComponent } from './pages/main/importar-exportar-xml/importar-exportar-xml.component';

registerLocaleData(localePt);
@NgModule({
  declarations: [
    AppComponent,
    DateMaskDirective,
    LoginComponent,
    DfeComponent,
    CteComponent,
    CfeComponent,
    MainComponent,
    MinhasEmpresasComponent,
    MinhaContaComponent,
    RedefinicaoSenhaComponent,
    NfseComponent,
    PortalNacionalComponent,
    ImportarExportarXmlComponent,
    FormCadastroClienteComponent,
    FormCertificadoDigitalComponent,
    FormCadastroEmpresaComponent,
    RelatoriosComponent,
    DashboardComponent,
    NotificacoesComponent,
    CadastroTodasEmpresasComponent,
    CadastroTodosClientesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    DropdownModule,
    ButtonModule,
    TableModule,
    ToastModule,
    MessageModule,
    MessagesModule,
    CalendarModule,
    CheckboxModule,
    InputTextModule,
    PasswordModule,
    TabViewModule,
    ListboxModule,
    InterceptorModule,
    FileUploadModule,
    DialogModule,
    ProgressSpinnerModule,
    MenuModule,
    ContextMenuModule,
    MultiSelectModule,
    NgxMaskDirective,
    NgxMaskPipe,
    DividerModule,
    FieldsetModule,
    KeyFilterModule,
    RadioButtonModule,
    BadgeModule,
    CardModule,
    ScrollPanelModule,
    PanelModule,
    InputNumberModule,
    SelectButtonModule,
    FloatLabelModule,
    InputGroupModule,
    PanelMenuModule,
    ChartModule,
    OverlayPanelModule
  ],
  providers: [
    provideNgxMask(),
    MessageService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    JwtHelperService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
