import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Page } from 'src/model/page.model';
import { PortalNacionalNFSeRequest } from 'src/model/portal-nacional-request.model';
import { PortalNacionalNFSe } from 'src/model/portal-nacional-nfse.model';
import { UploadRequest } from 'src/model/upload-request';
import { DownloadGeralRequest } from 'src/model/download-notas-geral.request';

const ENDPOINT_CONSULTA_NFSE_BAIXADAS = environment.API_MAIN + "/nfse-nacional/consulta";
const ENDPOINT_CONSULTA_NFSE_BAIXADAS_BANCO = environment.API_MAIN + "/nfse-nacional/consulta-banco";
const ENDPOINT_DOWNLOAD_NFSE_NACIONAL = environment.API_MAIN + "/nfse-nacional/download";
const ENDPOINT_DOWNLOAD_LOTE_NFSE_NACIONAL = environment.API_MAIN + "/nfse-nacional/download-lote";
const ENDPOINT_UPLOAD_PORTAL = environment.API_MAIN + "/nfse-nacional/upload-xml";
const ENDPOINT_GET_DANFSE_NFSE_NACIONAL = environment.API_MAIN + "/nfse-nacional/danfse/";
const ENDPOINT_DOWNLOAD_NFSE_NACIONAL_PACOTE = environment.API_MAIN + "/nfse-nacional/download-pacote";
const ENDPOINT_DOWNLOAD_PDF_NACIONAL_PACOTE = environment.API_MAIN + "/nfse-nacional/download-pacote-pdf";
const ENDPOINT_CONSULTA_PORTAL_RELATORIO = environment.API_MAIN + "/nfse-nacional/relatorio";

@Injectable({
  providedIn: 'root'
})
export class PortalNaciocalService {

  constructor(private http: HttpClient) {}

  /*
    Obtem a lista de NFSe consultadas no Portal
  */
  public consultaPortal(request: PortalNacionalNFSeRequest): Observable<Page<PortalNacionalNFSe>> {
    let params: URLSearchParams = this.getParams(request);
    const url = `${ENDPOINT_CONSULTA_NFSE_BAIXADAS}?${params.toString()}`;
    return this.http.get<Page<PortalNacionalNFSe>>(url);
  }

  /*
    Obtem a lista de NFSe consultadas no Banco
  */
  public consultaNFSeBanco(request: PortalNacionalNFSeRequest): Observable<Page<PortalNacionalNFSe>> {
    let params: URLSearchParams = this.getParams(request);
    const url = `${ENDPOINT_CONSULTA_NFSE_BAIXADAS_BANCO}?${params.toString()}`;
    return this.http.get<Page<PortalNacionalNFSe>>(url);
  }

  /*
    Download da NFSe contida no banco de dados
  */
  public downloadNotaPortal(id: String): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.post<HttpResponse<Blob>>(ENDPOINT_DOWNLOAD_NFSE_NACIONAL, id, options);
  }

  /*
    Download da NFSe selecionadas contida no banco de dados
  */
  public downloadNotaPortalLote(ids: String[]): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.post<HttpResponse<Blob>>(ENDPOINT_DOWNLOAD_LOTE_NFSE_NACIONAL, ids, options);
  }

  /*
    Obtem a lista de NFS-e Nacional para geração da planilha do relatório
  */
  public consultaPortalRelatorio(request: PortalNacionalNFSeRequest): Observable<Page<PortalNacionalNFSeRequest>> {
    let params: URLSearchParams = this.getParams(request);
    const url = `${ENDPOINT_CONSULTA_PORTAL_RELATORIO}?${params.toString()}`;
    return this.http.get<Page<PortalNacionalNFSeRequest>>(url);
  }

  /*
    Obtem a nota pela chave de acesso
  */
  public getDanfsePdf1(chaveAcesso: String): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.get<HttpResponse<Blob>>(ENDPOINT_GET_DANFSE_NFSE_NACIONAL + chaveAcesso, options);
  }

  public generateUrlGetDanfsePdf(idEmpresa: string, chaveAcesso: String): string {
    return ENDPOINT_GET_DANFSE_NFSE_NACIONAL + idEmpresa + "/" + chaveAcesso + "#zoom=100&view=FitH";
  }

  /*
    Download de todos os PDFs por data
  */
    public downloadPacotePdfNotasFiscais(request: DownloadGeralRequest): Observable<HttpResponse<Blob>> {
      let options: {} = {
        responseType: 'blob',
        observe: 'response'
      }
      return this.http.post<HttpResponse<Blob>>(ENDPOINT_DOWNLOAD_PDF_NACIONAL_PACOTE, request, options);
  }

  public downloadPacotePortalNacional(request: DownloadGeralRequest): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.post<HttpResponse<Blob>>(ENDPOINT_DOWNLOAD_NFSE_NACIONAL_PACOTE, request, options);
  }

  public sendUploadedXml(request: UploadRequest): Observable<void> {
    const formData = new FormData();
    if (request.arquivos?.length == 0 || request.idEmpresa == undefined || request.clienteLogin == undefined) {
      throw Error("Todos os campos da request devem ser preenchidos")
    } else {
      formData.append('idEmpresa', request.idEmpresa);
      formData.append('clienteLogin', request.clienteLogin);
      for (let i = 0; i < request.arquivos.length; i++) {
        formData.append('arquivos', request.arquivos[i]);
      }
    }
    return this.http.post<void>(ENDPOINT_UPLOAD_PORTAL, formData);
  }

  getParams(request: any): URLSearchParams {
    let params = new URLSearchParams();
    for (var propName in request) {
      if (!(propName == "dataInicio" || propName == "dataFim")) {
        if (request[propName]) {
          params.set(propName, request[propName]);
        }
      }
    }
    return params;
  }
}
