import { Calendar } from 'primeng/calendar';
import { Directive, AfterViewInit } from '@angular/core';

import * as $ from "jquery";

@Directive({
    selector: '[date-mask]'
})
export class DateMaskDirective implements AfterViewInit {

    constructor(
        private primeCalendar: Calendar
    ) { }

    ngAfterViewInit() {

        let input = this.primeCalendar.el.nativeElement.querySelector('input');

        $(input).attr('maxlength','10');

        this.primeCalendar.onInput.subscribe((event: InputEvent) => {
            let inputValue = $(input).val();
            if (inputValue) {
                let valuePermited = this.retornaSomenteNumeros(inputValue.toString());
                if (valuePermited.length >= 3 && valuePermited.length <= 4) {
                    valuePermited = [valuePermited.slice(0, 2), "/", valuePermited.slice(2)].join('');
                } else if (valuePermited.length >= 5) {
                    valuePermited = [valuePermited.slice(0, 2), "/", valuePermited.slice(2)].join('');
                    valuePermited = [valuePermited.slice(0, 5), "/", valuePermited.slice(5)].join('');
                }
                $(input).val(valuePermited);
            }
        });
    }

    retornaSomenteNumeros(val: string): string {
        return val.replace(/[^0-9]/g, '').replace(/(\..*?)\..*/g, '$1');
    }
}
