import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardReportDTO } from 'src/model/dashboard-report.model';

const ENDPOINT_DASHBOARD_NFSES = environment.API_MAIN + "/nfse/consulta-dashboard";
const ENDPOINT_DASHBOARD_DFES = environment.API_MAIN + "/dfe/consulta-dashboard";
const ENDPOINT_DASHBOARD_CTES = environment.API_MAIN + "/cte/consulta-dashboard";
const ENDPOINT_DASHBOARD_CFES = environment.API_MAIN + "/cfe/consulta-dashboard";
const ENDPOINT_DASHBOARD_PORTAL_NACIONAL = environment.API_MAIN + "/nfse-nacional/consulta-dashboard";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }

  public getDadosNFSeDashboard(ano: number|undefined, mes: number|undefined): Observable<Array<DashboardReportDTO>> {
    return this.http.get<Array<DashboardReportDTO>>(`${ENDPOINT_DASHBOARD_NFSES}/${ano}/${mes}`);
  }

  public getDadosDFeDashboard(ano: number|undefined, mes: number|undefined): Observable<Array<DashboardReportDTO>> {
    return this.http.get<Array<DashboardReportDTO>>(`${ENDPOINT_DASHBOARD_DFES}/${ano}/${mes}`);
  }

  public getDadosCTeDashboard(ano: number|undefined, mes: number|undefined): Observable<Array<DashboardReportDTO>> {
    return this.http.get<Array<DashboardReportDTO>>(`${ENDPOINT_DASHBOARD_CTES}/${ano}/${mes}`);
  }

  public getDadosCFeDashboard(ano: number|undefined, mes: number|undefined): Observable<Array<DashboardReportDTO>> {
    return this.http.get<Array<DashboardReportDTO>>(`${ENDPOINT_DASHBOARD_CFES}/${ano}/${mes}`);
  }

  public getDadosPortalNacionalDashboard(ano: number|undefined, mes: number|undefined): Observable<Array<DashboardReportDTO>> {
    return this.http.get<Array<DashboardReportDTO>>(`${ENDPOINT_DASHBOARD_PORTAL_NACIONAL}/${ano}/${mes}`);
  }
}
