import { AuthService } from '../../../../services/auth.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MessageService, MenuItem } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { saveAs } from 'file-saver';
import { Page } from 'src/model/page.model';
import { Cliente } from 'src/model/cliente.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MainComponent } from '../main.component';
import { Periodo } from 'src/model/periodo.model';
import { Empresa } from 'src/model/empresa.model';
import { CfeService } from 'src/services/cfe.service';
import { CfeRequest } from 'src/model/cfe-request.model';
import { CFe } from 'src/model/cfe.model';
import { NotificacoesService } from 'src/services/notificacoes.service';


@Component({
  selector: 'app-cte',
  templateUrl: './cfe.component.html',
  styleUrls: ['./cfe.component.scss']
})
export class CfeComponent implements OnInit, OnDestroy {

  @ViewChild('dt') table!: Table;

  userLogged!: Cliente;

  timeout: any;

  cfeRequest: CfeRequest = new CfeRequest();
  pageSizes = [10, 15, 20, 50, 100];
  periodo: Periodo | undefined;

  selectedEmpresa?: Empresa = undefined;

  rowsPerPageSelected?: number = 10;
  checkedSelectionAll: boolean = false;

  cfePage: Page<CFe> = new Page<CFe>();
  selectedRows: any[] = [];

  // Context Menu - Linha da Grid
  selectedCfeContextMenu?: CFe;
  itemsContextMenu: MenuItem[];

  loading: boolean = false;

  base64Pdf?: any;

  subscriptionEmpresaChange: any;
  subscriptionPeriodoChange: any;
  subscriptionNotificacoes: any;

  isEnableCfePage = true;

  constructor(
    private cfeService: CfeService,
    private messageService: MessageService,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    private main: MainComponent,
    private notificacoesService: NotificacoesService
  ) {
    this.base64Pdf = this.domSanitizer.bypassSecurityTrustResourceUrl('');
    this.itemsContextMenu = [
      {label: 'Baixar XML', icon: 'pi pi-fw pi-download', command: () => { this.downloadCfe(this.selectedCfeContextMenu?.chaveAcesso!) }}
    ];
  }

  ngOnInit(): void {

    this.cfePage.totalElements = 0;

    this.userLogged = this.authService.getUsuario()!;

    if (this.main.selectedEmpresa) {
      this.selectedEmpresa = this.main.selectedEmpresa;
      this.onChangeEmpresa();
    }

    this.subscriptionEmpresaChange = this.main.empresaChange.subscribe((empresa: Empresa) => {
      this.selectedEmpresa = empresa;
      this.onChangeEmpresa();
      this.isEnableCfePage = empresa.modulos.cfeModulo.cfeEnable;
    });

    this.subscriptionPeriodoChange = this.main.periodoChange.subscribe(() => {
      this.cfeRequest.chaveAcesso = "";
      this.cfeRequest.dataInicio = this.main.periodo.dataInicio;
      this.cfeRequest.dataFim = this.main.periodo.dataFim;
      this.buscaCfeConsultadas(this.cfeRequest);
    });

    this.subscriptionNotificacoes = this.notificacoesService.onReceiveNovasNotas.subscribe((modulo: string) => {
      if (modulo == "CF-e") {
        this.refresh();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptionEmpresaChange?.unsubscribe();
    this.subscriptionPeriodoChange?.unsubscribe();
  }

  onChangeContextOptions() {
    this.onChangeEmpresa();
  }

  removeHighlightContextmenu() {
    $(".p-highlight-contextmenu").removeClass("p-highlight-contextmenu");
  }

  onClickBusca() {
    this.selectedRows = [];
    this.buscaCfeConsultadas(this.cfeRequest);
  }

  private onChangeEmpresa() {
    this.selectedRows = [];
    this.cfeRequest.cnpjEmitente = this.selectedEmpresa?.id;

    this.cfeRequest.pageNum = 0;
    if (this.rowsPerPageSelected) {
      this.cfeRequest.pageSize = this.rowsPerPageSelected;
    }
    this.buscaCfeConsultadas(this.cfeRequest);
  }

  onSelectionChange(event: []) {
    this.selectedRows = event;
  }

  onClickFiltrarChaveAcesso() {
    this.cfeRequest.chaveAcesso = this.cfeRequest.chaveAcesso?.trim();
    this.cfeRequest.dataInicio = undefined;
    this.cfeRequest.dataFim = undefined;
    this.buscaCfeConsultadas(this.cfeRequest);
  }

  limpaFiltro(campo: string) {
    (this.cfeRequest as any)[campo] = "";
    this.cfeRequest.dataInicio = this.main.periodo.dataInicio;
    this.cfeRequest.dataFim = this.main.periodo.dataFim;
    this.buscaCfeConsultadas(this.cfeRequest);
  }

  private buscaCfeConsultadas(cfeRequest: CfeRequest) {
    if (cfeRequest.cnpjEmitente) {

      if (!this.cfeRequest.chaveAcesso) {
        const dataInicio = cfeRequest.dataInicio ? cfeRequest.dataInicio.toISOString().split('T')[0] :  this.main.periodo.dataInicio?.toISOString().split('T')[0];
        const dataFim = cfeRequest.dataFim ? cfeRequest.dataFim.toISOString().split('T')[0] :  this.main.periodo.dataFim?.toISOString().split('T')[0];

        this.cfeRequest.dataEmissaoInicioStr = dataInicio;
        this.cfeRequest.dataEmissaoFimStr = dataFim;
      } else {
        this.cfeRequest.dataEmissaoInicioStr = undefined;
        this.cfeRequest.dataEmissaoFimStr = undefined;
      }

      this.loading = true;
      this.showModalLoading();
      this.cfeService.consultaCFe(cfeRequest).subscribe({
        next: (data) => {
          this.cfePage = data;
          this.loading = false;
          this.hideModalLoading();
        },
        error: (e) => {
          this.showMensagemErro("Erro", e.message);
          this.loading = false;
          this.hideModalLoading();
        }
      });
    }
  }

  public lazyLoad(event: TableLazyLoadEvent) {
    if (event.first != undefined && event.rows != undefined) {
      this.cfeRequest.pageNum = event.first / event.rows;
      this.cfeRequest.pageSize = event.rows;
      this.buscaCfeConsultadas(this.cfeRequest);
    }
  }

  refresh() {
    this.buscaCfeConsultadas(this.cfeRequest);
  }

  public onClickSelectedAllRows() {
    let selection: any = [];
    this.selectedRows.forEach(item => {
      selection.push(item);
    });
    this.selectAllPageItems(selection);
  }

  private selectAllPageItems(selection: any) {
    this.cfePage.content.forEach(cfe => {
      const found = this.selectedRows.find(item => cfe.chaveAcesso == item.chaveAcesso);
      if (found == undefined) {
        selection.push(cfe);
      }
    });
    this.selectedRows = selection;
  }

  downloadCfe(chaveAcesso: String) {
    this.cfeService.downloadCFe(chaveAcesso).subscribe(res => {
      let filename = res.headers.get('content-disposition')?.split('filename=')[1];
      if (res.body) {
        saveAs(res.body, filename);
      }
    });
  }

  downloadCfeSelecionadas() {
    let ids: String[] = [];
    this.selectedRows.forEach(item => {
      ids.push(item.chaveAcesso);
    });
    if (ids.length == 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Nenhum registro selecionado contendo NF-e para download.',
        life: 10000
      });
    } else {
      this.cfeService.downloadCFeLote(ids).subscribe(res => {
        let filename = res.headers.get('content-disposition')?.split('filename=')[1];
        if (res.body) {
          saveAs(res.body, filename);
        }
      });
    }
  }

  private showMensagemInfo(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'info',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 5000
    });
  }

  private showMensagemErro(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  private showMensagemAlerta(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  private showModalLoading() {
    this.loading = this.main.showModalLoading();
  }

  private hideModalLoading() {
    this.loading = this.main.hideModalLoading();
  }

  showModalDanfe = false;
  showProgressSpinModalDanfe = true;
}
