import { FormsModule } from '@angular/forms';
import { NFeTipo } from './../../../../../model/enums/nfe-tipo.enum';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputGroupModule } from 'primeng/inputgroup';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { Cliente } from 'src/model/cliente.model';
import { DfeRequest } from 'src/model/dfe-request.model';
import { DFeResponse } from 'src/model/dfe-response.model';
import { Empresa } from 'src/model/empresa.model';
import { Page } from 'src/model/page.model';
import { DfeService } from 'src/services/dfe.service';
import { AuthService } from 'src/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MainComponent } from '../../main.component';
import { NotificacoesService } from 'src/services/notificacoes.service';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { saveAs } from 'file-saver';
import { DfeCteSefazRequest } from 'src/model/dfe-cte-sefaz-request.model';

@Component({
  selector: 'app-captura-nfe',
  standalone: true,
  imports: [TableModule, CommonModule, InputGroupModule, ButtonModule, SelectButtonModule, FormsModule, ContextMenuModule, DialogModule, ProgressSpinnerModule],
  templateUrl: './captura-nfe.component.html',
  styleUrl: './captura-nfe.component.scss'
})
export class CapturaNfeComponent implements OnInit, OnDestroy {

  contextOptions: any[] = [
    { icon: 'pi pi-download', label: "Saida", value: NFeTipo.SAIDA, disabled : false },
    { icon: 'pi pi-upload', label: "Entrada", value: NFeTipo.ENTRADA, disabled : false },
  ];
  contextSel: any;

  userLogged!: Cliente;

  timeout: any;

  nfeRequest: DfeRequest = new DfeRequest();

  selectedEmpresa?: Empresa;

  rowsPerPageSelected?: number = 10;
  checkedSelectionAll: boolean = false;

  dfePage: Page<DFeResponse> = new Page<DFeResponse>();
  NFeTipo = NFeTipo;
  selectedRows: DFeResponse[] = [];
  pageSizes = [10, 15, 20, 50, 100];

  // Context Menu - Linha da Grid
  itemsContextMenu?: MenuItem[];

  loading: boolean = false;

  base64Pdf?: any;

  onEmpresaChangeSub: any;
  onPeriodoChangeSub: any;
  onNotificacoesSub: any;

  showModalDanfe = false;
  showProgressSpinModalDanfe = true;

  constructor(
    private dfeService: DfeService,
    private messageService: MessageService,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    private main: MainComponent,
    private notificacoesService: NotificacoesService
  ) {
    this.base64Pdf = this.domSanitizer.bypassSecurityTrustResourceUrl('');
  }

  ngOnInit(): void {
    this.contextSel = localStorage.getItem('contextSel') ? JSON.parse(localStorage.getItem('contextSel') || 'null') : this.contextOptions[0];
    this.dfePage.totalElements = 0;
    this.userLogged = this.authService.getUsuario()!;

    if (this.main.selectedEmpresa) {
      this.selectedEmpresa = this.main.selectedEmpresa;
      this.nfeRequest.cnpjCpfDestinatario = this.selectedEmpresa?.id;
    }

    this.onEmpresaChangeSub = this.main.empresaChange.subscribe((empresa: Empresa) => {
      this.selectedEmpresa = empresa;
      this.nfeRequest.cnpjCpfDestinatario = this.selectedEmpresa?.id;
      this.onChangeEmpresa();
    });

    this.onPeriodoChangeSub = this.main.periodoChange.subscribe(() => {
      this.nfeRequest.chaveAcesso = "";
      this.nfeRequest.dataInicio = this.main.periodo.dataInicio;
      this.nfeRequest.dataFim = this.main.periodo.dataFim;
      this.buscaDFeConsultadas(this.nfeRequest);
    });

    this.onNotificacoesSub = this.notificacoesService.onReceiveNovasNotas.subscribe(notificacao => {
      if (notificacao.modulo == "NF-e" && notificacao.empresaCnpj == this.selectedEmpresa?.id) {
        this.refresh();
      }
    });

    this.refresh();
  }

  ngOnDestroy(): void {
    this.onEmpresaChangeSub?.unsubscribe();
    this.onPeriodoChangeSub?.unsubscribe();
    this.onNotificacoesSub?.unsubscribe();
  }

  getContextMenuItems(selectedDFe: any) {
    const items = [];
    if (selectedDFe?.tipo === NFeTipo.NFE_COMPLETA) {
      items.push({
        label: 'Visualizar DANFe',
        icon: 'pi pi-fw pi-eye',
        command: () => { this.openModalDanfe(selectedDFe.chaveAcesso!) }
      });
    }
    items.push({
      label: 'Baixar XML',
      icon: 'pi pi-fw pi-download',
      command: () => { this.downloadDFe(selectedDFe.chaveAcesso!) }
    });
    return items;
  }

  onContextMenu(dfe: any) {
    this.itemsContextMenu = this.getContextMenuItems(dfe);
  }

  removeHighlightContextmenu() {
    $(".p-highlight-contextmenu").removeClass("p-highlight-contextmenu");
  }

  private onChangeEmpresa() {
    this.selectedRows = [];
    this.nfeRequest.cnpjCpfDestinatario = this.selectedEmpresa?.id;
    this.nfeRequest.cnpjCpfEmitente = undefined;
    this.nfeRequest.pageNum = 0;
    if (this.rowsPerPageSelected) {
      this.nfeRequest.pageSize = this.rowsPerPageSelected;
    }
    this.buscaDFeConsultadas(this.nfeRequest);
  }

  onClickFiltrarChaveAcesso() {
    this.nfeRequest.chaveAcesso = this.nfeRequest.chaveAcesso?.trim();
    this.nfeRequest.dataInicio = undefined;
    this.nfeRequest.dataFim = undefined;
    this.buscaDFeConsultadas(this.nfeRequest);
  }

  limpaFiltro(campo: string) {
    (this.nfeRequest as any)[campo] = "";
    this.nfeRequest.dataInicio = this.main.periodo.dataInicio;
    this.nfeRequest.dataFim = this.main.periodo.dataFim;
    this.buscaDFeConsultadas(this.nfeRequest);
  }

  private buscaDFeConsultadas(nfeRequest: DfeRequest) {
    setTimeout(() => {
      if (nfeRequest.cnpjCpfDestinatario || nfeRequest.cnpjCpfEmitente) {
        if (!this.nfeRequest.chaveAcesso) {
          const dataInicio = nfeRequest.dataInicio ? nfeRequest.dataInicio.toISOString().split('T')[0] : this.main.periodo.dataInicio?.toISOString().split('T')[0];
          const dataFim = nfeRequest.dataFim ? nfeRequest.dataFim.toISOString().split('T')[0] : this.main.periodo.dataFim?.toISOString().split('T')[0];

          this.nfeRequest.dataEmissaoInicioStr = dataInicio;
          this.nfeRequest.dataEmissaoFimStr = dataFim;
        } else {
          this.nfeRequest.dataEmissaoInicioStr = undefined;
          this.nfeRequest.dataEmissaoFimStr = undefined;
        }
        this.nfeRequest.context = this.contextSel.value;
        this.nfeRequest.clienteLogin = this.userLogged.login;
        this.loading = true;
        this.dfeService.consultaDFe(nfeRequest).subscribe({
          next: (data) => {
            this.dfePage = data;
            this.loading = false;
            console.log("Concluido busca de notas no banco de dados.")
          },
          error: (e) => {
            var message = "Ocorreu um erro ao consultar as notas.";
            if (e.error.message) {
              message = e.error.message;
            } else if (e.status == 0) {
              message = "Serviço " + e.url.split("?")[0] + " está offline";
            } else if (e.message) {
              message = e.message;
            }
            this.showMensagemErro("Erro", message);
          }
        });
      }
    });
  }

  onChangeContextOptions(event: any){
    if (event.value == null) {
      setTimeout(() => {
        if (this.nfeRequest.context == undefined) {
          this.contextSel = this.contextOptions[0];
        } else {
          this.contextSel = this.contextOptions.find((t) => t.value == this.nfeRequest.context);
        }
      }, 10);
    } else {
      localStorage.setItem('contextSel', JSON.stringify(event.value));
      this.nfeRequest.context = event.value.value;
      this.buscaDFeConsultadas(this.nfeRequest);
    }
  }

  public lazyLoad(event: TableLazyLoadEvent) {
    if (event.first != undefined && event.rows != undefined) {
      this.nfeRequest.pageNum = event.first / event.rows;
      this.nfeRequest.pageSize = event.rows;
      if (!localStorage.getItem('selectedEmpresa')) {
        this.buscaDFeConsultadas(this.nfeRequest);
      }
    }
  }

  refresh() {
    this.buscaDFeConsultadas(this.nfeRequest);
  }

  private downloadDFe(chaveAcesso: String) {
    this.dfeService.downloadDFe(chaveAcesso).subscribe(res => {
      let filename = res.headers.get('content-disposition')?.split('filename=')[1];
      if (res.body) {
        saveAs(res.body, filename);
      }
    });
  }

  downloadDFeSelecionadas() {
    let ids: String[] = [];
    this.selectedRows.forEach(item => {
      ids.push(item.chaveAcesso);
    });
    if (ids.length == 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Nenhum registro selecionado contendo NF-e para download.',
        life: 10000
      });
    } else {
      this.dfeService.downloadDFeLote(ids).subscribe(res => {
        let filename = res.headers.get('content-disposition')?.split('filename=')[1];
        if (res.body) {
          saveAs(res.body, filename);
        }
      });
    }
  }

  consultaWebserviceNfe() {
    let request: DfeCteSefazRequest = new DfeCteSefazRequest();
    if (this.selectedEmpresa?.id) {
      let currentDate: Date = new Date();
      request.dataConsulta = currentDate.toLocaleString();
      request.tpAmb = '1';
      request.cnpj = this.selectedEmpresa.id;
      if (this.userLogged.login.toLowerCase() == 'admin') {
        request.clienteLogin = this.userLogged.login + '-' + this.selectedEmpresa.suporteLogin;
      } else {
        request.clienteLogin = this.userLogged.login;
      }
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Consulta DF-e',
        detail: 'CNPJ/CPF não informado',
        life: 10000
      });
      return
    }
    this.dfeService.consultaSefaz(request).subscribe({
      next: () => {
        this.showMensagemInfo("Requisição enviada", "Aguardar a notificação da conclusão do processamento.");
      },
      error: (e) => {
        this.showMensagemErro("Ocorreu um erro", "");
        console.error(e);
      }
    });
  }

  private showMensagemInfo(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'info',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 5000
    });
  }

  private showMensagemErro(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  openModalDanfe(chaveAcesso: String) {
    this.showModalDanfe = true;
    this.showProgressSpinModalDanfe = true;
    this.base64Pdf = this.domSanitizer.bypassSecurityTrustResourceUrl(this.dfeService.generateUrlGetDanfePdf(chaveAcesso));
    setTimeout(() => {
      document.getElementById("pdfViewer")!.onload = () => {
        this.showProgressSpinModalDanfe = false;
      };
    });
  }

  closeModalDanfe() {
    ($('#modal-danfe') as any).modal('hide');
  }
}
