import { MessageService } from 'primeng/api';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MensagemService {

    constructor(private messageService: MessageService) {}

    public showMensagemSucesso(summary: string, detail: string) {
        this.messageService.add({
            key: 'to-message-service',
            severity: 'success',
            summary: summary,
            detail: detail,
            life: 5000
        });
    }

    public showMensagemAlerta(summary: string, detail: string) {
        this.messageService.add({
            key: 'to-message-service',
            severity: 'warn',
            summary: '' + summary,
            detail: '' + detail,
            life: 10000
        });
    }

    public showMensagemErro(summary: string, detail: string) {
        this.messageService.add({
            key: 'to-message-service',
            severity: 'error',
            summary: '' + summary,
            detail: '' + detail,
            life: 10000
        });
    }

    public showMensagemInfo(mensagem: string, detalhe: string) {
      this.messageService.add({
        key: 'to-message-service',
        severity: 'info',
        summary: '' + mensagem,
        detail: '' + detalhe,
        life: 5000
      });
    }
}
