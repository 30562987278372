import { cnpj, cpf } from 'cpf-cnpj-validator';
import { AuthService } from '../../../../services/auth.service';
import { Cliente } from 'src/model/cliente.model';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from 'src/services/dashboard.service';
import { DashboardReportDTO } from 'src/model/dashboard-report.model';
import { EmpresaPagedRequest } from 'src/model/empresa-paged-request.model';
import { MainComponent } from '../main.component';
import { Subscription } from 'rxjs';
import { ClienteTipo } from 'src/model/enums/cliente-tipo.enum';
import { EmpresaService } from 'src/services/empresas.service';

enum TipoDoc {
  NFSePrestada = "NFS-e prestadas",
  NFSeTomada = "NFS-e tomadas",
  NFe = "NF-e completas",
  CTe = "CT-e",
  CFe = "CF-e",
  PortalNacional = "Sistema Nacional NFS-e"
};

interface DashboardItem {
  cor: string;
  tipoDoc: TipoDoc;
  quantidadeDocs: number;
  valorTotal: number;
  conteudoCarregou: boolean;
};

interface PanelMenuItem {
  borderStyle?: string;
  id?: string;
  icon: string;
  label?: string;
  quantidade?: number | undefined;
  items: PanelMenuItem[];
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, OnDestroy {

  userLogged!: Cliente;

  dashboardContent: DashboardItem[] = [
    {
      cor: "#fff875",
      tipoDoc: TipoDoc.PortalNacional,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    },
    {
      cor: "#d0e1fd",
      tipoDoc: TipoDoc.NFSePrestada,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    },
    {
      cor: "#caf1d8",
      tipoDoc: TipoDoc.NFSeTomada,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    },
    {
      cor: "#ffaca7",
      tipoDoc: TipoDoc.NFe,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    },
    {
      cor: "#ead6fd",
      tipoDoc: TipoDoc.CTe,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    },
    {
      cor: "#fcc39b",
      tipoDoc: TipoDoc.CFe,
      quantidadeDocs: 0,
      valorTotal: 0,
      conteudoCarregou: false
    }
  ];

  conteudosCarregaram: boolean = false;

  panelMenuRootContent: PanelMenuItem[] = [];
  panelMenuEmpresas: PanelMenuItem[] | undefined;

  empresasLogin: Array<{ id: string | undefined, nomeEmpresa: string | undefined }> = [];

  subscriptionPeriodo: Subscription | undefined;
  subscriptionNFSeNacional: Subscription | undefined;
  subscriptionNFSe: Subscription | undefined;
  subscriptionNFe: Subscription | undefined;
  subscriptionCTe: Subscription | undefined;
  subscriptionCFe: Subscription | undefined;

  chartData = {
    labels: this.dashboardContent.map(item => item.tipoDoc),
    datasets: [
      {
        label: '',
        backgroundColor: this.dashboardContent.map(item => item.cor),
        data: this.dashboardContent.map(item => item.quantidadeDocs)
      }
    ]
  };

  chartOptions = {
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          color: "#334155"
        }
      }
    }
  };

  meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho','Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  anoAtual: number | undefined;
  mesAtual: number | undefined;

  clienteTipo = ClienteTipo;

  expandedItems: { [key: string]: boolean } = {};

  constructor(
    private authService: AuthService,
    private dashboardService: DashboardService,
    private main: MainComponent,
    private empresaService: EmpresaService
  ) { };

  ngOnInit(): void {
    this.userLogged = this.authService.getUsuario()!;

    if (this.userLogged.clienteTipo === ClienteTipo.CLIENTE) {
      const empresaRequest: EmpresaPagedRequest = new EmpresaPagedRequest();
      empresaRequest.ativa = true;
      empresaRequest.clienteLogin = this.userLogged.suporteLogin;
      empresaRequest.unpaged = true;
      this.empresaService.getEmpresasByFilters(empresaRequest).subscribe({
        next: (empresas) => {
          this.empresasLogin = empresas.content.map((empresa) => { return { id: empresa.id, nomeEmpresa: empresa.nome } });
          this.consultaInfos();
        }
      });
    } else {
      if (this.main.empresasDropdown.length > 0) {
        this.consultaInfos();
      }
      this.main.empresasDropdownLoadedEvent.subscribe({
        next: () => {
          this.empresasLogin = this.main.empresasDropdown.map((empresa) => { return { id: empresa.id, nomeEmpresa: empresa.nome } });
          this.consultaInfos();
        }
      });
    }

    this.subscriptionPeriodo = this.main.periodoChange.subscribe(() => {
      this.limpaDashboard();
      this.consultaInfos();
    });
  };

  ngOnDestroy(): void {
    this.subscriptionNFe?.unsubscribe();
    this.subscriptionCTe?.unsubscribe();
    this.subscriptionCFe?.unsubscribe();
    this.subscriptionNFSe?.unsubscribe();
    this.subscriptionPeriodo?.unsubscribe();
    this.subscriptionNFSeNacional?.unsubscribe();
  };

  private consultaInfos() {
    this.carregaValores();
    this.consultaInfosNFSe();
    this.consultaInfosDFe();
    this.consultaInfosCTe();
    this.consultaInfosCFe();
    this.consultaInfosPortalNacional();
  };

  private carregaValores() {
    this.anoAtual = this.main.periodo.dataInicio?.getFullYear();
    this.mesAtual = this.main.periodo.dataInicio!.getMonth() + 1;
    const mesDisplay = this.meses[this.main.periodo.dataInicio!.getMonth()];

    this.expandedItems = {};

    this.panelMenuRootContent = [{
      id: "panelMenuRoot",
      icon: "pi pi-file",
      label: `Total de XMLs capturados no período de <b>${mesDisplay} - ${this.anoAtual}</b>`,
      quantidade: undefined,
      items: []
    }];

    this.panelMenuEmpresas = this.panelMenuRootContent;
  };

  private consultaInfosNFSe() {
    this.subscriptionNFSe = this.dashboardService.getDadosNFSeDashboard(this.anoAtual, this.mesAtual).subscribe(infoNFSeList => {
      const infoNotasTomadas: Array<DashboardReportDTO> = infoNFSeList.filter(infoNota => infoNota.tipoDoc == "NFS-e Tomada");
      const infoNotasPrestadas: Array<DashboardReportDTO> = infoNFSeList.filter(infoNota => infoNota.tipoDoc == "NFS-e Prestada");

      this.atualizaValores(
        TipoDoc.NFSeTomada,
        infoNotasTomadas,
        true
      );

      this.atualizaValores(
        TipoDoc.NFSePrestada,
        infoNotasPrestadas,
        true
      );
    });
  };

  private consultaInfosDFe() {
    this.subscriptionNFe = this.dashboardService.getDadosDFeDashboard(this.anoAtual, this.mesAtual).subscribe((infoDFeList) => {
      this.atualizaValores(
        TipoDoc.NFe,
        infoDFeList,
        true
      );
    });
  };

  private consultaInfosCTe() {
    this.subscriptionCTe = this.dashboardService.getDadosCTeDashboard(this.anoAtual, this.mesAtual).subscribe((infoCTeList) => {
      this.atualizaValores(
        TipoDoc.CTe,
        infoCTeList,
        true
      );
    });
  };

  private consultaInfosCFe() {
    this.subscriptionCFe = this.dashboardService.getDadosCFeDashboard(this.anoAtual, this.mesAtual).subscribe((infoCFeList) => {
      this.atualizaValores(
        TipoDoc.CFe,
        infoCFeList,
        true
      );
    });
  };

  private consultaInfosPortalNacional() {
    this.subscriptionNFSeNacional = this.dashboardService.getDadosPortalNacionalDashboard(this.anoAtual, this.mesAtual).subscribe((infoPortalNacionalList) => {
      this.atualizaValores(
        TipoDoc.PortalNacional,
        infoPortalNacionalList,
        true
      );
    });
  };

  private atualizaValores(tipo: TipoDoc, reportContent: Array<DashboardReportDTO>, carregou: boolean) {
    const quantidadeCalculada = reportContent.reduce((total, infoNota) => total + (infoNota.quantidadeDocs?? 0), 0);
    const valorCalculado = reportContent.reduce((total, infoNota) => total + (infoNota.valorTotalDocs?? 0), 0);
    this.dashboardContent = this.dashboardContent.map(item => {
      if (item.tipoDoc == tipo) {
        return {
          ...item,
          quantidadeDocs: quantidadeCalculada,
          valorTotal: valorCalculado,
          conteudoCarregou: carregou
        };
      };
      return item;
    });
    this.conteudosCarregaram = this.dashboardContent.every(content => content.conteudoCarregou);

    this.atualizaInformacoesPainel(tipo, reportContent);
    this.renderizaGrafico();
  };

  private atualizaInformacoesPainel(tipo: TipoDoc, dashboardReportContent: Array<DashboardReportDTO>) {
    dashboardReportContent.forEach(report => {
      const isNfse = (tipo == TipoDoc.NFSePrestada || tipo == TipoDoc.NFSeTomada);
      const nomeEmpresa = report.nomeEmpresa ?? this.empresasLogin.find(empresa => empresa.id == report.cnpjEmpresa)?.nomeEmpresa;
      const empresalabel = `<b>${report.cnpjEmpresa?.length == 14 ? cnpj.format(report.cnpjEmpresa!) : cpf.format(report.cnpjEmpresa!)}</b> - ${nomeEmpresa ?? ""}`.toUpperCase();

      const newMunicipioPanel: PanelMenuItem = {
        id: tipo + report.nomeMunicipio + report.cnpjEmpresa,
        icon: "pi pi-map-marker",
        label: report.nomeMunicipio,
        quantidade: report.quantidadeDocs,
        items: []
      }

      const newDocumentPanel: PanelMenuItem = {
        id: tipo + report.cnpjEmpresa,
        icon: "pi pi-file-check",
        label: tipo,
        quantidade: report.quantidadeDocs,
        items: (isNfse ? [newMunicipioPanel] : [])
      }

      const newEmpresaPanel: PanelMenuItem = {
        borderStyle: "2px solid #ebebeb",
        id: report.cnpjEmpresa,
        icon: "pi pi-building",
        label: empresalabel,
        quantidade: report.quantidadeDocs,
        items: [newDocumentPanel]
      }

      const currentPanelState: Array<PanelMenuItem> = this.panelMenuRootContent[0].items!;
      const empresaPanel = currentPanelState!.find(currContent => currContent.id == report.cnpjEmpresa)!;

      //se a empresa nao tiver sido adicionada ao painel
      if (empresaPanel == undefined) {
        currentPanelState.push(newEmpresaPanel);
      } else {
        empresaPanel.quantidade = (empresaPanel.quantidade?? 0) + (report.quantidadeDocs?? 0);
        empresaPanel.label = empresalabel;

        const documentPanel = empresaPanel?.items?.find(currDoc => currDoc.label == tipo.toString());

        //se o tipo de documento nao tiver sido adicionado ao painel da empresa
        if (documentPanel == undefined) {
          empresaPanel.items!.push(newDocumentPanel);
        } else {
          documentPanel.quantidade = (documentPanel.quantidade?? 0) + (report.quantidadeDocs?? 0);

          //se for nfse adicionar o painel de municipios dentro do painel de documentos
          //nao precisamos atualizar os valores do painel de municipio pois so ha 1 municipio por report de nfse
          if (isNfse) {
            documentPanel.items!.push(newMunicipioPanel)
          }
        }
      }
    })

    const updatedPanelMenu = this.panelMenuRootContent[0];
    updatedPanelMenu.quantidade = (updatedPanelMenu.quantidade?? 0) + (dashboardReportContent.reduce((total, infoNota) => total + (infoNota.quantidadeDocs?? 0), 0));
    updatedPanelMenu.items = updatedPanelMenu.items.sort(
      (a, b) => `${b.label!}${b.quantidade}`.length - `${a.label!}${a.quantidade}`.length
    );
    this.panelMenuRootContent = [updatedPanelMenu];
  }

  private limpaDashboard() {
    Object.keys(TipoDoc).forEach(category => {
      const item = TipoDoc[category as keyof typeof TipoDoc];
      this.atualizaValores(item, [] , false);
    })
  }

  private renderizaGrafico() {
    this.chartData = {
      labels: this.dashboardContent.map(item => item.tipoDoc ),
      datasets: [
        {
          label: '',
          backgroundColor: this.dashboardContent.map(item => item.cor ),
          data: this.dashboardContent.map(item => item.quantidadeDocs )
        }
      ]
    };
  };

  escurecerCor(cor: string, percent: number): string {
    return '#' + cor.replace(/^#/, '').replace(/../g, color => {
      const num = parseInt(color, 16) - Math.min(255, Math.round(255 * percent));
      const newColor = num < 0 ? 0 : num;
      return newColor.toString(16).padStart(2, '0');
    });
  };

  toggleExpand(itemId: string): void {
    this.expandedItems[itemId] = !this.expandedItems[itemId];
  }
}
