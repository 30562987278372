import { Nullable } from "primeng/ts-helpers";

export class EmpresaPlugNotas {
  _id?: string;
  cpfCnpj?: string;
  incentivadorCultural?: boolean;
  incentivoFiscal?: boolean;
  simplesNacional?: boolean;
  inscricaoMunicipal?: string;
  inscricaoEstadual?: string;
  certificado?: string;
  razaoSocial?: string;
  nomeFantasia?: string;
  regimeTributario: RegimeTributario | Nullable;
  regimeTributarioEspecial: RegimeTributarioEspecial | Nullable;
  telefone?: Telefone;
  email?: string;
  endereco: Endereco | undefined;
}

export class Telefone {
  ddd?: number;
  numero?: number;
}

export enum RegimeTributario {
  NENHUM = 0,
  SIMPLES_NACIONAL = 1,
  SIMPLES_NACIONAL_EXCESSO = 2,
  REGIME_NORMAL_LUCRO_PRESUMIDO = 3,
  REGIME_NORMAL_LUCRO_REAL = 4,
  MEI = 5
}

export enum RegimeTributarioEspecial {
  SEM_REGIME_TRIBUTARIO_ESPECIAL = 0,
  MICRO_EMPRESA_MUNICIPAL = 1,
  ESTIMATIVA = 2,
  SOCIEDADE_DE_PROFISSIONAIS = 3,
  COOPERATIVA = 4,
  MICROEMPRESARIO_INDIVIDUAL_MEI = 5,
  MICROEMPRESA_PEQUENO_PORTE_ME_EPP = 6,
  LUCRO_REAL = 7,
  LUCRO_PRESUMIDO = 8,
  TRIBUTACAO_NORMAL = 9,
  SIMPLES_NACIONAL_EXCESSO_SUBLIMITE = 10,
  EMPRESA_RESPONSABILIDADE_LIMITADA = 11
}

export class Endereco {
  logradouro?: string;
  numero?: string;
  complemento?: string;
  bairro?: string;
  codigoCidade?: string;
  descricaoCidade?: string;
  estado?: string;
  cep?: string;
}
