<div class="conteudo">

  <div class="login">

    <div>
      <img class="mt-1" style="height: 100px;" src="assets/images/logo-ereobot.png" alt="Logo MP Consultoria">
    </div>

    <form #form="ngForm" autocomplete (ngSubmit)="entrar()" style="width: 260px;" class="d-flex flex-column align-items-center">
      <div style="width: 260px;">
        <div class="mt-4">
          <div class="field">
            <label class="block">Login</label>
            <input pInputText type="text" id="txtLogin" [(ngModel)]="dadosLogin.login" [ngModelOptions]="{standalone: true}">
          </div>
        </div>
        <div *ngIf="!showChangePassworForm">
          <div class="field">
            <label for="txtSenha" class="block">Senha</label>
            <p-password
              id="txtSenha"
              [toggleMask]="true" [feedback]="false"
              [(ngModel)]="password"
              [ngModelOptions]="{standalone: true}">
            </p-password>
          </div>
        </div>

        <form autocomplete="off" class="mb-n4">
          <div *ngIf="showChangePassworForm">
            <div class="field">
              <label for="txtNovaSenha" class="block">Nova senha</label>
              <p-password
                id="txtNovaSenha"
                [toggleMask]="true"
                [feedback]="false"
                [(ngModel)]="newPassword"
                [ngModelOptions]="{standalone: true}"
                (input)="verificarNovaSenha()">
              </p-password>
            </div>
            <div class="mt-2" style="line-height: 1.5">
              <ul class="pl-2 ml-2 mt-0" style="list-style-type: none; line-height: 1.5;">
                <li id="minusc" class="inativo"><span class="icone" id="iconeMinusc"><i class="pi pi-times"></i></span>Pelo menos uma letra minúscula</li>
                <li id="maiusc" class="inativo"><span class="icone" id="iconeMaiusc"><i class="pi pi-times"></i></span>Pelo menos uma letra maiúscula</li>
                <li id="numerico" class="inativo"><span class="icone" id="iconeNumerico"><i class="pi pi-times"></i></span>Pelo menos um número</li>
                <li id="especial" class="inativo"><span class="icone" id="iconeEspecial"><i class="pi pi-times"></i></span>Pelo menos um caractere especial</li>
                <li id="tamanho" class="inativo"><span class="icone" id="iconeTamanho"><i class="pi pi-times"></i></span>No mínimo 8 caracteres</li>
              </ul>
            </div>
            <div class="field">
              <label for="txtConfirmaNovaSenha" class="block">Confirma nova senha</label>
              <p-password
                id="txtConfirmaNovaSenha"
                [toggleMask]="true"
                [feedback]="false"
                [(ngModel)]="confirmaNovaSenha"
                [ngModelOptions]="{standalone: true}">
              </p-password>
            </div>
          </div>
        </form>
      </div>
      <div style="height: 1.8rem; margin-top: 21px;" [ngClass]="{'mt-5' : !showChangePassworForm}">
        <small *ngIf="mensagemErroForm != ''" class="p-error block">{{mensagemErroForm}}</small>
      </div>
      <div class="w-100">
        <button
          pButton
          #btnEntrar
          type="submit"
          [label]="showChangePassworForm ? 'Salvar nova senha': 'Entrar'"
          class="p-button-primary"
          [disabled]="!todosRequesitosAtendidos()">
        </button>
      </div>
      <div *ngIf="showChangePassworForm">
        <div class="mt-3 pb-n5">
          <button
            pButton
            pRipple
            type="button"
            label="Fazer login com outro usuário"
            class="p-button-text"
            (click)="loginOutroUsuario()">
          </button>
        </div>
      </div>

      <div *ngIf="!showChangePassworForm" class="mt-5 w-10 d-flex justify-content-center">
        <button
          pButton
          pRipple
          type="button"
          label="Esqueci minha senha"
          class="p-button-text"
          (click)="esqueceuASenha()">
        </button>
      </div>

    </form>

    <!-- Mensagem via Service -->
    <p-toast position="center" [style]="{'width':'800px'}"></p-toast>

  </div>

  <div *ngIf="loading" class="mt-5">
    <p-progressSpinner strokeWidth="4"></p-progressSpinner>
  </div>

</div>
