import { MensagemService } from './../../services/mensagem.service';
import { AuthService } from './../../services/auth.service';
import { Observable, throwError, timer, retryWhen, mergeMap, of, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor {

  private maxRetry = 60;
  private retryDelay = 2000;

  constructor(
    private authService: AuthService,
    private router: Router,
    private mensagemService: MensagemService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.authService.getToken();
    if (token) {
      request = request.clone({
        headers: request.headers.append('Authorization', 'Bearer ' + token)
      });
    }

    if (!request.url.includes('/login')) {
      return next.handle(request).pipe(this.retryAfterDelay());
    } else {
      return next.handle(request);
    }
  }

  retryAfterDelay(): any {
    return retryWhen(errors => {
      return errors.pipe(
        mergeMap((err, count) => {
          console.error("RETORNO DETALHADO: " + JSON.stringify(err));
          if (err.status == HttpStatusCode.Forbidden) {
            this.authService.logout();
            this.router.navigate(['/login']);
          }
          if (err.status == HttpStatusCode.InternalServerError) {
            if (!err.url.toString().includes("/notificacoes/") && !err.message.includes('Http failure response for')) {
              this.mensagemService.showMensagemErro("Erro:", err.message);
            }
            count = this.maxRetry;
          }
          if (err.status == HttpStatusCode.NetworkAuthenticationRequired) {
            count = this.maxRetry;
          }
          if (count === this.maxRetry) {
            return throwError(err);
          }
          return of(err).pipe(
            tap(error => console.log(`Chamando novamente ${error.url}. Tentativa ${count + 1}`)),
            mergeMap(() => timer(this.retryDelay))
          );
        })
      );
    });
  }
}
