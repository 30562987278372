import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';
import { DadosMunicipioNfseModel } from 'src/model/dados-municipio-nfse.model';
import { GrupoMunicipios } from 'src/model/grupo-municipios.model';

const ENDPOINT_GET_DADOS_MUNICIPIOS_NFSE = environment.API_MAIN + "/nfse/dados-municipios";

@Injectable({
  providedIn: 'root'
})
export class MunicipiosNfseService {

  private dadosMunicipios: DadosMunicipioNfseModel[] = [];

  constructor (
    private http: HttpClient,
    private messageService: MessageService
  ) { }

  /*
    Obtem a lista de todos os estados dos municipios suportados pelo sistema
  */
  public getGrupoMunicipios(): Promise<GrupoMunicipios[]> {
    return new Promise<GrupoMunicipios[]>(resolve => {
      const ret: GrupoMunicipios[] = [];
      this.getMunicipios().then(data => {
        let municipios = data.sort((a, b) => {
          if (a.estadoSigla! < b.estadoSigla!) {
            return 1;
          }
          if (a.estadoSigla! > b.estadoSigla!) {
            return -1;
          }
          return 0;
        });

        let sigla: String = "";
        municipios.forEach(mun => {
          if (sigla != mun.estadoSigla) {
            sigla = mun.estadoSigla!;
            let grupo = new GrupoMunicipios();
            grupo.label = mun.estadoSigla!;
            grupo.value = Number(mun.codigo.toString().substring(0, 2));
            ret.push(grupo);
          }
          let grupo = new GrupoMunicipios();
          grupo.label = mun.municipio;
          grupo.value = mun.codigo;
          ret[ret.length - 1].items.push(grupo);
        })
        resolve(ret);
      })
    })
  }

  /*
    Obtem a lista de todos os municipios suportados pelo sistema
  */
  public getMunicipios(): Promise<DadosMunicipioNfseModel[]> {
    if (this.dadosMunicipios.length == 0) {
      try {
        return new Promise<DadosMunicipioNfseModel[]>(resolve => {
          this.loadDadosMunicipios().then(result => {resolve(result)});
        });
      } catch (error) {
        this.showMensagemErro("Erro", "Não foi possivel carregar lista de municípios. Tente novamente mais tarde.");
      }
    }
    return new Promise<DadosMunicipioNfseModel[]>(resolve => { resolve(this.dadosMunicipios) });
  }

  private async loadDadosMunicipios(): Promise<DadosMunicipioNfseModel[]> {
    return new Promise<DadosMunicipioNfseModel[]>(resolve => {
      this.http.get<DadosMunicipioNfseModel[]>(ENDPOINT_GET_DADOS_MUNICIPIOS_NFSE).subscribe({
        next: data => {
          this.dadosMunicipios = data;
          resolve(data);
        }
      });
    });
  }

  /*
    Obtem a lista de municipios suportados pelo sistema por estado
  */
  public getMunicipiosPeloCodigoUF(codigoUf: Number): DadosMunicipioNfseModel[] {
    return this.dadosMunicipios.filter(mun => { mun.codigo.toString().startsWith(codigoUf.toString()) });
  }

  /*
    Obtem da lista o municipio suportado pelo sistema pelo código dele
  */
  public getMunicipioPeloCodigo(codigoMun: Number): Promise<DadosMunicipioNfseModel> {
    return new Promise<DadosMunicipioNfseModel>(resolve => {
      this.getMunicipios().then(resp => {
        let ret = resp.find(mun => mun.codigo.toString() == codigoMun.toString());
        resolve(ret!);
      });
    });
  }

  private showMensagemErro(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

}
