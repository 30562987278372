import { NFSeTipo } from "./enums/nfse-tipo.enum";

export class DownloadNotasGeralRequest {
    clienteLogin: string | undefined;
    idEmpresa: string | undefined;
    dataInicio: Date | undefined;
    dataFinal: Date | undefined;
    isLoginDownload: boolean | undefined;
    isDownloadAbrasfNfse: boolean | undefined;
    tipoNfse: NFSeTipo | undefined;
}
