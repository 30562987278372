import { HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from "@angular/core";
import saveAs from 'file-saver';
import moment from 'moment-timezone';
import { MessageService } from 'primeng/api';
import { DadosMunicipioNfseModel } from 'src/model/dados-municipio-nfse.model';
import { DownloadGeralRequest } from 'src/model/download-notas-geral.request';
import { Empresa } from 'src/model/empresa.model';
import { UploadRequest } from 'src/model/upload-request';
import { AuthService } from "src/services/auth.service";
import { CfeService } from "src/services/cfe.service";
import { CteService } from 'src/services/cte.service';
import { DfeService } from 'src/services/dfe.service';
import { MunicipiosNfseService } from "src/services/municipios-nfse.service";
import { NFSeService } from 'src/services/nfse.service';
import { MainComponent } from '../main.component';
import { Cliente } from 'src/model/cliente.model';
import { PortalNaciocalService } from 'src/services/portal-nacional-nfse.service';
import { NFSeTipo } from 'src/model/enums/nfse-tipo.enum';

interface TipoDoc {
  name: string
  key: number
}

@Component({
  selector: 'app-importar-exportar-xml',
  templateUrl: './importar-exportar-xml.component.html',
  styleUrl: './importar-exportar-xml.component.scss'
})
export class ImportarExportarXmlComponent implements OnInit, OnDestroy {

  userLogged!: Cliente;
  selectedEmpresa?: Empresa;

  // Formato
  selectedFormatoExportacao: any;
  tipoFormato: TipoDoc[] = [
    { name: 'XML',  key: 1 },
    { name: 'PDF',  key: 2 },
  ];

  // Exportacao
  selectedTipoDocExportacao: any = null;
  abrasfXMLOption: boolean = false;
  tipoNfseExportacaoOption?: NFSeTipo;
  dataInicioExportacao?: Date;
  dataFinalExportacao?: Date;
  tipoDocumentoExportacao: TipoDoc[] = [
    { name: 'CT-e',  key: 1 },
    { name: 'NF-e',  key: 2 },
    { name: 'NFS-e', key: 3 },
    { name: 'CF-e',  key: 4 },
    { name: 'NFS-e Nacional',  key: 5 },
  ];

  // Importacao
  selectedTipoDocImportacao?: TipoDoc;
  municipiosContratados: DadosMunicipioNfseModel[] = [];
  tipoDocumentoImportacao: TipoDoc[] = [
    { name: 'NF-e',  key: 1 },
    { name: 'CT-e',  key: 2 },
    { name: 'NFS-e', key: 3 },
    { name: 'CF-e',  key: 4 },
    { name: 'NFS-e Nacional',  key: 5 }
  ];

  xmlUploadedFiles?: File[];
  todosMunicipios: DadosMunicipioNfseModel[] = [];
  selectedMunicipio?: DadosMunicipioNfseModel;

  subscriptionPeriodoChange: any;
  subscriptionEmpresaChange: any;

  constructor(
    private authService: AuthService,
    private municipiosNfseService: MunicipiosNfseService,
    private main: MainComponent,
    private nfseService: NFSeService,
    private cteService: CteService,
    private nfeService: DfeService,
    private cfeService: CfeService,
    private portalService: PortalNaciocalService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.dataInicioExportacao = new Date(this.main.periodo.dataInicio!.getFullYear(), this.main.periodo.dataInicio!.getMonth(), 1);
    this.dataFinalExportacao = new Date(this.main.periodo.dataFim!.getFullYear(), this.main.periodo.dataFim!.getMonth(), 7);

    this.subscriptionPeriodoChange = this.main.periodoChange.subscribe(() => {
      this.dataInicioExportacao = new Date(this.main.periodo.dataInicio!.getFullYear(), this.main.periodo.dataInicio!.getMonth(), 1);
      this.dataFinalExportacao = new Date(this.main.periodo.dataFim!.getFullYear(), this.main.periodo.dataFim!.getMonth(), 7);
    });

    this.municipiosNfseService.getMunicipios().then(muns => {
      this.todosMunicipios = muns;

      if (this.main.selectedEmpresa) {
        this.selectedEmpresa = this.main.selectedEmpresa;
      }

      this.subscriptionEmpresaChange = this.main.empresaChange.subscribe((empresa: Empresa) => {
        this.selectedEmpresa = empresa;
        this.carregaCampoMunicipiosContratados();
      });
    })

    this.userLogged = this.authService.getUsuario()!;

    this.municipiosNfseService.getMunicipios().then(muns => {
      this.municipiosContratados = muns;
      this.carregaCampoMunicipiosContratados();
    });

    if (this.main.selectedEmpresa) {
      this.selectedEmpresa = this.main.selectedEmpresa;
    }

    this.selectedTipoDocExportacao = { key: null, name: 'Nenhum' };

    this.subscriptionEmpresaChange = this.main.empresaChange.subscribe((empresa: Empresa) => {
      this.selectedEmpresa = empresa;
      this.carregaCampoMunicipiosContratados();
    });

    if (this.main.selectedEmpresa) {
      this.selectedEmpresa = this.main.selectedEmpresa;
    }

    this.selectedEmpresa = this.main.getSelectedEmpresa();

    this.carregaCampoMunicipiosContratados();
  }

  ngOnDestroy(): void {
    this.subscriptionPeriodoChange?.unsubscribe();
    this.subscriptionEmpresaChange?.unsubscribe();
  }

  onClickRadioButtonDocExportar(selectedDoc:TipoDoc) {
    this.selectedTipoDocExportacao = selectedDoc;
    this.tipoNfseExportacaoOption = NFSeTipo.TOMADA;
    if (this.selectedTipoDocExportacao.key != 3) {
      this.abrasfXMLOption = false;
      this.tipoNfseExportacaoOption = undefined;
    }
  }

  // Download de notas por data
  downloadPacoteNotas(tipoDoc: TipoDoc, isLoginDownload: boolean) {

    if (!this.selectedEmpresa?.id && !isLoginDownload) {
      this.showMensagemAlerta("", "Nenhuma empresa foi selecionada");
      return
    }

    this.verificaDataFinal();

    const requestExportacao = new DownloadGeralRequest();
    requestExportacao.isLoginDownload = isLoginDownload;
    requestExportacao.idEmpresa = this.selectedEmpresa?.id?.toString();
    requestExportacao.clienteLogin = this.userLogged.login;
    requestExportacao.dataInicio = this.dataInicioExportacao? new Date(this.dataInicioExportacao.getTime() - this.dataInicioExportacao.getTimezoneOffset() * 60000) : undefined;
    requestExportacao.dataFinal = this.dataFinalExportacao? new Date(this.dataFinalExportacao.getTime() - this.dataFinalExportacao.getTimezoneOffset() * 60000) : undefined;
    requestExportacao.isDownloadAbrasfNfse = this.abrasfXMLOption;
    requestExportacao.tipoNfse = this.tipoNfseExportacaoOption;
    if (isLoginDownload) {
      requestExportacao.municipiosLoginEmpresa = [...new Set(this.main.empresasDropdown
        ?.flatMap(empresa => empresa.modulos.nfseModulo.nfseMunicipios)
        .map(municipio => municipio.codigoMun)
        .filter((codigoMun): codigoMun is number => codigoMun !== undefined) || [])];
    } else {
      requestExportacao.municipiosLoginEmpresa = this.selectedEmpresa?.modulos.nfseModulo.nfseMunicipios?.map(municipio => municipio.codigoMun).filter((codigoMun): codigoMun is number => codigoMun !== undefined) || [];
    }

    const downloadFunctions: { [key: number]: () => void } = {
      1: () => this.downloadPacoteNotasCTe(requestExportacao),
      2: () => this.downloadPacoteNotasNFe(requestExportacao),
      3: () => this.downloadPacoteNotasNFSe(requestExportacao),
      4: () => this.downloadPacoteNotasCFE(requestExportacao),
      5: () => this.downloadPacoteNotasPortalNacional(requestExportacao)
    }

    if (this.verificaDatas(requestExportacao.dataInicio, requestExportacao.dataFinal)) {
      this.showMensagemInfo("Requisição enviada", "Aguarde até que o download seja iniciado.");
      downloadFunctions[tipoDoc.key]()
    }
  }

  downloadPacotePdf(tipoDoc: TipoDoc, isLoginDownload: boolean) {
    if (!this.selectedEmpresa?.id && !isLoginDownload) {
      this.showMensagemAlerta("", "Nenhuma empresa foi selecionada");
      return
    }

    this.verificaDataFinal();

    const requestExportacao = new DownloadGeralRequest();
    requestExportacao.isLoginDownload = isLoginDownload;
    requestExportacao.idEmpresa = this.selectedEmpresa?.id?.toString();
    requestExportacao.clienteLogin = this.userLogged.login;
    requestExportacao.dataInicio = this.dataInicioExportacao? new Date(this.dataInicioExportacao.getTime() - this.dataInicioExportacao.getTimezoneOffset() * 60000) : undefined;
    requestExportacao.dataFinal = this.dataFinalExportacao? new Date(this.dataFinalExportacao.getTime() - this.dataFinalExportacao.getTimezoneOffset() * 60000) : undefined;
    requestExportacao.tipoNfse = this.tipoNfseExportacaoOption;

    const downloadFunctions: { [key: number]: () => void } = {
      1: () => this.downloadPacotePdfCTe(requestExportacao),
      2: () => this.downloadPacotePdfNFe(requestExportacao),
      3: () => this.downloadPacotePdfNFSe(requestExportacao),
      4: () => this.downloadPacotePdfCFe(requestExportacao),
      5: () => this.downloadPacotePdfPortalNacional(requestExportacao)
    }

    if (this.verificaDatas(requestExportacao.dataInicio, requestExportacao.dataFinal)) {
      this.showMensagemInfo("Requisição enviada", "Aguarde até que o download seja iniciado.");
      downloadFunctions[tipoDoc.key]();
    }
  }

  private verificaDataFinal() {
    this.dataFinalExportacao?.setHours(23, 59, 59, 999);
  }

  private verificaDatas(dataInicio?: Date, dataFim?: Date): boolean {
    const dataInicioUtc = moment(dataInicio).tz('UTC').startOf('day');
    const dataFimUtc = moment(dataFim).tz('UTC').endOf('day');

    if (!dataInicio || !dataFim) {
      this.showMensagemAlerta("Alerta", "Datas inseridas incorretamente");
      return false;
    }

    if (dataInicio > dataFim) {
      this.showMensagemAlerta("Alerta", "Data inicial maior que data final");
      return false;
    }

    if (dataFimUtc.diff(dataInicioUtc, "days") > 7) {
      this.showMensagemAlerta("Alerta", "O período inserido deve incluir um intervalo de até 7 dias");
      return false;
    }

    return true;
  }

  private downloadPacoteNotasNFe(request: DownloadGeralRequest) {
    this.nfeService.downloadPacoteNotasFiscais(request).subscribe({
      next: blob => {
        if (!blob.body) {
          this.showMensagemInfo("Aviso", "Nenhum arquivo NFE encontrado no período selecionado.");
        } else {
          this.triggerDownload(blob);
        }
      },
      error: e => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private downloadPacoteNotasCTe(request: DownloadGeralRequest) {
    this.cteService.downloadPacoteConhecimentoTransporteEletronico(request).subscribe({
      next: blob => {
        if (!blob.body) {
          this.showMensagemInfo("Aviso", "Nenhum arquivo CTE encontrado no período selecionado.");
        } else {
          this.triggerDownload(blob);
        }
      },
      error: e => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private downloadPacoteNotasNFSe(request: DownloadGeralRequest) {
    this.nfseService.downloadPacoteNotasFiscaisEletronicas(request).subscribe({
      next: blob => {
        if (!blob.body) {
          this.showMensagemInfo("Aviso", "Nenhum arquivo NFSE encontrado no período selecionado.");
        } else {
          this.triggerDownload(blob);
        }
      },
      error: e => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private downloadPacoteNotasCFE(request: DownloadGeralRequest) {
    this.cfeService.downloadPacoteCupomFiscal(request).subscribe({
      next: blob => {
        if (!blob.body) {
          this.showMensagemInfo("Aviso", "Nenhum arquivo CF-E encontrado no período selecionado.");
        } else {
          this.triggerDownload(blob);
        }
      },
      error: e => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private downloadPacoteNotasPortalNacional(request: DownloadGeralRequest) {
    this.portalService.downloadPacotePortalNacional(request).subscribe({
      next: blob => {
        if (!blob.body) {
          this.showMensagemInfo("Aviso", "Nenhuma nota encontrado no período selecionado.");
        } else {
          this.triggerDownload(blob);
        }
      },
      error: e => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }


  private downloadPacotePdfPortalNacional(request: DownloadGeralRequest) {
    this.portalService.downloadPacotePdfNotasFiscais(request).subscribe({
      next: blob => {
        if (!blob.body) {
          this.showMensagemInfo("Aviso", "Nenhuma nota encontrado no período selecionado.");
        } else {
          this.triggerDownload(blob);
        }
      },
      error: e => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private downloadPacotePdfCFe(request: DownloadGeralRequest) {
    this.showMensagemInfo("Aviso", "Visualização de PDF ainda não implementado.")
    /*this.cfeService.downloadPacotePdfCupomFiscal(request).subscribe({
      next: blob => {
        if (!blob.body) {
          this.showMensagemInfo("Aviso", "Nenhum arquivo CF-E encontrado no período selecionado.");
        } else {
          this.triggerDownload(blob);
        }
      },
      error: e => {
        this.showMensagemErro("Erro", e.message);
      }
    });*/
  }

  private downloadPacotePdfNFe(request: DownloadGeralRequest) {
    this.nfeService.downloadPacotePdfNotasFiscais(request).subscribe({
      next: blob => {
        if (!blob.body) {
          this.showMensagemInfo("Aviso", "Nenhum arquivo NFE encontrado no período selecionado.");
        } else {
          this.triggerDownload(blob);
        }
      },
      error: e => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private downloadPacotePdfCTe(request: DownloadGeralRequest) {
    this.cteService.downloadPacotePdfConhecimentoTransporteEletronico(request).subscribe({
      next: blob => {
        if (!blob.body) {
          this.showMensagemInfo("Aviso", "Nenhum arquivo CTE encontrado no período selecionado.");
        } else {
          this.triggerDownload(blob);
        }
      },
      error: e => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private downloadPacotePdfNFSe(request: DownloadGeralRequest) {
    this.nfseService.downloadPacotePdfNFse(request).subscribe({
      next: blob => {
        if (!blob.body) {
          this.showMensagemInfo("Aviso", "Nenhum arquivo PDF encontrado no período selecionado.");
        } else {
          this.triggerDownload(blob);
        }
      },
      error: e => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  // Upload de XML's
  onUploadXml(event: any) {
    if (event.files[0]) {
      this.xmlUploadedFiles = event.files;
    }

    let request = new UploadRequest;

    request.idEmpresa = this.selectedEmpresa?.id;
    request.arquivos = this.xmlUploadedFiles!;
    request.codigoMun = this.selectedMunicipio?.codigo;
    request.clienteLogin = this.userLogged?.login;

    switch (this.selectedTipoDocImportacao!.key) {
      case 1:
        this.nfeUpload(request);
        break;
      case 2:
        this.cteUpload(request);
        break;
      case 3:
        if (this.selectedMunicipio) {
          this.nfseUpload(request);
        } else {
          this.showMensagemAlerta(" ", "Selecione um município");
        }
        break;
      case 4:
        this.cfeUpload(request);
        break;
      case 5:
        this.portalUpload(request);
        break;
      default:
        this.showMensagemAlerta(" ", "Selecione o tipo");
        break;
    }
  }

  private nfeUpload(request: UploadRequest) {
    this.nfeService.sendUploadedXml(request).subscribe({
      next: () => {
        this.showMensagemInfo("Requisição enviada", "Aguardar a notificação da conclusão do processamento.");
      },
      error: (e) => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private cteUpload(request: UploadRequest) {
    this.cteService.sendUploadedXml(request).subscribe({
      next: () => {
        this.showMensagemInfo("Requisição enviada", "Aguardar a notificação da conclusão do processamento.");
      },
      error: (e) => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private nfseUpload(request: UploadRequest) {
    this.nfseService.sendUploadedXml(request).subscribe({
      next: () => {
        this.showMensagemInfo("Requisição enviada", "Aguardar a notificação da conclusão do processamento.");
      },
      error: (e) => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private cfeUpload(request: UploadRequest) {
    this.cfeService.sendUploadedXml(request).subscribe({
      next: () => {
        this.showMensagemInfo("Requisição enviada", "Aguardar a notificação da conclusão do processamento.");
      },
      error: (e) => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private portalUpload(request: UploadRequest) {
    this.portalService.sendUploadedXml(request).subscribe({
      next: () => {
        this.showMensagemInfo("Requisição enviada", "Aguardar a notificação da conclusão do processamento.");
      },
      error: (e) => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private triggerDownload(blob: HttpResponse<Blob>) {
    let filename = blob.headers.get('Content-Disposition')?.split('filename=')[1].split(';')[0];
    filename = filename ? filename.trim() : `documentos_fiscais_${moment().format('YYYYMMDD')}.zip`;
    saveAs(blob.body!, filename);
  }

  private showMensagemAlerta(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'warn',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  private showMensagemInfo(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'info',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 5000
    });
  }

  private showMensagemErro(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  private showMensagemSuccess(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'success',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 5000
    });
  }

  carregaCampoMunicipiosContratados() {
    this.municipiosContratados = [];
    if (this.selectedEmpresa && this.selectedTipoDocImportacao && this.selectedTipoDocImportacao.key === 3) {
      this.selectedEmpresa.modulos?.nfseModulo.nfseMunicipios?.forEach(dadosMun => {
        const mun = this.todosMunicipios.find(mun => mun.codigo == dadosMun.codigoMun);
        if (mun) {
          this.municipiosContratados.push(mun);
        }
      });
      setTimeout(() => {
        this.selectedMunicipio = this.municipiosContratados[0];
      });
    }
  }

}
