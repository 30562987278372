import { CFeStatus } from "./enums/cfe-status.enum";
import { PageRequest } from "./page-request.model";

export class CfeRequest extends PageRequest {

  chaveAcesso: string | undefined;
  chaveAcessoDocOriginal: string | undefined;
  cnpjEmitente: string | undefined;
  dataInicio: Date | undefined;
  dataFim: Date | undefined;
  dataEmissaoInicioStr: string | undefined;
  dataEmissaoFimStr: string | undefined;
  numNotaDe!: number | string;
  numNotaAte!: number | string;
  status: CFeStatus | undefined;
  numRecibo: string | undefined;
  numCF: Number | undefined;
  valorNcm: Number | undefined;
  prodQuantidade: Number | undefined;
  valorUnitario: Number | undefined;
  valorCfop: Number | undefined;
  valorIcms: Number | undefined;
  aliquotaIcms: Number | undefined;
  equipamento: String | undefined;
  dataEmissao: Date | undefined;
  descriProd: String | undefined;

}
