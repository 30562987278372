import { Component, OnInit } from '@angular/core';
import { TableLazyLoadEvent } from 'primeng/table';
import { Cliente } from 'src/model/cliente.model';
import { ClientesPagedRequest } from 'src/model/clientes-paged-request.model';
import { Page } from 'src/model/page.model';
import { ClienteService } from 'src/services/clientes.service';
import { MessageService } from 'primeng/api';
import { GlobalEventService } from 'src/shared/global-event.service.ts.service';
import { ClienteTipo } from 'src/model/enums/cliente-tipo.enum';

@Component({
  selector: 'app-cadastro-todos-clientes',
  templateUrl: './cadastro-todos-clientes.component.html',
  styleUrl: './cadastro-todos-clientes.component.scss'
})
export class CadastroTodosClientesComponent implements OnInit {

  loading: boolean = false;
  msgNenhumRegistro: string = '';
  timer: any;

  clienteRequest: ClientesPagedRequest = new ClientesPagedRequest();

  clientes: Page<Cliente> = new Page<Cliente>();
  selectedCliente: Cliente | undefined;
  pageSizes = [10, 15, 20, 50, 100];

  filtroLoginNome: string | undefined;
  editandoCliente = true;
  showModalCadastroCliente = false;

  constructor(
    private clienteService: ClienteService,
    private messageService: MessageService,
    private globalEventService: GlobalEventService
  ) { }

  ngOnInit(): void {
    this.clienteRequest.clienteTipo = ClienteTipo.SUPORTE;
    this.loadClientes();
  }

  private loadClientes() {
    this.showModalLoading();
    this.clienteService.getClientesByFilters(this.clienteRequest).subscribe({
      next: (data: Page<Cliente>) => {
        if (data.content.length == 0) {
          this.msgNenhumRegistro = "Nenhum registro encontrado."
        } else {
          // Ordena os clientes pelo campo 'nome'
          data.content = data.content.sort((a: Cliente, b: Cliente) => {
            // Compara os nomes, convertendo para minúsculas para garantir que a comparação não seja sensível a caixa
            return a.nome.toLowerCase().localeCompare(b.nome.toLowerCase());
          });
          this.clientes = data;
        }
        this.clientes = data;
        this.hideModalLoading();
      },
      error: (e: { name: string; message: string; }) => {
        this.msgNenhumRegistro = "Erro ao carregar dados de clientes."
        this.showMensagemErro(e.name, e.message);
        console.error(e);
      }
    }).add(() => {
      this.hideModalLoading();
    });
  }

  onLazyLoad(event: TableLazyLoadEvent) {
    if (this.clientes.totalElements > 0) {
      this.clienteRequest.pageNum = event.first! / event.rows!;
      if (event.rows) {
        this.clienteRequest.pageSize = event.rows;
      }
      this.loadClientes();
    }
  }

  procuraComDelay() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      const filtro = this.filtroLoginNome;
      this.clienteRequest.login = filtro;
      this.clienteRequest.nome = filtro;
      this.loadClientes();
    }, 500);
  }

  editaCliente(cliente: Cliente) {
    this.selectedCliente = cliente;
    this.showModalCadastroCliente = true;
  }

  cadastrarNovoCliente() {
    this.editandoCliente = false;
    this.selectedCliente = new Cliente();
    this.showModalCadastroCliente = true;
  }

  onSalvarCliente(cliente: Cliente) {
    if (cliente && this.editandoCliente) {
      const index = this.clientes.content.findIndex(c => c.login === cliente.login);
      if (index !== -1) {
        this.clientes.content[index] = cliente;
      }
    } else {
      this.refresh();
    }
    this.showModalCadastroCliente = false;
  }

  onCancelarSalvarCliente() {
    this.showModalCadastroCliente = false;
  }

  refresh() {
    this.loadClientes();
  }

  showModalLoading() {
    this.globalEventService.modalProcessandoEvent.emit(true);
    this.loading = true;
  }

  hideModalLoading() {
    this.loading = false;
    this.globalEventService.modalProcessandoEvent.emit(false);
  }

  private showMensagemSucesso(summary: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'success',
      summary: '' + summary,
      detail: '' + detalhe
    });
  }

  private showMensagemErro(summary: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'error',
      summary: '' + summary,
      detail: '' + detalhe
    });
  }
}
