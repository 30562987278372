export class MonitorEmissaoNfe {

  id: string;
  idIntegracao: string;
  statusEmissao: StatusEmissao;
  dataEmissao: Date;
  dataAutoriazacao: Date;
  mensagem: string;
  protocolo: string;
  chaveAcesso: string;
  cpfCnpjEmissor: string;
  nomeEmissor: string;
  cpfCnpjDestinatario: string;
  nomeDestinatario: string;
  valor: number;
  cStat: number;
  criadoEm: Date;
  atualizadoEm: Date;

  constructor(
    id: string,
    idIntegracao: string,
    statusEmissao: StatusEmissao,
    dataEmissao: Date,
    dataAutoriazacao: Date,
    mensagem: string,
    protocolo: string,
    chaveAcesso: string,
    cpfCnpjEmissor: string,
    nomeEmissor: string,
    cpfCnpjDestinatario: string,
    nomeDestinatario: string,
    valor: number,
    cStat: number,
    criadoEm: Date,
    atualizadoEm: Date
  ) {
    this.id = id;
    this.idIntegracao = idIntegracao;
    this.statusEmissao = statusEmissao;
    this.dataEmissao = dataEmissao;
    this.dataAutoriazacao = dataAutoriazacao;
    this.mensagem = mensagem;
    this.protocolo = protocolo;
    this.chaveAcesso = chaveAcesso;
    this.cpfCnpjEmissor = cpfCnpjEmissor;
    this.nomeEmissor = nomeEmissor;
    this.cpfCnpjDestinatario = cpfCnpjDestinatario;
    this.nomeDestinatario = nomeDestinatario;
    this.valor = valor;
    this.cStat = cStat;
    this.criadoEm = criadoEm;
    this.atualizadoEm = atualizadoEm;
  }
}

export enum StatusEmissao {
  RECEBIDA = 1,
  EM_PROCESSAMENTO = 2,
  CONCLUIDO = 3,
  ERRO = 99
}
