import { NFSeTipo } from './enums/nfse-tipo.enum';
import { NFeOrigem } from './enums/nf-origem.enum';
import { PageRequest } from './page-request.model';

export class NFSePagedRequest extends PageRequest {

  context: NFSeTipo | undefined;
  codigoMun!: Number | undefined;
  cnpjCpfTomador?: String;
  cnpjCpfPrestador?: String;
  dataInicio?: Date;
  dataEmissao?: Date;
  dataFim?: Date;
  numNotaDe!: number | string;
  numNotaAte!: number | string;
  nomeTomador!: String;
  nomePrestador!: String;
  numero!: Number | String;
  codigoServico!: Number | String;
  descricao!: String;
  valor!: Number | String;
  valorISS!: Number | String;
  valorPIS!: Number | String;
  valorCOFINS!: Number | String;
  valorCSLL!: Number | String;
  valorIRPF!: Number | String;
  valorINSS!: Number | String;
  municipio!: String
  nfSeOrigem?: NFeOrigem | String;
}
