import { ClienteTipo } from './enums/cliente-tipo.enum';
import { PageRequest } from './page-request.model';

export class ClientesPagedRequest extends PageRequest {

  login?: string;
  clienteTipo?: ClienteTipo;
  suporteLogin?: string;
  nome?: string;
  ativo: Boolean | undefined;
  dataRegistro?: Date;
  email?: string;
}
