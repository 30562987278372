<div class="container">
  <div class="container-width">
    <!-- TITULO -->
    <div class="d-flex flex-row align-items-baseline" style="margin-top: -10px">
      <div class="flex-grow-1">
        <div>
          <h4 style="line-height: 2">
            Visão geral<span style="color: gray"> - {{userLogged.clienteTipo === clienteTipo.SUPORTE ? userLogged.nome : userLogged.suporteLoginNome}}</span>
          </h4>
        </div>
      </div>
    </div>
    <div class="mt-2">
        <p-panelMenu id="panelMenu"
            [model]="panelMenuEmpresas"
            data-p-highlight="true"
            aria-expanded="true"
            [ngStyle]="{ 'pointer-events': conteudosCarregaram ? 'auto' : 'none' }">
            <ng-template pTemplate="item" let-item>
            <div
                (click)="toggleExpand(item.id)"
                class="d-flex align-items-center justify-content-between"
                *ngIf="item.label"
                style="border-radius: 5px; height: 50px; padding-top: 3px;"
                [ngStyle]="{ border: item.borderStyle }">
                <div class="panelMenuContent">
                    <i [class]="item.icon"></i>
                    <span [innerHTML]="item.label"></span>
                    <p-badge *ngIf="item.quantidade !== undefined" [value]="item.quantidade" />
                </div>
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem; margin-right: 30px;" *ngIf="conteudosCarregaram === false; else chevronIcon"></i>
                <ng-template #chevronIcon>
                    <i
                        class="pi"
                        [ngClass]="expandedItems[item.id] ? 'pi-angle-down' : 'pi-angle-right'"
                        style="font-size: 2rem; margin-right: 30px; color: var(--cor-principal);"
                        *ngIf="item.items && item.items.length > 0">
                    </i>
                </ng-template>
            </div>
            </ng-template>
        </p-panelMenu>
    </div>
    <div>
      <div>
        <p-table
          [defaultSortOrder]="-1"
          [value]="dashboardContent"
          styleClass="p-datatable-gridlines">
          <ng-template pTemplate="header">
            <tr>
              <th>Tipo</th>
              <th pSortableColumn="quantidadeDocs">
                Volumetria XMLs (Qtde)<p-sortIcon
                  field="quantidadeDocs"
                ></p-sortIcon>
              </th>
              <th pSortableColumn="valorTotal">
                Valor Total (R$)<p-sortIcon field="valorTotal"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-dashboardContent>
            <p-progressSpinner
              *ngIf="!dashboardContent.conteudoCarregou"
              animationDuration=".5s"
            />
            <tr
              [ngClass]="{ 'loading-row': !dashboardContent.conteudoCarregou }">
              <td>
                <span
                  class="dashboard-tag"
                  [ngStyle]="{
                    color: escurecerCor(dashboardContent.cor, 0.5),
                    'background-color': dashboardContent.cor
                  }">
                  <b>{{ dashboardContent.tipoDoc }}</b>
                </span>
              </td>
              <td>{{ dashboardContent.quantidadeDocs }}</td>
              <td>{{ dashboardContent.valorTotal | currency : "BRL" }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <div class="card mt-4">
        <p-chart
            width="500"
            height="500"
            type="bar"
            [data]="chartData"
            [options]="chartOptions">
        </p-chart>
    </div>
  </div>
</div>
