import { MessageService } from 'primeng/api';
import { DadosLogin } from '../../../model/dados-login.model';
import { Cliente } from '../../../model/cliente.model';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('form') form: NgForm | undefined;

  dadosLogin: DadosLogin = new DadosLogin();
  password: string | undefined;
  newPassword: string | undefined;
  confirmaNovaSenha: string | undefined;
  showChangePassworForm: boolean = false;
  mensagemErroForm: string = "";
  loading = false;

  doc = document;

  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
  }

  async entrar() {
    if (!this.showChangePassworForm) {
      this.dadosLogin.password = this.password;
      this.autenticar();
    } else {
      $("#txtNovaSenha .p-password-input").prop("disabled", "");
      $("#txtConfirmaNovaSenha .p-password-input").prop("disabled", "");
      if (this.validarCamposParaTrocaSenha()) {
        let dadosLoginClone = Object.assign({}, this.dadosLogin);
        dadosLoginClone.newPassword = this.newPassword;
        this.authService.alteraSenha(dadosLoginClone).subscribe({
          next: (alteradaSenha) => {
            if (alteradaSenha) {
              this.dadosLogin.password = "";
              this.loading = true;
              $("#txtNovaSenha .p-password-input").prop("disabled", "disabled");
              $("#txtConfirmaNovaSenha .p-password-input").prop("disabled", "disabled");
              setTimeout(() => {
                this.showChangePassworForm = false;
                this.password = "";
                setTimeout(() => {
                  $("#txtLogin").prop("disabled", "");
                  $("#txtSenha .p-password-input").prop("disabled", "");
                  $("#txtSenha .p-password-input").trigger("focus");
                  this.loading = false;
                }, 300);
              }, 200);
            } else {
              this.mensagemErroForm = "Erro ao alterar senha. Favor tentar novamente."
            }
          },
          error: (e) => {
            this.mensagemErroForm = e.error.message;
          }
        });
      }
    }
  }

  private async autenticar() {
    if (this.dadosLogin.login && this.dadosLogin.password) {
      this.mensagemErroForm = "";
      this.loading = true;
      this.authService.autenticar(this.dadosLogin).subscribe({
        next: (user) => {
          if (user.token == null) {
            this.mensagemErroForm = (String(user.message));
          } else {
            this.authService.gravaLocalStorageDadosAutenticacao(user);
            var usuario: Cliente | null = this.authService.getUsuario();
            if (usuario?.mudarSenha) {
              this.showChangePassworForm = true;
              $("#txtLogin").prop("disabled", "disabled");
              $("#txtSenha .p-password-input").val("");
              $("#txtSenha .p-password-input").prop("disabled", "disabled");
              setTimeout(() => {
                $("#txtNovaSenha input").trigger("focus");
              }, 5);
            } else {
              this.router.navigate(['']);
            }
          }
          this.loading = false;
        }, error: (error) => {
          this.mensagemErroForm = "Ocorreu um erro ao acessar serviço de login.";
          this.loading = false;
        }
      });
    } else {
      this.mensagemErroForm = "Os campos login e senha devem estar preenchidos.";
      if (!this.dadosLogin.login || (this.dadosLogin.login && this.dadosLogin.password)) {
        $("#txtLogin").trigger("focus");
      } else {
        $("#txtSenha .p-password-input").trigger("focus");
      }
    }
  }

  verificarNovaSenha() {
    const senha = this.newPassword!;

    // Regras de validação
    const temMinuscula = /[a-z]/.test(senha);
    const temMaiuscula = /[A-Z]/.test(senha);
    const temNumero = /\d/.test(senha);
    const temEspecial = /[!@#$%^&*(),.?":{}|<>]/.test(senha);
    const tamanhoValido = senha.length >= 8;

    // Atualiza a lista de requisitos
    this.atualizarRequisito('minusc', temMinuscula);
    this.atualizarRequisito('maiusc', temMaiuscula);
    this.atualizarRequisito('numerico', temNumero);
    this.atualizarRequisito('especial', temEspecial);
    this.atualizarRequisito('tamanho', tamanhoValido);
  }

  private atualizarRequisito(id: string, valido: boolean) {
    const item = document.getElementById(id);
    let icone = document.getElementById('icone' + id.charAt(0).toUpperCase() + id.slice(1));

    if (valido && item && icone) {
      item.classList.remove('inativo');
      item.classList.add('ativo');
      icone.innerHTML = '<i class="pi pi-check"></i>';

    } else {
      item?.classList.remove('ativo');
      item?.classList.add('inativo');
      icone!.innerHTML = '<i class="pi pi-times"></i>';
    }
  }

  todosRequesitosAtendidos(): boolean {
    if (!this.showChangePassworForm) {
      return true;
    }
    const requisitos = ['minusc', 'maiusc', 'numerico', 'especial', 'tamanho'];
    const todosAtendidos = requisitos.every(id => {
      if (document.getElementById(id)) {
        return document.getElementById(id)!.classList.contains('ativo');
      }
      return false;
    });
    return todosAtendidos;
  }

  private validarCamposParaTrocaSenha(): boolean {
    var confirmaNovaSenha = this.confirmaNovaSenha;
    if (!this.newPassword || !confirmaNovaSenha) {
      this.mensagemErroForm = "Campos devem estar preenchidos.";
      return false;
    }
    if (this.newPassword == this.dadosLogin.password) {
      this.mensagemErroForm = "A nova senha deve ser diferente da última usada.";
      return false;
    }
    if (this.newPassword != confirmaNovaSenha) {
      this.mensagemErroForm = "As senhas não coincidem";
      return false;
    }
    this.mensagemErroForm = "";
    return true;
  }

  loginOutroUsuario() {
    $("#txtLogin").prop("disabled", "");
    $("#txtSenha .p-password-input").prop("disabled", "");
    $("#txtSenha .p-password-input").val("");
    $("#txtLogin").trigger("focus");
    this.mensagemErroForm = "";
    this.dadosLogin = new DadosLogin();
    this.showChangePassworForm = false;
  }

  esqueceuASenha() {
    if (!this.dadosLogin.login) {
      this.mensagemErroForm = "Digite o login cadastrado para recuperação da senha.";
    } else {
      this.mensagemErroForm = "";
      this.dadosLogin.urlBase = window.location.protocol + "//" + window.location.host + "/#";
      this.loading = true;
      this.authService.esqueceuASenha(this.dadosLogin).subscribe({
        next: (data) => {
          if (data.emailSuccessfullySent) {
            this.showMensagemSucesso("Esqueci minha senha", "Link para redefinição de senha enviado para o e-mail cadastrado.");
          } else {
            this.showMensagemErro("Esqueci minha senha", data.result);
          }
        },
        error: (e) => {
          this.showMensagemErro(e.name, e.message);
        }
      }).add(() => {
        this.loading = false;
      });
    }
  }

  private showMensagemSucesso(mensagem: string, detalhe: string) {
    this.messageService.add({
      severity: 'success',
      summary: '' + mensagem,
      detail: '' + detalhe
    });
  }

  private showMensagemErro(mensagem: string, detalhe: string) {
    this.messageService.add({
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe
    });
  }
}
