import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { CfeRequest } from 'src/model/cfe-request.model';
import { CFe } from 'src/model/cfe.model';
import { Page } from 'src/model/page.model';
import { UploadRequest } from 'src/model/upload-request';
import { DownloadNotasGeralRequest } from 'src/model/download-notas-geral.request';

const ENDPOINT_CONSULTA_CFE_BAIXADAS = environment.API_MAIN + "/cfe/consulta";
const ENDPOINT_UPLOAD_CFE = environment.API_MAIN + "/cfe/upload-xml";
const ENDPOINT_DOWNLOAD_CFE = environment.API_MAIN + "/cfe/download";
const ENDPOINT_DOWNLOAD_LOTE_CFE = environment.API_MAIN + "/cfe/download-lote";
const ENDPOINT_GET_PDF_CFE = environment.API_MAIN + "/cfe/danfe/pdf/";
const ENDPOINT_DOWNLOAD_PACOTE_CFE = environment.API_MAIN + "/cfe/pacote-cfe";
const ENDPOINT_CONSULTA_CFE_RELATORIO = environment.API_MAIN + "/cfe/relatorio";

@Injectable({
  providedIn: 'root'
})
export class CfeService {

  constructor(private http: HttpClient) {}

  /*
    Obtem o status da solicitação de consulta NSU de interesse
  */
  //public consultaSefaz(request: CfeConsultaWsRequest): Observable<  > {
  //}

  /*
    Obtem a lista de CFe consultadas
  */
  public consultaCFe(request: CfeRequest): Observable<Page<CFe>> {
    let params: URLSearchParams = this.getParams(request);
    const url = `${ENDPOINT_CONSULTA_CFE_BAIXADAS}?${params.toString()}`;
    return this.http.get<Page<CFe>>(url);
  }

  /*
    Obtem a lista de CFe para geração da planilha do relatório
  */
  public consultaCFeRelatorio(request: CfeRequest): Observable<Page<CfeRequest>> {
    let params: URLSearchParams = this.getParams(request);
    const url = `${ENDPOINT_CONSULTA_CFE_RELATORIO}?${params.toString()}`;
    return this.http.get<Page<CfeRequest>>(url);
  }

  /*
    Download da NFe contida no banco de dados
  */
  public downloadCFe(id: String): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.post<HttpResponse<Blob>>(ENDPOINT_DOWNLOAD_CFE, id, options);
  }

  /*
    Download de CFe por data
  */
  public downloadPacoteCupomFiscal(request: DownloadNotasGeralRequest): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.post<HttpResponse<Blob>>(ENDPOINT_DOWNLOAD_PACOTE_CFE, request, options);
  }

  /*
    Download da NFe selecionadas contida no banco de dados
  */
  public downloadCFeLote(ids: String[]): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.post<HttpResponse<Blob>>(ENDPOINT_DOWNLOAD_LOTE_CFE, ids, options);
  }

  /*
    Faz o envio de uma lista de arquivos para importação de notas
  */
  public sendUploadedXml(request: UploadRequest): Observable<void> {
    const formData = new FormData();
    if (request.arquivos?.length == 0 || request.idEmpresa == undefined || request.clienteLogin == undefined) {
      throw Error("Todos os campos da request devem ser preenchidos")
    } else {
      formData.append('idEmpresa', request.idEmpresa);
      formData.append('clienteLogin', request.clienteLogin);
      for (let i = 0; i < request.arquivos.length; i++) {
        formData.append('arquivos', request.arquivos[i]);
      }
    }
    return this.http.post<void>(ENDPOINT_UPLOAD_CFE, formData);
  }

  /*
    Obtem a nota pela chave de acesso
  */
  public getDanfePdf(chaveAcesso: String): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.get<HttpResponse<Blob>>(ENDPOINT_GET_PDF_CFE + chaveAcesso, options);
  }

  public generateUrlGetDanfePdf(chaveAcesso: String): string {
    return ENDPOINT_GET_PDF_CFE + chaveAcesso + "#zoom=100&view=FitH";
  }

  getParams(request: any): URLSearchParams {
    let params = new URLSearchParams();
    for (var propName in request) {
      if (!(propName == "dataInicio" || propName == "dataFim")) {
        if (request[propName]) {
          params.set(propName, request[propName]);
        }
      }
    }
    return params;
  }
}
