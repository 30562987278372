import { AuthService } from '../../services/auth.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { TabView } from 'primeng/tabview';
import { MessageService } from 'primeng/api';
import { Cliente } from 'src/model/cliente.model';
import { ClienteService } from 'src/services/clientes.service';
import { ClienteTipo } from 'src/model/enums/cliente-tipo.enum';

@Component({
  selector: 'app-form-cadastro-cliente',
  templateUrl: './form-cadastro-cliente.component.html',
  styleUrls: ['./form-cadastro-cliente.component.scss']
})
export class FormCadastroClienteComponent implements OnInit, OnChanges {

  @ViewChild('tabView') tabView!: TabView;

  @Input()
  get cliente(): Cliente {
    return this._cliente
  }
  set cliente(cliente: Cliente | undefined) {
    this.loading = true;
    this.novoCliente = false;
    if (cliente == undefined || cliente.login == undefined) {
      if (cliente && cliente.clienteTipo == ClienteTipo.CLIENTE) {
        this._cliente = this.clone(cliente);
      } else {
        this._cliente = new Cliente();
        this._cliente.ativo = true;
        this._cliente.clienteTipo = ClienteTipo.SUPORTE;
        this._cliente.controleDownloadXML = {};
        this.desabilitaCampoLogin = false;
      }
      this.novoCliente = true;
    } else {
      this._cliente = this.clone(cliente);
      this.desabilitaCampoLogin = true;
      this.loginValidado = true;
    }
    this.exibeCampoAtivo = this.novoCliente || this.isLoggedAdmin;
    this.desabilitaCampoLogin = !(this._cliente.login == undefined || this._cliente.login == null);
    this._clienteAux = this.clone(this._cliente);
    this.selectedTab = 0;
    this.loading = false;
  }
  @Input() showBotaoCancelar = true;
  @Output() onCancelar = new EventEmitter();
  @Output() onSalvarCliente = new EventEmitter<Cliente>();
  @Input() somenteVisualizar = false;

  loading = false;

  isLoggedAdmin = false;

  _cliente: Cliente = new Cliente();
  _clienteAux: Cliente = new Cliente();
  novoCliente = false;
  selectedCliente?: Cliente;
  cnpjNovo: Boolean = true;
  loginErroMensagem: string = '';
  loginValidado = false;
  validandoLogin = false;
  emailErroMensagem: string = '';

  subscriptionClienteChange: any;

  exibeCampoAtivo: boolean = false;
  desabilitaCampoLogin = false;
  ClienteTipo = ClienteTipo

  selectedTab: number = 0;

  constructor(
    private authService: AuthService,
    private clienteService: ClienteService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.isLoggedAdmin = this.authService.getUsuario()?.login.toUpperCase() === "ADMIN";
  }

  ngOnChanges(_changes: SimpleChanges) {
    setTimeout(() => this.limparErros());
  }

  onBlurLogin(event: any) {
    const loginValue = event.target.value;
    const pattern = /^[a-zA-Z0-9.]*$/;
    const somentePontos = /^[.]*$/;

    this.loginErroMensagem = '';
    if (loginValue === '') {
      this.loginErroMensagem = 'Login é obrigatório';
    } else if (!pattern.test(loginValue)) {
      this.loginErroMensagem = 'Somente letras, números e pontos';
    } else if (somentePontos.test(loginValue)) {
      this.loginErroMensagem = 'Não pode conter somente pontos.';
    } else if (loginValue.startsWith('.') || loginValue.endsWith('.')) {
      this.loginErroMensagem = 'Não pode começar ou terminar com pontos';
    }

    // Removendo acentuação
    const loginValueWithoutAccents = loginValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // Se o loginValue original for diferente do loginValue sem acentos, então tem acentuação
    if (loginValue !== loginValueWithoutAccents) {
      this.loginErroMensagem = 'O login não pode conter acentuação.';
    }

    if (this.loginErroMensagem) {
      $('#campoLogin').addClass('ng-invalid');
      $('#campoLogin-erro').show();
    } else {
      this.validaLogin(loginValue);
    }
  }

  private validaLogin(login: string) {
    this.validandoLogin = true;
    this.clienteService.verificaLoginExistente(login).subscribe({
      next: (data: boolean) => {
        this.validandoLogin = false;
        if (data) {
          this.loginErroMensagem = 'Login já existente.';
          $('#campoLogin').addClass('ng-invalid');
          $('#campoLogin-erro').show();
          this.loginValidado = false;
        } else {
          $('#campoLogin').removeClass('ng-invalid');
          $('#campoLogin-erro').hide();
          this.loginValidado = true;
        }
      },
      error: (e: { name: string; message: string; }) => {
        this.showMensagemErro(e.name, "Erro ao validar login do cliente.");
        console.error(e);
        this.validandoLogin = false;
      }
    });
  }

  onBlurNome(event: any) {
    var value: String = event.target.value;
    value = String(value).trim();
    if (this.hasValue(value)) {
      $('#campoNome').removeClass('ng-invalid');
      $('#campoNome-erro').hide();
    } else {
      $('#campoNome').addClass('ng-invalid');
      $('#campoNome-erro').show();
    }
  }

  onBlurEmail(event: any) {
    var value: String = event.target.value;
    if (value) {
      if (this.validaEmail(value)) {
        $('#campoEmail').removeClass('ng-invalid');
        $('#campoEmail-erro').hide();
        this._cliente.email = value.trim();
      } else {
        $('#campoEmail').addClass('ng-invalid');
        $('#campoEmail-erro').show();
      }
    }
  }

  private validaEmail(value: String): boolean {
    const pattern = /.+@.+\..+/;
    if (value) {
      return (value.length >= 5 && value.length <= 250 && pattern.test(value.toString()));
    } else {
      return false;
    }
  }

  clickSalvar() {
    const tipo = this._cliente.clienteTipo == ClienteTipo.SUPORTE ? "Cliente": "Usuário";
    if (this.novoCliente) {
      this.clienteService.criaCliente(this._cliente).subscribe({
        next: (response) => {
          if (response.successfully) {
            this.showMensagemSucesso("", "Novo " + tipo.toLowerCase() + " cadastrado com sucesso.");
            this.onSalvarCliente.emit(this._cliente);
          } else {
            this.showMensagemErro("Erro ao salvar", response.result);
            this.onSalvarCliente.emit(undefined);
          }
        },
        error: (e) => {
          console.error(e);
        }
      });
    } else {
      this.clienteService.atualizaCliente(this._cliente).subscribe({
        next: (response) => {
          if (response.successfully) {
            this.showMensagemSucesso("", tipo + " atualizado com sucesso.");
            this.onSalvarCliente.emit(this._cliente);
          } else {
            this.showMensagemErro("Erro ao atualizar", response.result);
            this.onSalvarCliente.emit(undefined);
          }
        },
        error: (e) => {
          console.error(e);
        }
      });
    }
  }

  clickCancelar() {
    this.onCancelar.emit();
  }

  activeButtonSalvar() {
    return !(
      (this.hasValue(this._cliente.login) || this.desabilitaCampoLogin || this.loginValidado) &&
      (this.hasValue(this._cliente.nome)) &&
      (this.validaEmail(this._cliente.email)) &&
      (this.hasValue(this._cliente.controleDownloadXML?.limite) || this._cliente.clienteTipo != ClienteTipo.SUPORTE) &&
      !(this.compareObjects(this._cliente, this._clienteAux))
    );
  }

  private hasValue(value: any): boolean {
    return (value != null && String(value).trim() != '');
  }

  private showMensagemSucesso(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'success',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 5000
    });
  }

  private limparErros() {
    $('#campoLogin-erro').hide();
    $('#campoLogin').removeClass('ng-invalid');

    $('#campoNome-erro').hide();
    $('#campoNome').removeClass('ng-invalid');

    $('#campoEmail-erro').hide();
    $('#campoEmail').removeClass('ng-invalid');
  }

  private clone(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  private compareObjects(obj1: Object, obj2: Object) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  private showMensagemErro(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  private showMensagemAlerta(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'warn',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }
}
