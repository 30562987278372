<div class="me-4 mt-3" *ngIf="loadPage">
  <p-tabView
    #tabView
    [styleClass]="'width: 600px'"
    [(activeIndex)]="selectedTab"
    header="dados_empresa"
    (onChange)="onTabChange($event)">
    <!-- TabView - Dados da _empresa -->
    <p-tabPanel>
      <ng-template pTemplate="header">
        Dados da empresa
      </ng-template>
      <div class="m-4" [ngStyle]="{'display': !loadPage ? 'none' : 'block'}">
        <div class="d-flex flex-row">
          <div>

            <div [ngStyle]="{'display': !exibeCampoAtiva ? 'none' : 'block'}" class="field-checkbox mb-4 ml-n2">
              <p-checkbox [(ngModel)]="_empresa.ativa" [binary]="true" inputId="ativa"></p-checkbox>
              <label for="ativa" class="ms-3">Ativa</label>
            </div>

            <div class="d-flex flex-row align-items-center">
              <div class="field" style="width: 155px;">
                <label class="d-flex justify-content-between">
                  <div>CNPJ</div>
                  <div><small id="campoCnpj-erro" class="p-error block">{{cnpjErroMensagem}}</small></div>
                </label>
                <input pInputText
                  id="campoCnpj"
                  type="text"
                  (blur)="onBlurCnpj($event)"
                  [(ngModel)]="_empresa.id"
                  [ngModelOptions]="{standalone: true}"
                  mask="00.000.000/0000-00"
                  [disabled]="!empresaNova || somenteVisualizar">
              </div>
            </div>

            <div>
              <div class="field" style="width: 500px;">
                <label class="block">Nome</label>
                <input pInputText
                  maxlength="70"
                  [(ngModel)]="_empresa.nome"
                  [ngModelOptions]="{standalone: true}"
                  [disabled]="somenteVisualizar">
              </div>
            </div>

            <div class="field" style="width: 300px; z-index: 1;">
              <label class="block">UF</label>
              <p-dropdown
                [options]="estados"
                [autoDisplayFirst]="false"
                [(ngModel)]="_empresa.codigoUf"
                [ngModelOptions]="{standalone: true}"
                optionLabel="name"
                optionValue="code"
                appendTo="body"
                [style]="{'width' : '75px'}"
                [disabled]="somenteVisualizar">
                <ng-template let-item pTemplate="item">
                  <div [ngStyle]="{'color': item?.name.startsWith('*') ? 'red': 'black'}">
                    {{item?.name.startsWith('*') ? item.name.substring(1, item.name.length) : item.name}}
                  </div>
                </ng-template>
              </p-dropdown>
            </div>

            <div *ngIf="_empresa.dataRegistro" class="mt-5">
              <b>Data do cadastro: &nbsp;</b>
              <span>{{_empresa.dataRegistro | date : "  dd/MM/yyyy"}}</span>
              <span style="font-size: 12px; color: gray;">{{_empresa.dataRegistro | date : "  HH:mm"}}</span>
            </div>

          </div>
        </div>
      </div>
    </p-tabPanel>

    <!-- TabView - Modulos -->
    <p-tabPanel header="modulos">
      <ng-template pTemplate="header">
        Modulos
      </ng-template>
      <div class="m-4 pt-2">
        <span>Habilita ou desabilita o acesso aos módulos <b>NF-e</b>, <b>CT-e</b>, <b>CF-e</b>, <b>NFS-e</b> e <b>NFS-e Nacional</b></span>
        <div class="mt-5">
          <table class="tabela-modulos">
            <tr>
              <th style="width: 130px;"></th>
              <th style="width: 70px;">Captura</th>
              <th style="width: 70px;">Emissão</th>
            </tr>
            <tr>
              <td>NF-e</td>
              <td>
                <p-inputSwitch
                  [(ngModel)]="_empresa.modulos.nfeModulo.capturaEnable"
                  [disabled]="somenteVisualizar"
                />
              </td>
              <td>
                <p-inputSwitch
                  [(ngModel)]="_empresa.modulos.nfeModulo.emissaoEnable"
                  [disabled]="somenteVisualizar"
                />
              </td>
            </tr>
            <tr>
              <td>CT-e</td>
              <td>
                <p-inputSwitch
                  [(ngModel)]="_empresa.modulos.cteModulo.cteEnable"
                  [disabled]="somenteVisualizar"
                />
              </td>
              <td>
              </td>
            </tr>
            <tr>
              <td>CF-e</td>
              <td>
                <p-inputSwitch
                  [(ngModel)]="_empresa.modulos.cfeModulo.cfeEnable"
                  [disabled]="somenteVisualizar"
                />
              </td>
              <td>
              </td>
            </tr>
            <tr>
              <td>NFC-e</td>
              <td>
                <p-inputSwitch
                  [(ngModel)]="_empresa.modulos.nfceModulo.capturaEnable"
                  [disabled]="somenteVisualizar"
                />
              </td>
              <td>
              </td>
            </tr>
            <tr>
              <td>NFS-e</td>
              <td>
                <p-inputSwitch
                  [(ngModel)]="_empresa.modulos.nfseModulo.nfseEnable"
                  [disabled]="somenteVisualizar"
                />
              </td>
              <td>
              </td>
            </tr>
            <tr>
              <td>NFS-e Nacional</td>
              <td>
                <p-inputSwitch
                  [(ngModel)]="_empresa.modulos.portalNacionalModulo.portalNacionalEnable"
                  [disabled]="somenteVisualizar"
                />
              </td>
              <td>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </p-tabPanel>

    <!-- TabView - Certificado Digital -->
    <p-tabPanel header="certificado" *ngIf="cnpjValido! && _empresa.id">
      <ng-template pTemplate="header">
        Certificado digital
      </ng-template>
      <div class="m-4">
        <div class="coluna-3">
          <!-- Certificado da _empresa ou do Contador -->
          <div style="width: 500px;">
            <app-form-certificado-digital
              [cnpj]="_empresa.id"
              [(certificado)]="_empresa.certificado!"
              label="Certificado A1"
              [somenteVisualizar]="somenteVisualizar">
            </app-form-certificado-digital>
          </div>
        </div>
      </div>
    </p-tabPanel>

    <!-- TabView - Dados para emissão de NF -->
    <p-tabPanel header="dadosEmissaoNFe" *ngIf="_empresa.modulos.nfeModulo.emissaoEnable">
      <ng-template pTemplate="header">
        <div *ngIf="erroCadastroNovaEmpresaPlugNotas" pTooltip="Preencha os campos obrigatórios" [showDelay]="500" tooltipPosition="top">
          <i class="pi pi-exclamation-triangle" style="color: var(--yellow-500); font-size: 1.2rem; font-weight: 500;"></i>
        </div>
        <span class="ms-3">
          Dados para emissão de NF-e
        </span>
      </ng-template>
      <div *ngIf="loadingPlugNotas" class="w-100 h-100 d-flex justify-content-center align-items-center">
        <p-progressSpinner
          styleClass="w-4rem h-4rem"
          strokeWidth="4"
          animationDuration=".5s" />
      </div>
      {{erroCadastroNovaEmpresaPlugNotasCampos | json}}
      <div *ngIf="!loadingPlugNotas" class="m-4">
        <div class="d-flex flex-row">
          <div class="d-flex flex-row align-items-center">
            <div class="field" style="width: 155px;">
              <label class="d-flex justify-content-between">
                <div>Simples Nacional</div>
              </label>
              <p-inputSwitch
                [(ngModel)]="empresaPlugNotas.simplesNacional"
                [disabled]="somenteVisualizar"
              />
            </div>
          </div>
          <div class="d-flex flex-row align-items-center">
            <div class="field" style="width: 155px;">
              <label class="d-flex justify-content-between">
                <div>Incentivo Fiscal</div>
              </label>
              <p-inputSwitch
                [(ngModel)]="empresaPlugNotas.incentivoFiscal"
                [disabled]="somenteVisualizar"
              />
            </div>
          </div>
          <div class="d-flex flex-row align-items-center">
            <div class="field" style="width: 155px;">
              <label class="d-flex justify-content-between">
                <div>Incentivo Cultural</div>
              </label>
              <p-inputSwitch
                [(ngModel)]="empresaPlugNotas.incentivadorCultural"
                [disabled]="somenteVisualizar"
              />
            </div>
          </div>
        </div>

        <div class="d-flex flex-row align-items-center">
          <div class="field" style="width: 500px;" [ngClass]="{'campo-erro': isCampoErro('razaoSocial')}">
            <label class="block">Razão Social</label>
            <input pInputText
              maxlength="100"
              [pTooltip]="getToolTipCampoErro('razaoSocial')"
              tooltipPosition="right"
              [(ngModel)]="empresaPlugNotas.razaoSocial"
              [ngModelOptions]="{standalone: true}"
              [disabled]="somenteVisualizar">
          </div>
        </div>

        <div class="d-flex flex-row align-items-center">
          <div class="field" style="width: 500px;" [ngClass]="{'campo-erro': isCampoErro('nomeFantasia')}">
            <label class="block">Nome Fantasia</label>
            <input pInputText
              maxlength="100"
              [pTooltip]="getToolTipCampoErro('nomeFantasia')"
              tooltipPosition="right"
              [(ngModel)]="empresaPlugNotas.nomeFantasia"
              [ngModelOptions]="{standalone: true}"
              [disabled]="somenteVisualizar">
          </div>
        </div>

        <div class="d-flex flex-row">
          <div class="d-flex flex-row align-items-center">
            <div class="field" style="width: 200px;" [ngClass]="{'campo-erro': isCampoErro('inscricaoMunicipal')}">
              <label class="block">Inscrição municipal</label>
              <input pInputText
                maxlength="20"
                [pTooltip]="getToolTipCampoErro('inscricaoMunicipal')"
              tooltipPosition="right"
                [(ngModel)]="empresaPlugNotas.inscricaoMunicipal"
                [ngModelOptions]="{standalone: true}"
                [disabled]="somenteVisualizar">
            </div>
          </div>
          <div class="d-flex flex-row align-items-center">
            <div class="field ms-4" style="width: 200px;" [ngClass]="{'campo-erro': isCampoErro('inscricaoEstadual')}">
              <label class="block">Inscrição estadual</label>
              <input pInputText
                maxlength="20"
                [pTooltip]="getToolTipCampoErro('inscricaoEstadual')"
                tooltipPosition="right"
                [(ngModel)]="empresaPlugNotas.inscricaoEstadual"
                [ngModelOptions]="{standalone: true}"
                [disabled]="somenteVisualizar">
            </div>
          </div>
        </div>

        <div class="d-flex flex-row">
          <div class="d-flex flex-row align-items-center">
            <div class="field" [ngClass]="{'campo-erro': isCampoErro('regimeTributario')}">
              <label class="block">Regime Tributário</label>
              <p-dropdown
                appendTo="body"
                [pTooltip]="getToolTipCampoErro('regimeTributario')"
                tooltipPosition="right"
                [options]="regimesTributarios"
                [(ngModel)]="empresaPlugNotas.regimeTributario"
                placeholder="Selecione um Regime Tributário"
                [style]="{'width' : '300px'}">
                <ng-template let-item pTemplate="item">
                  {{item?.value}} {{item?.value != null ? '-': '&nbsp;'}} {{item?.label}}
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center">
            <div class="field ms-4" [ngClass]="{'campo-erro': isCampoErro('regimeTributarioEspecial')}">
              <label class="block">Regime Tributário Especial</label>
              <p-dropdown
                appendTo="body"
                [pTooltip]="getToolTipCampoErro('regimeTributarioEspecial')"
                tooltipPosition="right"
                [options]="regimesTributariosEspecial"
                [(ngModel)]="empresaPlugNotas.regimeTributarioEspecial"
                placeholder="Selecione um Regime Tributário Especial"
                [style]="{'width' : '300px'}">
                <ng-template let-item pTemplate="item">
                  {{item?.value}} {{item?.value != null ? '-': '&nbsp;'}} {{item?.label}}
                </ng-template>
              </p-dropdown>
            </div>
          </div>
        </div>

        <div class="d-flex flex-row">
          <div class="d-flex flex-row align-items-center">
            <div class="field" style="width: 50px;" [ngClass]="{'campo-erro': isCampoErro('telefone.ddd')}">
              <label class="block">DDD</label>
              <input pInputText
                mask="99"
                maxlength="2"
                [pTooltip]="getToolTipCampoErro('telefone.ddd')"
                tooltipPosition="right"
                [(ngModel)]="empresaPlugNotas.telefone!.ddd"
                [ngModelOptions]="{standalone: true}"
                [disabled]="somenteVisualizar">
            </div>
          </div>
          <div class="d-flex flex-row align-items-center">
            <div class="field ms-4" style="width: 100px;" [ngClass]="{'campo-erro': isCampoErro('telefone.numero')}">
              <label class="block">Telefone</label>
              <input pInputText
                mask="999999999"
                maxlength="9"
                [pTooltip]="getToolTipCampoErro('telefone.numero')"
                tooltipPosition="right"
                [(ngModel)]="empresaPlugNotas.telefone!.numero"
                [ngModelOptions]="{standalone: true}"
                [disabled]="somenteVisualizar">
            </div>
          </div>
          <div class="d-flex flex-row align-items-center">
            <div class="field ms-5" style="width: 350px;" [ngClass]="{'campo-erro': isCampoErro('email')}">
              <label class="block">e-mail</label>
              <input pInputText
                maxlength="100"
                [pTooltip]="getToolTipCampoErro('email')"
                tooltipPosition="right"
                [(ngModel)]="empresaPlugNotas.email"
                [ngModelOptions]="{standalone: true}"
                [disabled]="somenteVisualizar">
            </div>
          </div>
        </div>
        <div>
          <h5 class="mt-3 mb-3"><b>Endereço</b></h5>
          <div class="d-flex flex-row">
            <div>
              <div class="field" style="width: 85px;" [ngClass]="{'campo-erro': isCampoErro('endereco.cep')}">
                <label class="block">CEP</label>
                <input pInputText
                  mask="99999-999"
                  maxlength="9"
                  [pTooltip]="getToolTipCampoErro('endereco.cep')"
                  tooltipPosition="right"
                  [(ngModel)]="empresaPlugNotas.endereco!.cep"
                  [ngModelOptions]="{standalone: true}"
                  [disabled]="somenteVisualizar">
              </div>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="field" style="width: 300px;" [ngClass]="{'campo-erro': isCampoErro('endereco.logradouro')}">
              <label class="block">Logradouro</label>
              <input pInputText
                maxlength="100"
                [pTooltip]="getToolTipCampoErro('endereco.logradouro')"
                tooltipPosition="right"
                [(ngModel)]="empresaPlugNotas.endereco!.logradouro"
                [ngModelOptions]="{standalone: true}"
                [disabled]="somenteVisualizar">
            </div>
            <div class="field ms-4" style="width: 100px;" [ngClass]="{'campo-erro': isCampoErro('endereco.numero')}">
              <label class="block">Número</label>
              <input pInputText
                maxlength="20"
                [pTooltip]="getToolTipCampoErro('endereco.numero')"
                tooltipPosition="right"
                [(ngModel)]="empresaPlugNotas.endereco!.numero"
                [ngModelOptions]="{standalone: true}"
                [disabled]="somenteVisualizar">
            </div>
            <div class="field ms-4" style="width: 200px;">
              <label class="block">Complemento</label>
              <input pInputText
                maxlength="50"
                [(ngModel)]="empresaPlugNotas.endereco!.complemento"
                [ngModelOptions]="{standalone: true}"
                [disabled]="somenteVisualizar">
            </div>
          </div>

          <div class="d-flex flex-row">
            <div class="field" style="width: 220px;" [ngClass]="{'campo-erro': isCampoErro('endereco.bairro')}">
              <label class="block">Bairro</label>
              <input pInputText
                maxlength="50"
                [pTooltip]="getToolTipCampoErro('endereco.bairro')"
                tooltipPosition="right"
                [(ngModel)]="empresaPlugNotas.endereco!.bairro"
                [ngModelOptions]="{standalone: true}"
                [disabled]="somenteVisualizar">
            </div>
          </div>

          <div class="d-flex flex-row">
            <div class="field" [ngClass]="{'campo-erro': isCampoErro('endereco.codigoCidade')}">
              <label class="block">Código da cidade</label>
              <input pInputText
                style="width: 100px;"
                mask="9999999"
                maxlength="7"
                [pTooltip]="getToolTipCampoErro('endereco.codigoCidade')"
                tooltipPosition="right"
                number
                [(ngModel)]="empresaPlugNotas.endereco!.codigoCidade"
                [ngModelOptions]="{standalone: true}"
                [disabled]="somenteVisualizar">
            </div>
            <div class="field ms-4" style="width: 250px;">
              <label class="block">Cidade</label>
              <input pInputText
                maxlength="50"
                [(ngModel)]="empresaPlugNotas.endereco!.descricaoCidade"
                [ngModelOptions]="{standalone: true}"
                [disabled]="somenteVisualizar">
            </div>
          </div>

          <div class="d-flex flex-row">
            <div>
              <div class="field" style="width: 200px; z-index: 1;" [ngClass]="{'campo-erro': isCampoErro('endereco.estado')}">
                <label class="block">UF</label>
                <p-dropdown
                  [options]="estados"
                  [pTooltip]="getToolTipCampoErro('endereco.estado')"
                  tooltipPosition="right"
                  [autoDisplayFirst]="false"
                  [(ngModel)]="empresaPlugNotas.endereco!.estado"
                  [ngModelOptions]="{standalone: true}"
                  optionLabel="name"
                  optionValue="name"
                  appendTo="body"
                  [style]="{'width' : '80px'}"
                  [disabled]="somenteVisualizar">
                  <ng-template let-item pTemplate="item">
                    <div [ngStyle]="{'color': item?.name.startsWith('*') ? 'red': 'black'}">
                      {{item?.name.startsWith('*') ? item.name.substring(1, item.name.length) : item.name}}
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>

    <!-- TabView - NFS-e -->
    <p-tabPanel header="nfse" *ngIf="_empresa.modulos.nfseModulo.nfseEnable">
      <ng-template pTemplate="header">
        <div *ngIf="possuiPendencias" pTooltip="Requer preenchimento de alguns dados" [showDelay]="500" tooltipPosition="top">
          <i class="pi pi-exclamation-triangle" style="color: var(--yellow-500); font-size: 1.2rem; font-weight: 500;"></i>
        </div>
        <span class="ms-3">
          NFS-e
        </span>
      </ng-template>

      <div class="m-4 mt-2">
        <div class="d-flex flex-row align-items-end">
          <div>
            <div class="mb-4">
              <label>Liberar notas retroativas a partir de:</label>
              <div class="d-flex align-items-center mt-2 mb-2">
                <p-calendar
                  aria-label="Date"
                  [(ngModel)]="_empresa.modulos.nfseModulo.dataRetroativaNFSe"
                  [showIcon]="true"
                  inputId="dataNfse"
                  (ngModelChange)="onDateChange()"
                  dateFormat="dd/mm/yy"
                  [style]="{'margin-left': '0'}"
                  [showClear]="true"
                  [disabled]="somenteVisualizar">
                </p-calendar>
              </div>
            </div>

            <label>
              Seleção de município
              <i style="font-size: 10px;">(Dois cliques para adicionar)</i>
            </label>
            <div class="mt-2">
              <p-listbox
                [filter]="true"
                [options]="grupoMunicipios"
                [group]="true"
                [style]="{'width':'360px', 'height':'300px' }"
                [listStyle]="{ 'max-height': '250px'}"
                appendTo="body"
                optionLabel="label"
                optionValue="value"
                (onDblClick)="adicionarMunicipio($event)"
                [disabled]="somenteVisualizar">
                <ng-template let-group pTemplate="group">
                  <div class="flex align-items-center">
                    <span>{{group.label}}</span>
                  </div>
                </ng-template>
              </p-listbox>
            </div>
          </div>

          <div class="ms-5">
            <label style="font-size: 12px;">Município(s) selecionado(s)</label>
            <p-scrollPanel [style]="{ width: '500px', height: '367px', 'margin-top': '10px' }">
              <div *ngFor="let mun of municipiosSelecionados" class="m-3">
                <div class="d-flex flex-row align-items-center">
                  <div class="w-100">
                    <p-card [style]="{ width: '100%' }">
                      <div class="d-flex flex-column">
                        <div class="d-flex flex-row justify-content-between align-items-center">

                          <div class="ms-2" style="width: 150px; padding-right: 10px;">
                            <b>{{mun?.municipio}}</b>&nbsp;-&nbsp;{{mun?.estadoSigla}}
                          </div>

                          <div class="d-flex flex-column align-items-start" style="width: 250px;">
                            <div class="d-flex flex-row">
                              <div class="mt-2" *ngIf="requerAlgumDadoAdicional(mun)">
                                <div *ngIf="mun? !mun.pendencia: false" pTooltip="Sem nenhuma pendencia">
                                  <i class="pi pi-check" style="color: var(--green-500); font-size: large; font-weight: 600;"></i>
                                </div>
                                <div *ngIf="mun? mun.pendencia: false" pTooltip="Necessário dados complementares" [showDelay]="500" tooltipPosition="left">
                                  <i class="pi pi-exclamation-triangle" style="color: var(--yellow-500); font-size: large; font-weight: 500;"></i>
                                </div>
                              </div>
                              <div *ngIf="requerAlgumDadoAdicional(mun)">
                                <p-button
                                  label="Dados complementares"
                                  [link]="true"
                                  (onClick)="openModalCadastroDadosComplementares(mun)"
                                  [disabled]="somenteVisualizar">
                                </p-button>
                              </div>
                            </div>
                            <div *ngIf="mun?.diferenciaTipoEmissao" class="mb-2">
                              <p-checkbox
                                [style]="{'margin-left': '-0.5px', 'margin-right': '2px', 'margin-bottom': '3px'}"
                                pTooltip="Se marcado, busca nota via Webservice, senão, via Bot."
                                tooltipPosition="bottom"
                                label="Emite NFS-e manualmente?"
                                inputId="emiteNfse"
                                [binary]="true"
                                [(ngModel)]="mun.emiteNfseManual"
                                (onChange)="onCheckboxTipoEmissaoChange(mun)"
                                [disabled]="somenteVisualizar"
                              />
                            </div>
                            <div class="d-flex flex-row">
                              <div class="ms-0" style="margin-left: -10px !important">
                                <p-checkbox
                                  [(ngModel)]="mun.temTomada"
                                  [binary]="true"
                                  inputId="temTomadas"
                                  label="Tomadas"
                                  (onChange)="onCheckboxTemTomadas(mun)"
                                  [disabled]="somenteVisualizar">
                                </p-checkbox>
                              </div>
                              <div>
                                <p-checkbox
                                  [(ngModel)]="mun.temPrestada"
                                  [binary]="true"
                                  inputId="temPrestadas"
                                  label="Prestadas"
                                  (onChange)="onCheckboxTemPrestadas(mun)"
                                  [disabled]="somenteVisualizar">
                                </p-checkbox>
                              </div>
                            </div>
                          </div>

                          <div style="width: 45px;">
                            <p-button
                              (onClick)="removeMunicipio(mun)"
                              icon="pi pi-trash"
                              styleClass="p-button-rounded p-button-text"
                              [disabled]="somenteVisualizar">
                            </p-button>
                          </div>
                        </div>
                      </div>
                    </p-card>
                  </div>
                </div>
              </div>
            </p-scrollPanel>
          </div>
        </div>
      </div>
    </p-tabPanel>

  </p-tabView>

  <div class="w-100 d-flex justify-content-end me-4 btn-salvar-cancelar">
    <div class="ms-4">
      <button (click)="clickSalvar()" pButton icon="pi pi-save" label="Salvar" class="p-button-primary botao-custom" [disabled]="activeButtonSalvar()"></button>
    </div>
    <div *ngIf="showBotaoCancelar" class="ms-4">
      <button pButton (click)="close()" label="Cancelar" class="p-button-text ms-3"></button>
    </div>
  </div>

</div>

<!-- Modal que exibe os campos que precisam ser preenchidos -->
<p-dialog
  class="modal-header-display-flex"
  header="Dados necessários para {{municipioSelecionado?.municipio}}"
  [modal]="true"
  [(visible)]="showModalPreencherDados"
  [style]="{ width: '590px', height: '600px' }"
  [draggable]="false"
  [resizable]="false">

  <p class="ms-2">
    Preencha os dados necessários para busca de notas na prefeitura de {{municipioSelecionado?.municipio}}.
  </p>

  <!-- Dados de login para o portal da prefeitura -->
  <div>
    <p-card
      header="{{municipioSelecionado?.requerLoginSite ? 'Dados para login no portal da prefeitura' : ''}}"
      [style]="{ 'box-shadow': 'none' }">
      <div class="m-4" *ngIf="municipioSelecionado?.requerLoginSite">
        <span class="p-float-label">
          <input pInputText id="login"
            [(ngModel)]="login"
            [style]="{width: '350px'}"
            maxlength="50" />
          <label htmlFor="login">Login</label>
        </span>
        <span class="p-float-label" style="margin-top: 28px;">
          <input pInputText id="senha"
            [(ngModel)]="senha"
            [style]="{width: '350px'}"
            maxlength="100"/>
          <label htmlFor="senha">Senha</label>
        </span>
      </div>
    </p-card>
  </div>

  <!-- Inscrição Municipal -->
  <div>
    <p-card
      header="{{municipioSelecionado?.requerInscricaoMunicipal ? 'Inscrição Municipal' : ''}}"
      [style]="{ 'box-shadow': 'none' }">
      <div class="m-4 mb-0 mt-0" *ngIf="municipioSelecionado?.requerInscricaoMunicipal">
        <span class="p-float-label">
          <input pInputText #inputInscricaoBox id="inscricaoMunicipal"
            [ngClass]="{'ng-invalid': !isValidInscricaoMunicipal}"
            (input)="validateInscricaoMunicipal(inputInscricaoBox.value)"
            [(ngModel)]="inscricaoMunicipal"
            [style]="{width: '350px'}"
            maxlength="50"/>
        </span>
        <div style="height: 20px;">
          <small>
            <span *ngIf="!isValidInscricaoMunicipal" style="color: red;">Insira apenas letras ou números {{isValidInscricaoMunicipal}}</span>
          </small>
        </div>
      </div>
    </p-card>
  </div>

  <!-- Secret Token -->
  <div>
    <p-card
      header="{{municipioSelecionado?.requerSecretToken ? 'Secret Token' : ''}}"
      [style]="{ 'box-shadow': 'none' }">
      <div class="m-4 mb-0 mt-0" *ngIf="municipioSelecionado?.requerSecretToken">
        <span class="p-float-label">
          <input pInputText #inputSecretTokenBox id="secretToken"
            [ngClass]="{'ng-invalid': !isValidInscricaoMunicipal}"
            (input)="validateInscricaoMunicipal(inputSecretTokenBox.value)"
            [(ngModel)]="secretToken"
            [style]="{width: '350px'}"
            maxlength="64"/>
        </span>
        <div style="height: 20px;">
          <small>
            <span *ngIf="!isValidInscricaoMunicipal" style="color: red;">Insira apenas letras ou números {{isValidInscricaoMunicipal}}</span>
          </small>
        </div>
      </div>
    </p-card>
  </div>

  <!-- Lista prestadores -->
  <div>
    <p-card
      header="{{municipioSelecionado?.requerListaPrestadores ? 'Prestadores' : ''}}"
      [style]="{ 'box-shadow': 'none' }">
      <div style="margin-top: -3rem"></div>
      <div style="text-align: end; width: 28.4rem;" class="m-4 mb-0 mt-0" *ngIf="municipioSelecionado?.requerListaPrestadores">
        <p-button
          icon="pi pi-plus"
          [rounded]="true"
          [outlined]="true"
          size="small"
          [style]="{width: '5px', height: '5px', 'margin-bottom': '1rem'}"
          (click)="addNovoPrestador()"
          pTooltip="Adiciona dados de prestador à lista"
          showDelay="500">
        </p-button>
        <div class="d-flex flex-row mt-2" *ngFor="let prestador of prestadores; let i = index">
          <div>
            <input pInputText
              id="cnpj-{{i}}"
              [(ngModel)]="prestador.cnpj"
              [style]="{width: '11rem'}"
              [ngClass]="{'invalido': !validaCnpjPrestador(prestador.cnpj!)}"
              mask="00.000.000/0000-00"
              placeholder="CNPJ"
              autocomplete="off"/>
          </div>
          <div class="ms-3">
            <input pInputText
              id="inscricaoMunicipal-{{i}}"
              [(ngModel)]="prestador.inscricaoMunicipal"
              [style]="{width: '14rem'}"
              maxlength="14"
              [ngClass]="{'ng-invalid': validaCnpj(prestador.inscricaoMunicipal!)}"
              placeholder="Inscrição Municipal"
              autocomplete="off"/>
          </div>
          <div class="ms-1" *ngIf="prestadores.length > 1">
            <p-button icon="pi pi-trash" [rounded]="true" [text]="true" (click)="removePrestador(prestador)" />
          </div>
        </div>
      </div>
    </p-card>
  </div>

  <ng-template pTemplate="footer">
    <button
      pButton
      label="OK"
      class="p-button-primary botao-custom me-3 mb-2"
      [disabled]="!validaPreenchimentoDados()"
      (click)="atualizaEmpresaDadosLoginNfse()">
    </button>
  </ng-template>

</p-dialog>
