import { Injectable, EventEmitter } from '@angular/core';
import { NotificacaoModel } from 'src/model/notificacao.model';

@Injectable({
  providedIn: 'root'
})
export class NotificacoesService {

  onReceiveNovasNotas = new EventEmitter<NotificacaoModel>();
}
