<div class="container">
  <div class="container-width">
    <!-- TITULO E BOTOES DE ACOES -->
    <div class="d-flex flex-row titulo-btn-acoes mb-3">
      <div class="flex-grow-1">
        <h4>Cadastro das empresas</h4>
      </div>
    </div>

    <span class="p-text-secondary block ps-1">
      Selecione um cliente para que exiba as empresas dele:
    </span>
    <div class="mt-2"></div>
    <p-dropdown #campoPesquisaCliente
      [filter]="true"
      [filterFields] ="['nome','login']"
      [style]="{'width': '550px', 'max-height': '400px'}"
      [options]="clientesCombobox"
      optionLabel="nome"
      placeholder="Selecione um cliente"
      (onChange)="onChangeCliente($event)"
      (onClick)="cleanFieldFilter()">
      <ng-template let-clientes pTemplate="item">
        <div class="item">
          <div><span class="font-monospaced">[{{clientes.login}}]</span><b> {{clientes.nome}}</b></div>
        </div>
      </ng-template>
    </p-dropdown>

    <div class="d-flex flex-column mt-4">
      <div class="d-flex bd-highlight mb-2">
        <span class="p-input-icon-left flex-grow-1">
          <i class="pi pi-search"></i>
          <input pInputText
            placeholder="Procura por CNPJ ou nome da empresa"
            class="p-inputtext p-component p-element"
            style="width: 400px;"
            [(ngModel)]="filtroCnpjNome"
            (ngModelChange)="procuraComDelay()">
        </span>
        <button pButton icon="pi pi-plus" label="Nova empresa" class="p-button-outlined p-button-sm"
          pTooltip="Adiciona nova empresa" [showDelay]="500" (click)="cadastrarNovaEmpresa()">
        </button>
        <button pButton class="p-button-rounded p-button-text ms-4 me-3" pTooltip="Recarrega página" [showDelay]="500" (click)="refresh()">
          <i class="pi pi-refresh"></i>
        </button>
      </div>
      <!-- TABLE - EMPRESAS DO CLIENTE -->
      <p-table
        dataKey="id"
        styleClass="p-datatable-sm"
        class="table-nfse"
        responsiveLayout="scroll"
        [value]="empresas.content"
        [loading]="loading"
        [scrollable]="true"
        scrollHeight="calc(100% - 200px)"
        [paginator]="empresas.totalElements > 0"
        [rowsPerPageOptions]="pageSizes"
        [rows]="pageSizes[0]"
        currentPageReportTemplate="{first} - {last} de {{empresas.totalElements ? empresas.totalElements : 0}}"
        [showCurrentPageReport]="true"
        [totalRecords]="empresas.totalElements"
        [lazy]="true"
        (onLazyLoad)="onLazyLoad($event)">
        <!-- TABLE HEADER -->
        <ng-template pTemplate="header">
          <tr [style]="{'height': '50px'}">
            <th [ngStyle]="{'width': 'auto'}">
              CNPJ/CPF
            </th>
            <th [ngStyle]="{'width': 'auto'}">
              Nome
            </th>
            <th [ngStyle]="{'width': '62px', 'text-align': 'center'}">
              Ativa
            </th>
            <th [ngStyle]="{'width': '152px', 'text-align': 'center'}">
              Validade do certificado
            </th>
            <th [ngStyle]="{'width': '40px', 'padding': '0'}"></th>
            <th [ngStyle]="{'width': '55px', 'padding': '0'}"></th>
          </tr>
        </ng-template>
        <!-- TABLE BODY - EMPRESAS -->
        <ng-template pTemplate="body" let-empresa let-expanded="expanded">
          <tr class="table-row-body">
            <td>
              {{empresa.id.length == 14 ? (empresa.id | mask : "00.000.000/0000-00"): (empresa.id | mask : "00.000.000-00") }}
            </td>
            <td>
              {{empresa.nome}}
            </td>
            <td>
              <div class="d-flex justify-content-center">
                <div *ngIf="empresa.ativa" class="ativa" pTooltip="Ativa"></div>
                <div *ngIf="!empresa.ativa" class="ativa inativa" pTooltip="Inativa"></div>
              </div>
            </td>
            <td>
              <div
                class="d-flex justify-content-center"
                style="text-align: center;"
                [ngStyle]="{
                  'color': !empresa.certificado?.validoAte ? 'gray' : (dataVencida(empresa.certificado) ? 'red' : 'black'),
                  'font-weight': dataVencida(empresa.certificado) ? '600' : 'normal'
                }">
                {{empresa.certificado?.validoAte ? (empresa.certificado?.validoAte | date : 'dd/MM/yyyy') : 'Sem certificado' }}
              </div>
            </td>
            <td style="padding: 0;">
              <div class="d-flex align-items-end">
                <button
                  pButton
                  class="p-button-rounded p-button-text"
                  pTooltip="Edita o cadastro da empresa"
                  [showDelay]="500"
                  (click)="editaEmpresa(empresa)">
                  <i class="pi pi-pencil"></i>
                </button>
              </div>
            </td>
            <td style="padding: 0;">
              <div class="d-flex align-items-end">
                <button
                  pButton
                  class="p-button-rounded p-button-text"
                  pTooltip="Associa a empresa a um ou mais usuários"
                  [showDelay]="500"
                  (click)="showModalAssociarEmpresaUsuario(empresa)">
                  <i class="pi pi-users"></i>
                </button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <td colspan="8" class="p-3 bg-white" *ngIf="!(empresas.totalElements > 0)">
            <span class="d-flex justify-content-center">Nenhuma empresa encontrada</span>
          </td>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<!-- Modal - Formulário do cadastro de empresa -->
<p-dialog
  class="modal-header-display-block"
  header='Cadastro da empresa: {{empresa?.nome}}'
  [modal]="true"
  [(visible)]="showCadastroEmpresa"
  styleClass="modalSize"
  [draggable]="false"
  [resizable]="false"
  [closable]="false">
  <ng-template pTemplate="header">
    <b>Cadastro da empresa:</b>&nbsp;&nbsp;{{empresa?.nome}}
  </ng-template>
  <app-form-cadastro-empresa
    [empresa]="empresa"
    (onClickCancelar)="closeModalCadastroEmpresa()"
    (onClickSalvar)="salvarEmpresa($event)">
  </app-form-cadastro-empresa>
</p-dialog>

<!-- Modal - Associa a empresa selecionada usuário(s) para gerencia-la -->
<p-dialog
  class="modal-header-display-block"
  [modal]="true"
  closable="false"
  draggable="false"
  [(visible)]="showModalUsuarioEmpresas"
  [style]="{width: '60rem', height: '40rem'}">
  <ng-template pTemplate="header">
    <b>Associa um ou mais usuários à empresa</b>
  </ng-template>
  <div class="m-3 mb-0" style="height: 21rem;">
    <div class="gap-3 mt-3 mb-4" style="height: 28rem;">
      <div class="d-flex justify-content-between mb-2">
        <div class="mt-2">
          <span class="p-text-secondary block">
            Empresa: <b>{{empresa?.nome}}</b>
          </span>
        </div>
        <button pButton icon="pi pi-plus" label="Usuário" class="p-button-outlined p-button-sm"
          pTooltip="Cria novo usuário" [showDelay]="500" (click)="cadastrarNovoUsuario()">
        </button>
      </div>
      <!-- TABLE - USUARIOS -->
      <p-table
        #tableUsuarios
        dataKey="login"
        styleClass="p-datatable-sm"
        class="table-nfse"
        responsiveLayout="scroll"
        [value]="fillTableWithBlankRows(usuarios.content)"
        [(selection)]="selectedUsuarios"
        [loading]="loadingTableUsuarios"
        [scrollable]="true"
        scrollHeight="calc(100% - 200px)"
        [paginator]="usuarios.totalElements > 0"
        [rows]="5"
        currentPageReportTemplate="{first} - {last} de {{usuarios.totalElements ? usuarios.totalElements : 0}}"
        [showCurrentPageReport]="true"
        [totalRecords]="usuarios.totalElements">
        <!-- TABLE HEADER -->
        <ng-template pTemplate="header">
          <tr [style]="{'height': '50px'}">
            <th style="width: 4rem">
            </th>
            <th [ngStyle]="{'width': 'auto'}">
              Login
            </th>
            <th [ngStyle]="{'width': 'auto'}">
              Nome
            </th>
            <th [ngStyle]="{'width': '62px', 'text-align': 'center'}">
              Tipo
            </th>
            <th [ngStyle]="{'width': '62px', 'text-align': 'center'}">
              Ativo
            </th>
            <th [ngStyle]="{'width': '66px'}"></th>
          </tr>
        </ng-template>
        <!-- TABLE BODY - usuarios -->
        <ng-template pTemplate="body" let-usuario>
          <tr class="table-row-body">
            <td>
              <p-checkbox
                *ngIf="usuario.login"
                [ngModel]="selectedUsuarios.includes(usuario)"
                class="ml-1"
                [binary]="true"
                (onChange)="onClickCheckBox($event, usuario)">
              </p-checkbox>
            </td>
            <td>
              {{usuario.login}}
            </td>
            <td>
              {{usuario.nome}}
            </td>
            <td>
              <span pTooltip="Tipo CLIENTE é o que o cliente vai usar para entrar no portal e-Reobot." showDelay="500">
                {{usuario.clienteTipo}}
              </span>
            </td>
            <td>
              <div class="d-flex justify-content-center">
                <div *ngIf="usuario.ativo" class="ativa" pTooltip="Ativo"></div>
                <div *ngIf="!usuario.ativo && usuario.login" class="ativa inativa" pTooltip="Inativo"></div>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-end">
                <button
                  pButton
                  *ngIf="usuario.login"
                  class="p-button-rounded p-button-text"
                  pTooltip="Edita cadastro do usuário"
                  (click)="editaCadastrousuario(usuario)">
                  <i class="pi pi-pencil"></i>
                </button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <td colspan="8" class="p-3 bg-white" *ngIf="!(usuarios.totalElements > 0)">
            <span class="d-flex justify-content-center">Nenhum registro de usuário para esse cliente encontrado</span>
          </td>
        </ng-template>
      </p-table>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <div class="w-100 d-flex justify-content-between me-4">
      <div>Selecionados:&nbsp;&nbsp;{{selectedUsuarios.length}}</div>
      <div class="d-flex justify-content-end me-4">
        <div class="ms-4">
          <button pButton label="Ok" class="p-button-primary botao-custom" (click)="salvarListaUsuario()"></button>
        </div>
        <div class="ms-4">
          <button pButton (click)="showModalUsuarioEmpresas = false" label="Cancelar" class="p-button-text ms-3"></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-dialog>

<!-- Modal - Cadastro de novo usuário -->
<p-dialog
  [modal]="true"
  closable="false"
  draggable="false"
  [(visible)]="showModalNovoUsuario"
  [style]="{width: '50rem', height: '36rem'}">
  <ng-template pTemplate="header">
    <span *ngIf="!selectedUsuario?.login">
      <b>Cria novo cadastro de usuário</b>
    </span>
    <span *ngIf="selectedUsuario?.login">
      Editando: <b>{{selectedUsuario?.nome}}</b>
    </span>
  </ng-template>
  <div class="m-3 mb-0" style="height: auto;">
    <div class="gap-3 mt-3 mb-4" style="height: 24rem;">
      <app-form-cadastro-cliente
        [cliente]="selectedUsuario"
        (onSalvarCliente)="onClickSalvarUsuario($event)"
        (onCancelar)="onClickCancelarSalvarUsuario()">
      </app-form-cadastro-cliente>
    </div>
  </div>
</p-dialog>
