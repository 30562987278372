export class Page<T> {

  public content!: T[];
  public totalElements: number = 0;
  public totalPages: number = 0;
  public size: number = 0;
  public number: number = 0;
  public first: boolean = true;
  public last: boolean = true;
  public empty: boolean = true;
  public sort!: Sort;
}

export class Sort {
  sorted!: boolean;
  unsorted!: boolean;
  empty!: boolean;
}
