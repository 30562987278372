<div class="container">
  <div class="container-width">
    <!-- TITULO E BOTOES DE ACOES -->
    <div class="d-flex flex-row titulo-btn-acoes mb-3">
      <div class="flex-grow-1">
        <h4>Cadastro de clientes (Suporte)</h4>
      </div>
    </div>

    <div class="d-flex flex-column mt-3">

      <div class="d-flex bd-highlight mb-2">
        <span class="p-input-icon-left flex-grow-1">
          <i class="pi pi-search"></i>
          <input pInputText
            placeholder="Procura por login ou nome do cliente"
            class="p-inputtext p-component p-element"
            style="width: 400px;"
            [(ngModel)]="filtroLoginNome"
            (ngModelChange)="procuraComDelay()">
        </span>
        <button pButton icon="pi pi-plus" label="Cliente" class="p-button-outlined p-button-sm"
          pTooltip="Adiciona novo cliente" [showDelay]="500" (click)="cadastrarNovoCliente()">
        </button>
        <button pButton class="p-button-rounded p-button-text ms-4 me-3" pTooltip="Recarrega página" [showDelay]="500" (click)="refresh()">
          <i class="pi pi-refresh"></i>
        </button>
      </div>

      <!-- TABLE - CLIENTES -->
      <p-table
        dataKey="id"
        styleClass="p-datatable-sm"
        class="table-nfse"
        responsiveLayout="scroll"
        [value]="clientes.content"
        [loading]="loading"
        [scrollable]="true"
        scrollHeight="calc(100% - 200px)"
        [paginator]="clientes.totalElements > 0"
        [rowsPerPageOptions]="pageSizes"
        [rows]="pageSizes[0]"
        currentPageReportTemplate="{first} - {last} de {{clientes.totalElements ? clientes.totalElements : 0}}"
        [showCurrentPageReport]="true"
        [totalRecords]="clientes.totalElements"
        [lazy]="true"
        (onLazyLoad)="onLazyLoad($event)">
        <!-- TABLE HEADER -->
        <ng-template pTemplate="header">
          <tr [style]="{'height': '50px'}">
            <th [ngStyle]="{'width': 'auto'}">
              Login
            </th>
            <th [ngStyle]="{'width': 'auto'}">
              Nome
            </th>
            <th [ngStyle]="{'width': '62px', 'text-align': 'center'}">
              Tipo
            </th>
            <th [ngStyle]="{'width': '62px', 'text-align': 'center'}">
              Ativo
            </th>
            <th [ngStyle]="{'width': '152px', 'text-align': 'center'}">
              Data do cadastro
            </th>
            <th [ngStyle]="{'width': '132px', 'text-align': 'center'}">
              Controle XML<br>baixados/limite
            </th>
            <th [ngStyle]="{'width': '66px'}"></th>
          </tr>
        </ng-template>
        <!-- TABLE BODY - clientes -->
        <ng-template pTemplate="body" let-cliente let-expanded="expanded">
          <tr class="table-row-body">
            <td>
              {{cliente.login }}
            </td>
            <td>
              {{cliente.nome}}
            </td>
            <td>
              <span pTooltip="Tipo SUPORTE é o usuário que a equipe do e-Reobot usará para suporte ao cliente." showDelay="500">
                {{cliente.clienteTipo}}
              </span>
            </td>
            <td>
              <div class="d-flex justify-content-center">
                <div *ngIf="cliente.ativo" class="ativa" pTooltip="Ativo"></div>
                <div *ngIf="!cliente.ativo" class="ativa inativa" pTooltip="Inativo"></div>
              </div>
            </td>
            <td>
              <div class="d-flex justify-content-center">
                <span>{{cliente.dataRegistro | date: "dd/MM/yyyy"}}</span>&nbsp;
                <span style="font-size: 1rem; color: rgb(165, 165, 165);">{{cliente.dataRegistro | date: "HH:mm"}}</span>
              </div>
            </td>
            <td>
               <div class="d-flex justify-content-center">
                <div [ngStyle]="cliente.controleDownloadXML.baixados >= cliente.controleDownloadXML.limite ? {'color': 'red'} : {'color': 'green'}">
                  <b>{{cliente.controleDownloadXML.baixados}}</b>
                </div>
                <b style="line-height: 14px; font-size: 1.3rem; color: gray;">/</b>
                <span>{{cliente.controleDownloadXML.limite}}</span>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-end">
                <button pButton class="p-button-rounded p-button-text" pTooltip="Edita o cliente" (click)="editaCliente(cliente)">
                  <i class="pi pi-pencil"></i>
                </button>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <td colspan="8" class="p-3 bg-white" *ngIf="!(clientes.totalElements > 0)">
            <span class="d-flex justify-content-center">Nenhum registro de cliente encontrado</span>
          </td>
        </ng-template>
      </p-table>
    </div>
  </div>

</div>

<!-- Modal - Formulário do cadastro de cliente -->
<p-dialog
  header='Cadastro do cliente: {{selectedCliente?.nome}}'
  [modal]="true"
  [(visible)]="showModalCadastroCliente"
  styleClass="modalSize"
  [draggable]="false"
  [resizable]="false"
  [closable]="false">
  <app-form-cadastro-cliente
    [cliente]="selectedCliente"
    (onCancelar)="onCancelarSalvarCliente()"
    (onSalvarCliente)="onSalvarCliente($event)">
  </app-form-cadastro-cliente>
</p-dialog>
