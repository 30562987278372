<div>

  <div class="d-flex flex-column">
    <!-- TOP BAR -->
    <div class="top-bar">
      <div class="d-flex flex-row">
        <div style="width: 255px; height: 88px;" class="d-flex justify-content-center logo-header">
          <img class="mt-2" src="assets/images/logo-ereobot.png" alt="Logo e-Reobot">
        </div>
        <div class="top-bar-selecao-empresa">
          <div class="d-flex align-items-center">

            <div class="d-flex align-items-end pt-2">
              <div *ngIf="userLogged?.clienteTipo != ClienteTipo.ADMIN">
                <div class="text-black-50 mb-1">
                  <b>Empresa: </b>{{empresasDropdown.length > 0 ? selectedEmpresa?.id : labelNenhumaEmpresaEncontrada}}
                </div>
                <div class="me-4">
                  <p-dropdown
                    #campoPesquisaEmpresa
                    [filter]="empresasDropdown.length > 0"
                    [filterFields] ="['nome','id']"
                    class="campo-empresas-top-bar"
                    [options]="empresasDropdown"
                    [(ngModel)]="selectedEmpresa"
                    optionLabel="nome"
                    [placeholder]="loadingComboEmpresas ? 'Carregando empresas...' : 'Selecione a empresa'"
                    [disabled]="loadingComboEmpresas"
                    (onChange)="onChangeEmpresa($event)"
                    (onClick)="cleanFieldFilter()">
                    <ng-template let-empresas pTemplate="item">
                      <div class="item">
                        <div>
                          <span class="font-monospaced">[{{empresas.id}}]</span><b> {{empresas.nome}}</b>
                        </div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
              </div>

              <div>
                <div class="calendario-periodo">
                  <div class="botao botao-previous" [ngClass]="{disabled: previousBottonDisabled}" (click)="previousBottonDisabled ? '' : setMonthPrev()">
                    <span class="p-datepicker-prev-icon pi pi-chevron-left"></span>
                  </div>
                  <div class="calendario">
                    <p-calendar
                      [(ngModel)]="periodo.dataInicio"
                      (ngModelChange)="onChangePeriodo(periodo.dataInicio)"
                      view="month"
                      dateFormat="MM - yy"
                      [readonlyInput]="true"
                      [minDate]="minDate"
                      [maxDate]="maxDate"
                      [monthNavigator]="true"
                      [yearNavigator]="true"
                      yearRange="2020:2030"/>
                  </div>
                  <div class="botao botao-next" [ngClass]="{disabled: bloqueioNextBottom()}" (click)="bloqueioNextBottom() ? '' : setMonthNext()">
                    <span class="p-datepicker-next-icon pi pi-chevron-right"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="top-bar-user">
              <div class="d-flex flex-row">
                <div class="mt-1">
                  <app-notificacoes *ngIf="authService.isAuthenticated()"></app-notificacoes>
                </div>
                <div>
                  <button pButton icon="pi pi-angle-down" iconPos="right" class="p-button-text"
                    label="{{nomeCliente}}"
                    (click)="menu.toggle($event)"
                    [pTooltip]="tooltipNomeCliente"
                    tooltipPosition="top"
                    tooltipStyleClass="tooltip-big-width"
                    [showDelay]="2000">
                  </button>
                  <p-menu #menu [popup]="true" [model]="items"></p-menu>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row">

      <!-- MENU -->
      <div class="menu">
        <div class="mt-4"></div>
        <!-- Dashboard -->
        <div *ngIf="userLogged?.clienteTipo != ClienteTipo.ADMIN">
          <button pButton (click)="goToPageDashboard()" [ngClass]="{'active': selectedMenu == '/dashboard'}">
            <i class="pi pi-chart-pie"></i>
            <span>Dashboard</span>
          </button>
        </div>
        <!-- NF-e -->
        <div *ngIf="userLogged?.clienteTipo != ClienteTipo.ADMIN">
          <button
            pButton
            (click)="goToPageDfe()"
            [ngClass]="{'active': selectedMenu == '/dfe'}"
            [disabled]="!isEnableNfePage"
            [style.color]="!isEnableNfePage ? '#a9a9a9' : ''">
            <i class="pi pi-file"></i>
            <span>NF-e</span>
          </button>
        </div>
        <!-- CT-e -->
        <div *ngIf="userLogged?.clienteTipo != ClienteTipo.ADMIN">
          <button pButton (click)="goToPageCte()" [ngClass]="{'active': selectedMenu == '/cte'}" [disabled]="!isEnableCtePage"
                  [style.color]="!isEnableCtePage ? '#a9a9a9' : ''">
            <i class="pi pi-file"></i>
            <span>CT-e</span>
          </button>
        </div>
        <!-- CF-e -->
        <div *ngIf="userLogged?.clienteTipo != ClienteTipo.ADMIN">
          <button pButton (click)="goToPageCfe()" [ngClass]="{'active': selectedMenu == '/cfe'}" [disabled]="!isEnableCfePage"
                  [style.color]="!isEnableCfePage ? '#a9a9a9' : ''">
            <i class="pi pi-file"></i>
            <span>CF-e</span>
          </button>
        </div>
        <!-- NFS-e -->
        <div *ngIf="userLogged?.clienteTipo != ClienteTipo.ADMIN">
          <button pButton (click)="goToPageNFSe()" [ngClass]="{'active': selectedMenu == '/nfse'}" [disabled]="!isEnableNfsePage"
                  [style.color]="!isEnableNfsePage ? '#a9a9a9' : ''">
            <i class="pi pi-file"></i>
            <span>NFS-e</span>
          </button>
        </div>
        <!-- NFS-e Nacional -->
        <div *ngIf="userLogged?.clienteTipo != ClienteTipo.ADMIN">
          <button pButton (click)="goToPagePortalNfSe()" [ngClass]="{'active': selectedMenu == '/portal-nfse'}" [disabled]="!isEnablePortalNacionalPage"
          [style.color]="!isEnablePortalNacionalPage ? '#a9a9a9' : ''">
            <i class="pi pi-file"></i>
            <span>NFS-e Nacional</span>
          </button>
        </div>
        <!-- Relatório -->
        <div *ngIf="userLogged?.clienteTipo != ClienteTipo.ADMIN">
          <button pButton (click)="goToPageRelatorio()" [ngClass]="{'active': selectedMenu == '/relatorios'}">
            <i class="pi pi-chart-line"></i>
            <span>Relatórios</span>
          </button>
        </div>
        <!-- Importart Exportar -->
        <div *ngIf="userLogged?.clienteTipo != ClienteTipo.ADMIN">
          <button pButton (click)="goToPageImportarExportar()" [ngClass]="{'active': selectedMenu == '/importar-exportar-xml'}">
            <i class="pi pi-download"></i>
            <span>Importação e Exportação</span>
          </button>
        </div>
        <!-- Minhas empresas -->
        <div *ngIf="userLogged?.clienteTipo != ClienteTipo.ADMIN">
          <button pButton (click)="goToPageMinhasEmpresas()" [ngClass]="{'active': selectedMenu == '/minhas-empresas'}">
            <i class="pi pi-building"></i>
            <span>Minhas empresas</span>
          </button>
        </div>
        <!-- Minha conta -->
        <div *ngIf="userLogged?.clienteTipo != ClienteTipo.ADMIN">
          <button pButton (click)="goToPageMinhaConta()" [ngClass]="{'active': selectedMenu == '/minha-conta'}">
            <i class="pi pi-user"></i>
            <span>Minha conta</span>
          </button>
        </div>
        <!-- ADMIN -->
        <!-- Cadastro dos clientes que utilizam o sistema -->
        <div *ngIf="userLogged?.login == 'admin'">
          <button pButton (click)="goToPageCadastroClientes()" [ngClass]="{'active': selectedMenu == '/cadastro-clientes'}">
            <i class="pi pi-users"></i>
            <span>Cadastro de clientes</span>
          </button>
        </div>
        <!-- ADMIN -->
        <!-- Cadastro das empresas vinculadas aos clientes -->
        <div *ngIf="userLogged?.login == 'admin'">
          <button pButton (click)="goToPageCadastroEmpresas()" [ngClass]="{'active': selectedMenu == '/cadastro-empresas'}">
            <i class="pi pi-building"></i>
            <span>Cadastro de empresas</span>
          </button>
        </div>
      </div>

      <!-- CONTEUDO PAGINAS -->
      <div class="conteudo">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

</div>
