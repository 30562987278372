import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { InputGroupModule } from 'primeng/inputgroup';
import { TableModule } from 'primeng/table';
import { MonitorEmissaoNfe, StatusEmissao } from 'src/model/monitor-emissao-nfe.model';
import { Page } from 'src/model/page.model';
import { DfeService } from 'src/services/dfe.service';
import { MainComponent } from '../../main.component';
import { Empresa } from 'src/model/empresa.model';
import { TooltipModule } from 'primeng/tooltip';
import { CpfCnpjPipe } from 'src/shared/cpf-cnpj.pipe';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-emissao-nfe',
  standalone: true,
  imports: [TableModule, CommonModule, InputGroupModule, TooltipModule, CpfCnpjPipe, ButtonModule],
  templateUrl: './emissao-nfe.component.html',
  styleUrl: './emissao-nfe.component.scss'
})
export class EmissaoNfeComponent implements OnInit, OnDestroy {

  monitorEmissaoPage: Page<MonitorEmissaoNfe> = new Page<MonitorEmissaoNfe>();

  cpfCnpj = CpfCnpjPipe

  loading = false;

  selectedEmpresa?: Empresa;

  onEmpresaChangeSub: any;

  textSizeToTruncate = 60;

  expandedRows = {};

  constructor(
    private main: MainComponent,
    private dfeService: DfeService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.onEmpresaChangeSub = this.main.empresaChange.subscribe((empresa: Empresa) => {
      this.selectedEmpresa = empresa;
      if (this.selectedEmpresa) {
        if (this.selectedEmpresa?.id) {
          this.loadMonitorEmissao(this.selectedEmpresa.id);
        }
      }
    });

    if (this.main.selectedEmpresa) {
      this.selectedEmpresa = this.main.selectedEmpresa;
      this.loadMonitorEmissao(this.selectedEmpresa.id!);
    }

    setInterval(() => {
      if (this.selectedEmpresa?.id) {
        this.loadMonitorEmissao(this.selectedEmpresa.id);
      }
    }, 15000);
  }

  ngOnDestroy() {
    this.onEmpresaChangeSub.unsubscribe();
  }

  loadMonitorEmissao(cnpjCpfEmitente: string) {
    if (cnpjCpfEmitente) {
      let loadingTimeout: any;

      // Começa o timeout para definir loading = true após 1 segundo
      loadingTimeout = setTimeout(() => {
        this.loading = true;
      }, 1000);

      this.dfeService.getListaMonitorEmissaoNfe(cnpjCpfEmitente).subscribe({
        next: (data) => {
          // Caso a requisição termine antes do tempo limite, limpa o timeout e desativa o loading
          clearTimeout(loadingTimeout);
          this.monitorEmissaoPage = data;
          this.loading = false;
        },
        error: (error) => {
          // Caso haja erro, limpa o timeout e desativa o loading
          clearTimeout(loadingTimeout);
          this.loading = false;
          console.error(error);
          this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao carregar monitor de emissão de NF-e' });
        }
      });
    }
  }

  refresh() {
    if (this.selectedEmpresa) {
      this.loadMonitorEmissao(this.selectedEmpresa.id!);
    }
  }

  getStatusClass(statusEmissao: string): string {
    const status = StatusEmissao[statusEmissao as keyof typeof StatusEmissao];
    switch (status) {
      case StatusEmissao.RECEBIDA:
        return 'status status-recebida';
      case StatusEmissao.EM_PROCESSAMENTO:
        return 'status status-processando';
      case StatusEmissao.CONCLUIDO:
        return 'status status-concluido';
      case StatusEmissao.ERRO:
        return 'status status-erro';
      default:
        return '';
    }
  }

  getStatusNome(statusEmissao: string): string {
    const status = StatusEmissao[statusEmissao as keyof typeof StatusEmissao];
    switch (status) {
      case StatusEmissao.RECEBIDA:
        return 'Recebida';
      case StatusEmissao.EM_PROCESSAMENTO:
        return 'Em processamento';
      case StatusEmissao.CONCLUIDO:
        return 'Concluído';
      case StatusEmissao.ERRO:
        return 'Erro';
      default:
        return '';
    }
  }
}
