import { MessageResponse } from '../model/message-response.model';
import { RecoveryPasswordResponse } from './../model/recovery-password-response.model';
import { DadosLogin } from '../model/dados-login.model';
import { Router } from '@angular/router';
import { Cliente } from '../model/cliente.model';
import { environment } from '../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

const ENDPOINT_LOGIN = environment.API_MAIN + "/login";
const ENDPOINT_ALTERA_SENHA = environment.API_MAIN + "/login/altera-senha";
const ENDPOINT_ESQUECEU_SENHA = environment.API_MAIN + "/login/esqueceu-senha";
const ENDPOINT_REDEFINICAO_SENHA = environment.API_MAIN + "/login/redefinicao-senha";
const ENDPOINT_REDEFINI_SENHA = environment.API_MAIN + "/login/redefini-senha";

const KEY_TOKEN = "TOKEN";
const KEY_USER = "USER";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private router: Router
  ) {}

  public autenticar(login: DadosLogin) {
    return this.http.post<any>(ENDPOINT_LOGIN, login);
  }

  public alteraSenha(dadosUsuario: DadosLogin): Observable<Boolean> {
    return this.http.put<Boolean>(ENDPOINT_ALTERA_SENHA, dadosUsuario);
  }

  public redefiniSenha(cnpj: string, code: string, senha: string): Observable<Boolean> {
    return this.http.get<Boolean>(ENDPOINT_REDEFINI_SENHA + "?login=" + cnpj + "&code=" + code + "&senha=" + senha);
  }

  public gravaLocalStorageDadosAutenticacao(user: Cliente) {
    localStorage.clear();
    if (user.token) {
      localStorage.setItem(KEY_USER, JSON.stringify(user));
      localStorage.setItem(KEY_TOKEN, user.token);
    }
  }

  public isAuthenticated() {
    const token = localStorage.getItem(KEY_TOKEN);
    if (token != null && token != 'null') {
      let ret;
      try {
        ret = this.jwtHelper.isTokenExpired(token);
      } catch (error) {
        this.logout();
        this.router.navigate(['/login']);
      }
      return !ret;
    }
    return false;
  }

  public getUsuario(): Cliente | null {
    if (localStorage.getItem(KEY_USER) != null) {
      return JSON.parse(String(localStorage.getItem(KEY_USER)));
    }
    return null;
  }

  public getToken(): string | null {
    if (localStorage.getItem(KEY_TOKEN)) {
      return String(localStorage.getItem(KEY_TOKEN));
    } else {
      return null;
    }
  }

  public logout() {
    localStorage.clear();
    this.router.navigate(['login']);
  }

  public esqueceuASenha(dadosLogin: DadosLogin): Observable<RecoveryPasswordResponse> {
    return this.http.post<RecoveryPasswordResponse>(ENDPOINT_ESQUECEU_SENHA, dadosLogin);
  }

  public redefinicaoSenha(cnpj: string, code: string): Observable<MessageResponse> {
    return this.http.get<MessageResponse>(ENDPOINT_REDEFINICAO_SENHA + "?login=" + cnpj + "&code=" + code);
  }

}
