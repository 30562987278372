import { Component, OnInit, ViewChild } from '@angular/core';
import { Page } from '../../../../model/page.model';
import { MessageService } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { EmpresaPagedRequest } from 'src/model/empresa-paged-request.model';
import { EmpresaService } from 'src/services/empresas.service';
import { CertificadoEmpresa } from 'src/model/certificado-empresa.model';
import { Empresa } from 'src/model/empresa.model';
import { ClienteService } from 'src/services/clientes.service';
import { ClientesPagedRequest } from 'src/model/clientes-paged-request.model';
import { Cliente } from 'src/model/cliente.model';
import { Dropdown } from 'primeng/dropdown';
import { GlobalEventService } from 'src/shared/global-event.service.ts.service';
import { ClienteTipo } from 'src/model/enums/cliente-tipo.enum';

@Component({
  selector: 'app-cadastro-todas-empresas',
  templateUrl: './cadastro-todas-empresas.component.html',
  styleUrl: './cadastro-todas-empresas.component.scss'
})
export class CadastroTodasEmpresasComponent implements OnInit {

  @ViewChild("campoPesquisaCliente", {static: false}) campoPesquisaCliente!: Dropdown;
  @ViewChild('tableUsuarios') tableUsuarios!: Table;

  loading: boolean = false;
  loadingTableUsuarios: boolean = false;
  timeout: any;

  editandoEmpresa = true;
  showCadastroEmpresa: boolean = false;

  empresa: Empresa | undefined = new Empresa();
  filtroCnpjNome: string | undefined;

  clienteRequest: ClientesPagedRequest = new ClientesPagedRequest();
  clientesCombobox: Cliente[] = [];

  empresaRequest: EmpresaPagedRequest = new EmpresaPagedRequest();
  empresas: Page<Empresa> = new Page<Empresa>();
  pageSizes = [10, 15, 20, 50, 100];

  timer: any;
  msgNenhumRegistro: string = '';
  mesAtual = new Date().toLocaleString('default', { month: 'long' });
  selectedCliente: Cliente | undefined;

  usuarios: Page<Cliente> = new Page();
  selectedUsuarios: Cliente[] = [];
  selectedUsuario: Cliente | undefined;
  usuarioRequest: ClientesPagedRequest = new ClientesPagedRequest();
  showModalUsuarioEmpresas = false;
  showModalNovoUsuario = false;

  constructor(
    private clienteService: ClienteService,
    private empresaService: EmpresaService,
    private messageService: MessageService,
    private globalEventService: GlobalEventService
  ) { }

  ngOnInit(): void {
    this.clienteRequest.ativo = true;
    this.clienteRequest.clienteTipo = ClienteTipo.SUPORTE;
    this.clienteRequest.unpaged = true;
    this.loadComboBoxClientes();
  }

  private loadComboBoxClientes() {
    this.showModalLoading();
    this.clienteService.getClientesByFilters(this.clienteRequest).subscribe({
      next: (data: Page<Cliente>) => {
        if (data.content.length == 0) {
          this.msgNenhumRegistro = "Nenhum registro encontrado."
        }  else {
          // Ordena os clientes pelo campo 'nome'
          data.content = data.content.sort((a: Cliente, b: Cliente) => {
            // Compara os nomes, convertendo para minúsculas para garantir que a comparação não seja sensível a caixa
            return a.nome.toLowerCase().localeCompare(b.nome.toLowerCase());
          });
          this.clientesCombobox = data.content;
        }
        this.hideModalLoading();
      },
      error: (e: { name: string; message: string; }) => {
        this.msgNenhumRegistro = "Erro ao carregar dados."
        this.showMensagemErro(e.name, e.message);
        console.error(e);
      }
    }).add(() => {
      this.hideModalLoading();
    });
  }

  cleanFieldFilter() {
    setTimeout(() => {
      this.campoPesquisaCliente.filterValue = "";
    });
  }

  onChangeCliente(event: any) {
    this.selectedCliente = event.value;
    this.empresaRequest = new EmpresaPagedRequest();
    this.empresaRequest.clienteLogin = this.selectedCliente?.login;
    this.filtroCnpjNome = undefined;
    this.loadEmpresas();
  }

  private loadEmpresas() {
    this.showModalLoading();
    if (this.empresaRequest.sortBy == undefined || this.empresaRequest.sortBy.length == 0) {
      this.empresaRequest.sortBy = ["nome;asc"];
    }
    this.empresaService.getEmpresasByFilters(this.empresaRequest).subscribe({
      next: (data: Page<Empresa>) => {
        if (data.content.length == 0) {
          this.msgNenhumRegistro = "Nenhum registro encontrado."
        }
        this.empresas = data;
        this.hideModalLoading();
      },
      error: (e: { name: string; message: string; }) => {
        this.msgNenhumRegistro = "Erro ao carregar dados."
        this.showMensagemErro(e.name, e.message);
        console.error(e);
      }
    }).add(() => {
      this.hideModalLoading();
    });
  }

  showModalAssociarEmpresaUsuario(empresa: Empresa) {
    this.empresa = empresa;
    this.usuarios = new Page();
    this.showModalUsuarioEmpresas = true;
    this.loadUsuarios();
  }

  private loadUsuarios() {
    this.loading = true;
    this.selectedUsuarios = [];
    if (this.empresa && this.empresa.suporteLogin) {
      this.usuarioRequest.clienteTipo = ClienteTipo.CLIENTE;
      this.usuarioRequest.suporteLogin = this.empresa.suporteLogin;
      this.usuarioRequest.unpaged = true;
      this.clienteService.getClientesByFilters(this.usuarioRequest).subscribe({
        next: (data) => {
          this.usuarios = data;
          this.usuarios.content.forEach(usuario => {
            if (usuario.empresas == null) usuario.empresas = [];
          });
          const usuariosAssociados = this.usuarios.content.filter(user => user.empresas?.includes(this.empresa?.id!));
          if (usuariosAssociados) this.selectedUsuarios.push(...usuariosAssociados);
          this.loading = false;
        }
      });
    }
  }

  onClickCheckBox(event: any, usuario: any) {
    let empresasUser = this.usuarios.content.find(user => user === usuario)?.empresas;
    if (event.checked) {
      if (!this.selectedUsuarios.includes(usuario)) {
        this.selectedUsuarios.push(usuario);
      }
      empresasUser?.push(this.empresa?.id!);
    } else {
      this.selectedUsuarios.splice(this.selectedUsuarios.findIndex(selUser => selUser === usuario), 1);
      empresasUser?.splice(empresasUser.findIndex(empresaUser => empresaUser == this.empresa?.id), 1);
    }
    console.log(this.usuarios.content);
  }

  salvarListaUsuario() {
    this.loadingTableUsuarios = true;
    const usuarios = this.usuarios.content.filter(user => user.login !== undefined);
    this.clienteService.atualizaClientes(usuarios).subscribe({
      next: (response) => {
        if (response.successfully) {
          this.showMensagemSucesso("Sucesso", response.result);
        } else {
          this.showMensagemErro("Erro ao atualizar", response.result);
        }
        this.loadingTableUsuarios = false;
        this.showModalUsuarioEmpresas = false;
      },
      error: (e) => {
        this.showMensagemErro(e.name, e.message);
        this.loadingTableUsuarios = false;
        this.showModalUsuarioEmpresas = false;
      }
    });
  }

  cadastrarNovoUsuario() {
    this.selectedUsuario = new Cliente();
    this.selectedUsuario.clienteTipo = ClienteTipo.CLIENTE;
    this.selectedUsuario.suporteLogin = this.empresa?.suporteLogin;
    this.selectedUsuario.ativo = true;
    this.showModalNovoUsuario = true;
  }

  editaCadastrousuario(usuario: Cliente) {
    this.selectedUsuario = usuario;
    this.showModalNovoUsuario = true;
  }

  onClickSalvarUsuario(usuario: Cliente) {
    const usuarioFoundIndex = this.usuarios.content.findIndex(u => u.login == usuario.login);
    if (usuarioFoundIndex > -1) {
      this.usuarios.content[usuarioFoundIndex] = usuario;
      const selUserIndex = this.selectedUsuarios.findIndex(selUser => selUser.login == usuario.login);
      if (selUserIndex > -1) {
        this.selectedUsuarios[selUserIndex] = usuario;
      }
    } else {
      this.loadUsuarios();
    }
    this.showModalNovoUsuario = false;
  }

  onClickCancelarSalvarUsuario() {
    this.showModalNovoUsuario = false;
  }

  fillTableWithBlankRows(data: any[]): any[] {
    if (data) {
      const blankRow = new Cliente();
      const rowsPerPage = 5;
      const totalRows = data.length;
      const blankRowsNeeded = rowsPerPage - (totalRows % rowsPerPage);
      if (blankRowsNeeded < rowsPerPage) {
        for (let i = 0; i < blankRowsNeeded; i++) {
          data.push(blankRow);
        }
      }
      return data;
    }
    return [];
  }

  refresh() {
    this.loadEmpresas();
    this.closeModalCadastroEmpresa();
  }

  procuraComDelay() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      const filtro = this.filtroCnpjNome;
      if (Number(filtro)) {
        this.empresaRequest.id = filtro;
        this.empresaRequest.nome = undefined;
      } else {
        this.empresaRequest.nome = filtro;
        this.empresaRequest.id = undefined;
      }
      this.loadEmpresas();
    }, 500);
  }

  onLazyLoad(event: TableLazyLoadEvent) {
    if (this.empresas.totalElements > 0) {
      this.empresaRequest.pageNum = event.first! / event.rows!;
      if (event.rows) {
        this.empresaRequest.pageSize = event.rows;
      }
      this.loadEmpresas();
    }
  }

  editaEmpresa(empresa: Empresa) {
    this.editandoEmpresa = true;
    this.empresa = empresa;
    this.openModalCadastroEmpresa();
  }

  cadastrarNovaEmpresa() {
    this.editandoEmpresa = false;
    this.empresa = new Empresa();
    this.empresa.suporteLogin = this.selectedCliente?.login;
    this.openModalCadastroEmpresa();
  }

  salvarEmpresa(empresa: Empresa) {
    if (this.editandoEmpresa) {
      this.empresaService.atualizaEmpresa(empresa).subscribe({
        next: (response) => {
          if (response.successfully) {
            if (empresa) {
              const index = this.empresas.content.findIndex(c => c.id === empresa.id);
              if (index > -1) {
                this.empresas.content[index] = empresa;
              }
            } else {
              this.refresh();
            }
            this.showMensagemSucesso("", "Empresa atualizada com sucesso.");
          } else {
            this.showMensagemErro("Erro ao salvar", response.result);
          }
        },
        error: (e) => {
          this.showMensagemErro(e.name, e.message);
        }
      });
    } else {
      this.empresaService.criaEmpresa(empresa).subscribe({
        next: (response) => {
          if (response.successfully) {
            this.showMensagemSucesso("", "Nova empresa cadastrada com sucesso.");
            this.refresh();
          } else {
            this.showMensagemErro("Erro ao salvar", response.result);
          }
        },
        error: (e) => {
          this.showMensagemErro(e.name, e.message);
        }
      });
    }
  }

  openModalCadastroEmpresa() {
    this.showCadastroEmpresa = true;
  }

  closeModalCadastroEmpresa() {
    this.showCadastroEmpresa = false;
  }

  public dataVencida(certificado: CertificadoEmpresa): boolean {
    if (certificado != null && certificado.validoAte) {
      return (new Date(certificado.validoAte).getTime() < new Date().getTime());
    } else {
      return false;
    }
  }

  private showMensagemSucesso(summary: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'success',
      summary: '' + summary,
      detail: '' + detalhe
    });
  }

  private showMensagemErro(summary: string, detalhe: string) {
    this.messageService.add({
      key: 'toastTopCenter800px',
      severity: 'error',
      summary: '' + summary,
      detail: '' + detalhe
    });
  }

  private showModalLoading() {
    this.globalEventService.modalProcessandoEvent.emit(true);
    this.loading = true;
  }

  private hideModalLoading() {
    this.loading = false;
    this.globalEventService.modalProcessandoEvent.emit(false);
  }
}
