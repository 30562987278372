import { AuthService } from '../../services/auth.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ClienteTipo } from 'src/model/enums/cliente-tipo.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  permissoes = new Map();

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    this.permissoes.set("/dfe",                   [ClienteTipo.SUPORTE, ClienteTipo.CLIENTE, ClienteTipo.ADMIN]);
    this.permissoes.set("/cte",                   [ClienteTipo.SUPORTE, ClienteTipo.CLIENTE]);
    this.permissoes.set("/cfe",                   [ClienteTipo.SUPORTE, ClienteTipo.CLIENTE]);
    this.permissoes.set("/nfse",                  [ClienteTipo.SUPORTE, ClienteTipo.CLIENTE]);
    this.permissoes.set("/portal-nfse",           [ClienteTipo.SUPORTE, ClienteTipo.CLIENTE]);
    this.permissoes.set("/relatorios",            [ClienteTipo.SUPORTE, ClienteTipo.CLIENTE]);
    this.permissoes.set("/dashboard",             [ClienteTipo.SUPORTE, ClienteTipo.CLIENTE]);
    this.permissoes.set("/minhas-empresas",       [ClienteTipo.SUPORTE, ClienteTipo.CLIENTE]);
    this.permissoes.set("/minha-conta",           [ClienteTipo.SUPORTE, ClienteTipo.CLIENTE]);
    this.permissoes.set("/importar-exportar-xml", [ClienteTipo.SUPORTE, ClienteTipo.CLIENTE]);
    this.permissoes.set("/cadastro-clientes",     [ClienteTipo.ADMIN]);
    this.permissoes.set("/cadastro-empresas",     [ClienteTipo.ADMIN]);
    this.permissoes.set("/cadastro-usuarios",     [ClienteTipo.ADMIN]);
    this.permissoes.set("/nfse-config",           [ClienteTipo.ADMIN]);
  }

  canActivate(): boolean {
    if (this.authService.isAuthenticated()) {

      const currentPage = this.router.getCurrentNavigation()?.finalUrl?.toString();
      if (currentPage == "/") {
        return true;
      }

      let can = false;
      let clienteTipo: ClienteTipo = this.authService.getUsuario()?.clienteTipo!;

      const permissoesArray: Array<String> = this.permissoes.get(currentPage);

      for (const tipo of permissoesArray) {
        if (tipo == clienteTipo) {
          can = true;
          break;
        }
      }

      return can;
    }
    this.router.navigate(['login']);
    return false;
  }

}
