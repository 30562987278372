import { CfeModulo } from "./cfe-modulo";
import { CteModulo } from "./cte-modulo";
import { NfeModulo } from "./nfe-modulo";
import { NfseModulo } from "./nfse-modulo";
import { PortalNacionalModulo } from "./portal-nacional-modulo";

export class Modulos {

  nfeModulo: NfeModulo = new NfeModulo();
  cteModulo: CteModulo = new CteModulo();
  nfseModulo: NfseModulo = new NfseModulo();
  cfeModulo: CfeModulo = new CfeModulo();
  portalNacionalModulo: PortalNacionalModulo = new PortalNacionalModulo();
}
