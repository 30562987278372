<div class="me-4 mt-3">
  <p-tabView #tabView [styleClass]="'width: 600px'" [(activeIndex)]="selectedTab" header="dados_empresa">
    <!-- TabView - Dados da _empresa -->
    <p-tabPanel>
      <ng-template pTemplate="header">
        Dados da empresa
      </ng-template>
      <div class="m-4" [ngStyle]="{'display': loadPage ? 'none' : 'block'}">
        <div class="d-flex flex-row">
          <div>

            <div [ngStyle]="{'display': !exibeCampoAtiva ? 'none' : 'block'}" class="field-checkbox mb-4">
              <p-checkbox [(ngModel)]="_empresa.ativa" [binary]="true" inputId="ativa"></p-checkbox>
              <label for="ativa" class="ms-2">Ativa</label>
            </div>

            <div class="d-flex flex-row align-items-center">
              <div class="field" style="width: 155px;">
                <label class="d-flex justify-content-between">
                  <div>CNPJ</div>
                  <div><small id="campoCnpj-erro" class="p-error block">{{cnpjErroMensagem}}</small></div>
                </label>
                <input pInputText
                  id="campoCnpj"
                  type="text"
                  (blur)="onBlurCnpj($event)"
                  [(ngModel)]="_empresa.id"
                  [ngModelOptions]="{standalone: true}"
                  mask="00.000.000/0000-00"
                  [disabled]="!empresaNova || somenteVisualizar">
              </div>
            </div>

            <div>
              <div class="field" style="width: 500px;">
                <label class="block">Nome</label>
                <input pInputText
                  maxlength="70"
                  [(ngModel)]="_empresa.nome"
                  [ngModelOptions]="{standalone: true}"
                  [disabled]="somenteVisualizar">
              </div>
            </div>

            <div class="field" style="width: 400px; z-index: 1;">
              <label class="block">UF</label>
              <p-dropdown
                [options]="estados"
                [autoDisplayFirst]="false"
                [(ngModel)]="_empresa.codigoUf"
                [ngModelOptions]="{standalone: true}"
                optionLabel="name"
                optionValue="code"
                appendTo="body"
                [style]="{'width' : '90px'}"
                [disabled]="somenteVisualizar">
                <ng-template let-item pTemplate="item">
                  <div [ngStyle]="{'color': item?.name.startsWith('*') ? 'red': 'black'}">
                    {{item?.name.startsWith('*') ? item.name.substring(1, item.name.length) : item.name}}
                  </div>
                </ng-template>
              </p-dropdown>
            </div>

            <div *ngIf="_empresa.dataRegistro" class="mt-5">
              <b>Data do cadastro: &nbsp;</b>
              <span>{{_empresa.dataRegistro | date : "  dd/MM/yyyy"}}</span>
              <span style="font-size: 12px; color: gray;">{{_empresa.dataRegistro | date : "  HH:mm"}}</span>
            </div>

          </div>
        </div>
      </div>
    </p-tabPanel>

    <!-- TabView - Certificado Digital -->
    <p-tabPanel header="certificado">
      <ng-template pTemplate="header">
        Certificado digital
      </ng-template>
      <div class="m-4">
        <div class="coluna-3">
          <!-- Certificado da _empresa ou do Contador -->
          <div style="width: 500px;">
            <app-form-certificado-digital
              [(certificado)]="_empresa.certificado!"
              label="Certificado A1"
              [somenteVisualizar]="somenteVisualizar">
            </app-form-certificado-digital>
          </div>
        </div>
      </div>
    </p-tabPanel>

    <!-- TabView - Modulos -->
    <p-tabPanel header="modulos">
      <ng-template pTemplate="header">
        Modulos
      </ng-template>
      <div class="m-4">
        <span>Habilita ou desabilita o acesso as páginas <b>NF-e</b>, <b>CT-e</b>, <b>CF-e</b>, <b>NFS-e</b> e <b>NFS-e Nacional</b></span>
        <div>
          <div class="field-checkbox mb-4 mt-5">
            <p-checkbox
              [(ngModel)]="_empresa.modulos.nfeModulo.nfeEnable"
              [binary]="true"
              inputId="enableNfe"
              [disabled]="somenteVisualizar">
            </p-checkbox>
            <label for="enableNfe" class="ms-3">Habilita NF-e</label>
          </div>
          <div class="field-checkbox mb-4">
            <p-checkbox
              [(ngModel)]="_empresa.modulos.cteModulo.cteEnable"
              [binary]="true"
              inputId="enableCte"
              [disabled]="somenteVisualizar">
            </p-checkbox>
            <label for="enableCte" class="ms-3">Habilita CT-e</label>
          </div>
          <div class="field-checkbox mb-4">
            <p-checkbox
              [(ngModel)]="_empresa.modulos.cfeModulo.cfeEnable"
              [binary]="true"
              inputId="enableCfe"
              [disabled]="somenteVisualizar">
            </p-checkbox>
            <label for="enableCfe" class="ms-3">Habilita CF-e</label>
          </div>
          <div class="field-checkbox mb-4 d-flex align-items-center">
            <p-checkbox
              [(ngModel)]="_empresa.modulos.nfseModulo.nfseEnable"
              [binary]="true"
              inputId="enableNfse"
              [disabled]="somenteVisualizar">
            </p-checkbox>
            <label for="enableNfse" class="ms-3">Habilita NFS-e</label>
          </div>
          <div class="field-checkbox mb-4">
            <p-checkbox
              [(ngModel)]="_empresa.modulos.portalNacionalModulo.portalNacionalEnable"
              [binary]="true"
              inputId="enablePortalNacional"
              [disabled]="somenteVisualizar">
            </p-checkbox>
            <label for="enablePortalNacional" class="ms-3">Habilita NFS-e Nacional</label>
          </div>
        </div>
      </div>
    </p-tabPanel>

    <!-- TabView - NFS-e -->
    <p-tabPanel *ngIf="_empresa.modulos.nfseModulo.nfseEnable" header="nfse">
      <ng-template pTemplate="header">
        <div *ngIf="possuiPendencias" pTooltip="Requer preenchimento de alguns dados" [showDelay]="500" tooltipPosition="top">
          <i class="pi pi-exclamation-triangle" style="color: var(--yellow-500); font-size: 1.2rem; font-weight: 500;"></i>
        </div>
        <span class="ms-3">
          NFS-e
        </span>
      </ng-template>

      <div class="m-4 mt-2">
        <div class="d-flex flex-row align-items-end">
          <div>
            <div class="mb-4">
              <label>Liberar notas retroativas a partir de:</label>
              <div class="d-flex align-items-center mt-2 mb-2">
                <p-calendar
                  aria-label="Date"
                  [(ngModel)]="_empresa.modulos.nfseModulo.dataRetroativaNFSe"
                  [showIcon]="true"
                  inputId="dataNfse"
                  (ngModelChange)="onDateChange()"
                  dateFormat="dd/mm/yy"
                  [style]="{'margin-left': '0'}"
                  [showClear]="true"
                  [disabled]="somenteVisualizar">
                </p-calendar>
              </div>
            </div>

            <label>
              Seleção de município
              <i style="font-size: 10px;">(Dois cliques para adicionar)</i>
            </label>
            <div class="mt-2">
              <p-listbox
                [filter]="true"
                [options]="grupoMunicipios"
                [group]="true"
                [style]="{'width' : '360px'}"
                [listStyle]="{ 'max-height': '233px'}"
                appendTo="body"
                optionLabel="label"
                optionValue="value"
                (onDblClick)="adicionarMunicipio($event)"
                [disabled]="somenteVisualizar">
                <ng-template let-group pTemplate="group">
                  <div class="flex align-items-center">
                    <span>{{group.label}}</span>
                  </div>
                </ng-template>
              </p-listbox>
            </div>
          </div>

          <div class="ms-5">
            <label style="font-size: 12px;">Município(s) selecionado(s)</label>
            <p-scrollPanel [style]="{ width: '500px', height: '367px', 'margin-top': '10px' }">
                <div *ngFor="let mun of municipiosSelecionados" class="m-3">
                  <div class="d-flex flex-row align-items-center">
                    <div class="w-100">
                      <p-card [style]="{ width: '100%' }">
                        <div class="d-flex flex-column">
                          <div class="d-flex flex-row justify-content-between align-items-center">

                            <div class="ms-2" style="width: 150px; padding-right: 10px;">
                              <b>{{mun?.municipio}}</b>&nbsp;-&nbsp;{{mun?.estadoSigla}}
                            </div>

                            <div class="d-flex flex-column align-items-start" style="width: 250px;">
                              <div class="d-flex flex-row">
                                <div class="mt-2" *ngIf="requerAlgumDadoAdicional(mun)">
                                  <div *ngIf="mun? !mun.pendencia: false" pTooltip="Sem nenhuma pendencia">
                                    <i class="pi pi-check" style="color: var(--green-500); font-size: large; font-weight: 600;"></i>
                                  </div>
                                  <div *ngIf="mun? mun.pendencia: false" pTooltip="Necessário dados complementares" [showDelay]="500" tooltipPosition="left">
                                    <i class="pi pi-exclamation-triangle" style="color: var(--yellow-500); font-size: large; font-weight: 500;"></i>
                                  </div>
                                </div>
                                <div *ngIf="requerAlgumDadoAdicional(mun)">
                                  <p-button
                                    label="Dados complementares"
                                    [link]="true"
                                    (onClick)="openModalCadastroDadosComplementares(mun)"
                                    [disabled]="somenteVisualizar">
                                  </p-button>
                                </div>
                              </div>
                              <div *ngIf="mun?.diferenciaTipoEmissao" class="mb-2">
                                <p-checkbox
                                  [style]="{'margin-left': '-0.5px', 'margin-right': '2px', 'margin-bottom': '3px'}"
                                  pTooltip="Se marcado, busca nota via Webservice, senão, via Bot."
                                  tooltipPosition="bottom"
                                  label="Emite NFS-e manualmente?"
                                  inputId="emiteNfse"
                                  [binary]="true"
                                  [(ngModel)]="mun.emiteNfseManual"
                                  (onChange)="onCheckboxTipoEmissaoChange(mun)"
                                  [disabled]="somenteVisualizar"
                                />
                              </div>
                              <div class="d-flex flex-row">
                                <div class="ms-0" style="margin-left: -10px !important">
                                  <p-checkbox
                                    [(ngModel)]="mun.temTomada"
                                    [binary]="true"
                                    inputId="temTomadas"
                                    label="Tomadas"
                                    (onChange)="onCheckboxTemTomadas(mun)">
                                  </p-checkbox>
                                </div>
                                <div>
                                  <p-checkbox
                                    [(ngModel)]="mun.temPrestada"
                                    [binary]="true"
                                    inputId="temPrestadas"
                                    label="Prestadas"
                                    (onChange)="onCheckboxTemPrestadas(mun)">
                                  </p-checkbox>
                                </div>
                              </div>
                            </div>

                            <div style="width: 45px;">
                              <p-button
                                (onClick)="removeMunicipio(mun)"
                                icon="pi pi-trash"
                                styleClass="p-button-rounded p-button-text"
                                [disabled]="somenteVisualizar">
                              </p-button>
                            </div>

                          </div>
                        </div>
                    </p-card>
                    </div>
                  </div>
                </div>
            </p-scrollPanel>
          </div>
        </div>
      </div>
    </p-tabPanel>

  </p-tabView>
  <div class="w-100 d-flex justify-content-end me-4" style="margin-top: -35px;">
    <div class="ms-4">
      <button (click)="clickSalvar()" pButton icon="pi pi-save" label="Salvar" class="p-button-primary botao-custom" [disabled]="activeButtonSalvar()"></button>
    </div>
    <div *ngIf="showBotaoCancelar" class="ms-4">
      <button pButton (click)="close()" label="Cancelar" class="p-button-text ms-3"></button>
    </div>
  </div>
</div>

<!-- Modal que exibe os campos que precisam ser preenchidos -->
<p-dialog
  class="modal-header-display-flex"
  header="Dados necessários para {{municipioSelecionado?.municipio}}"
  [modal]="true"
  [(visible)]="showModalPreencherDados"
  [style]="{ width: '590px', height: '600px' }"
  [draggable]="false"
  [resizable]="false">

  <p class="ms-2">
    Preencha os dados necessários para busca de notas na prefeitura de {{municipioSelecionado?.municipio}}.
  </p>

  <!-- Dados de login para o portal da prefeitura -->
  <div>
    <p-card
      header="{{municipioSelecionado?.requerLoginSite ? 'Dados para login no portal da prefeitura' : ''}}"
      [style]="{ 'box-shadow': 'none' }">
      <div class="m-4" *ngIf="municipioSelecionado?.requerLoginSite">
        <span class="p-float-label">
          <input pInputText id="login"
            [(ngModel)]="login"
            [style]="{width: '350px'}"
            maxlength="50" />
          <label htmlFor="login">Login</label>
        </span>
        <span class="p-float-label" style="margin-top: 28px;">
          <input pInputText id="senha"
            [(ngModel)]="senha"
            [style]="{width: '350px'}"
            maxlength="100"/>
          <label htmlFor="senha">Senha</label>
        </span>
      </div>
    </p-card>
  </div>

  <!-- Inscrição Municipal -->
  <div>
    <p-card
      header="{{municipioSelecionado?.requerInscricaoMunicipal ? 'Inscrição Municipal' : ''}}"
      [style]="{ 'box-shadow': 'none' }">
      <div class="m-4 mb-0 mt-0" *ngIf="municipioSelecionado?.requerInscricaoMunicipal">
        <span class="p-float-label">
          <input pInputText #inputInscricaoBox id="inscricaoMunicipal"
            [ngClass]="{'ng-invalid': !isValidInscricaoMunicipal}"
            (input)="validateInscricaoMunicipal(inputInscricaoBox.value)"
            [(ngModel)]="inscricaoMunicipal"
            [style]="{width: '350px'}"
            maxlength="50"/>
        </span>
        <div style="height: 20px;">
          <small>
            <span *ngIf="!isValidInscricaoMunicipal" style="color: red;">Insira apenas letras ou números {{isValidInscricaoMunicipal}}</span>
          </small>
        </div>
      </div>
    </p-card>
  </div>

  <!-- Lista prestadores -->
  <div>
    <p-card
      header="{{municipioSelecionado?.requerListaPrestadores ? 'Prestadores' : ''}}"
      [style]="{ 'box-shadow': 'none' }">
      <div style="margin-top: -3rem"></div>
      <div style="text-align: end; width: 28.4rem;" class="m-4 mb-0 mt-0" *ngIf="municipioSelecionado?.requerListaPrestadores">
        <p-button
          icon="pi pi-plus"
          [rounded]="true"
          [outlined]="true"
          size="small"
          [style]="{width: '5px', height: '5px', 'margin-bottom': '1rem'}"
          (click)="addNovoPrestador()"
          pTooltip="Adiciona dados de prestador à lista"
          showDelay="500">
        </p-button>
        <div class="d-flex flex-row mt-2" *ngFor="let prestador of prestadores; let i = index">
          <div>
            <input pInputText
              id="cnpj-{{i}}"
              [(ngModel)]="prestador.cnpj"
              [style]="{width: '11rem'}"
              [ngClass]="{'invalido': !validaCnpjPrestador(prestador.cnpj!)}"
              mask="00.000.000/0000-00"
              placeholder="CNPJ"
              autocomplete="off"/>
          </div>
          <div class="ms-3">
            <input pInputText
              id="inscricaoMunicipal-{{i}}"
              [(ngModel)]="prestador.inscricaoMunicipal"
              [style]="{width: '14rem'}"
              maxlength="14"
              [ngClass]="{'ng-invalid': validaCnpj(prestador.inscricaoMunicipal!)}"
              placeholder="Inscrição Municipal"
              autocomplete="off"/>
          </div>
          <div class="ms-1" *ngIf="prestadores.length > 1">
            <p-button icon="pi pi-trash" [rounded]="true" [text]="true" (click)="removePrestador(prestador)" />
          </div>
        </div>
      </div>
    </p-card>
  </div>

  <ng-template pTemplate="footer">
    <button
      pButton
      label="OK"
      class="p-button-primary botao-custom me-3 mb-2"
      [disabled]="!validaPreenchimentoDados()"
      (click)="atualizaEmpresaDadosLoginNfse()">
    </button>
  </ng-template>

</p-dialog>
