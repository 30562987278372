import { Page } from '../model/page.model';
import { environment } from '../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { ClientesPagedRequest } from 'src/model/clientes-paged-request.model';
import { Cliente } from 'src/model/cliente.model';
import { MessageResponse } from 'src/model/message-response.model';

const ENDPOINT_CLIENTES = environment.API_MAIN + "/clientes";
const ENDPOINT_CLIENTE = environment.API_MAIN + "/cliente";

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(private http: HttpClient) {}

  /*
    Obtem lista paginada de clientes pelos filtros passados
  */
  public getClientesByFilters(request: ClientesPagedRequest): Observable<Page<Cliente>> {
    const url = ENDPOINT_CLIENTES + "?" + this.getParams(request).toString();
    return this.http.get<Page<Cliente>>(url);
  }

  /*
    Obtem lista paginada de clientes pelos filtros passados
  */
  public getDadosClienteLogado(): Observable<Cliente> {
    return this.http.get<Cliente>(ENDPOINT_CLIENTE);
  }

  /*
    Cria um novo registro de cliente
  */
  public criaCliente(cliente: Cliente): Observable<MessageResponse> {
    let formData: FormData = new FormData();
    formData.append('cliente', new Blob([JSON.stringify(cliente)], { type: 'application/json' }));
    return this.http.post<MessageResponse>(ENDPOINT_CLIENTE, formData);
  }

  /*
    Verifica se existe um cadastro com o login passado
  */
  public verificaLoginExistente(clienteLogin: String): Observable<boolean> {
    const url = ENDPOINT_CLIENTE + "/verifica-login-existente?login=" + clienteLogin;
    return this.http.get<boolean>(url);
  }

  /*
    Atualiza um registro de cliente
  */
  public atualizaCliente(cliente: Cliente | undefined): Observable<MessageResponse> {
    let formData: FormData = new FormData();
    formData.append('cliente', new Blob([JSON.stringify(cliente)], { type: 'application/json' }));
    return this.http.put<MessageResponse>(ENDPOINT_CLIENTE, formData);
  }

  /*
    Atualiza varios registros de clientes de uma vez
  */
    public atualizaClientes(clientes: Cliente[] | undefined): Observable<MessageResponse> {
      let formData: FormData = new FormData();
      formData.append('clientes', new Blob([JSON.stringify(clientes)], { type: 'application/json' }));
      return this.http.put<MessageResponse>(ENDPOINT_CLIENTES, formData);
    }

  private getParams(request: Object): URLSearchParams {
    let params = new URLSearchParams();
    const keys = Object.keys(request);
    const values = Object.values(request);
    for (let i = 0; i < keys.length; i++) {
        if (values[i] instanceof Date) {
            params.set(keys[i], new Date(values[i]).toISOString());
        } else {
            params.set(keys[i], values[i]);
        }
    }
    return params;
  }
}
