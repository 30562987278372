export class NotificacaoModel {

  id: string | undefined;
  tipoMensagem!: string;
  clienteLogin: string | undefined;
  empresaCnpj: string | undefined;
  lida: boolean = false;
  modulo: string | undefined;
  titulo: string| undefined;
  mensagem: string | undefined;
	dataRegistro?: Date;
}
