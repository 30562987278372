import { NfseComponent } from './pages/main/nfse/nfse.component';
import { MinhasEmpresasComponent } from './pages/main/minhas-empresas/minhas-empresas.component';
import { RedefinicaoSenhaComponent } from './pages/redefinicao-senha/redefinicao-senha.component';
import { AuthGuard } from './guards/auth.guard';
import { DfeComponent } from './pages/main/dfe/dfe.component';
import { MainComponent } from './pages/main/main.component';
import { LoginComponent } from './pages/login/login.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CteComponent } from './pages/main/cte/cte.component';
import { MinhaContaComponent } from './pages/main/minha-conta/minha-conta.component';
import { RelatoriosComponent } from './pages/main/relatorios/relatorios.component';
import { CadastroTodasEmpresasComponent } from './pages/admin/cadastro-todas-empresas/cadastro-todas-empresas.component';
import { CadastroTodosClientesComponent } from './pages/admin/cadastro-todos-clientes/cadastro-todos-clientes.component';
import { CfeComponent } from './pages/main/cfe/cfe.component';
import { PortalNacionalComponent } from './pages/main/portal-nacional-nfse/portal-nacional.component';
import { DashboardComponent } from './pages/main/dashboard/dashboard.component';
import { ImportarExportarXmlComponent } from './pages/main/importar-exportar-xml/importar-exportar-xml.component';
import { MonitorEmissaoNfe } from 'src/model/monitor-emissao-nfe.model';

export const routes: Routes = [
  {
    path: '', component: MainComponent, canActivate: [AuthGuard], children: [
      { path: 'nfse', component: NfseComponent, canActivate: [AuthGuard] },
      { path: 'dfe', component: DfeComponent, canActivate: [AuthGuard] },
      { path: 'cte', component: CteComponent, canActivate: [AuthGuard] },
      { path: 'cfe', component: CfeComponent, canActivate: [AuthGuard] },
      { path: 'portal-nfse', component: PortalNacionalComponent, canActivate: [AuthGuard] },
      { path: 'importar-exportar-xml', component: ImportarExportarXmlComponent, canActivate: [AuthGuard] },
      { path: 'relatorios', component: RelatoriosComponent, canActivate: [AuthGuard] },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      { path: 'minhas-empresas', component: MinhasEmpresasComponent, canActivate: [AuthGuard] },
      { path: 'minha-conta', component: MinhaContaComponent, canActivate: [AuthGuard] },
      { path: 'cadastro-clientes', component: CadastroTodosClientesComponent, canActivate: [AuthGuard] },  // Somente admin pode acessar
      { path: 'cadastro-empresas', component: CadastroTodasEmpresasComponent, canActivate: [AuthGuard] },  // Somente admin pode acessar
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'redefinicao-senha', component: RedefinicaoSenhaComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
