<div class="d-flex flex-row ">
  <div>
    <div (click)="togglePanelNotifications()" id="notification-button" class="button-notification me-4">
      <div class="me-2 mb-2">
        <p-badge [value]="qtdNovasNotificacoes" [styleClass]="qtdNovasNotificacoes ? 'p-badge-warning': 'p-overlay-danger'"></p-badge>
        <i class="pi pi-bell"></i>
      </div>
    </div>

    <div id="notification-list" class="p-listbox notification-list notification" [ngStyle]="{'display': esconderNotificacoes ? 'none': 'block'}">
      <ul *ngFor="let item of notificacoes" class="p-listbox-list mb-0 notification">
        <li class="p-listbox-item p-ripple shadow-sm notification"
          [ngClass]="{'tipo-msg-erro': item.tipoMensagem == 'ERRO', 'tipo-msg-aviso': item.tipoMensagem == 'AVISO', 'tipo-msg-sucesso': item.tipoMensagem == 'SUCESSO'}">
          <div class="d-flex flex-row notification">
            <div class="notification-module notification">
              <b class="notification" [ngStyle]="{'font-size': item.modulo!.length >= 8 ? '11px' : '13px'}">{{item.modulo}}</b>
            </div>
            <div class="notification w-100">
              <div class="notification"><b>{{item.titulo}}</b></div>
              <hr *ngIf="item.mensagem">
              <div class="notification mb-1" [innerHTML]="item.mensagem"></div>
              <div class="notification" *ngIf="item.empresaCnpj"><b>CNPJ: </b><b class="notification" style="color: rgb(155, 155, 155);">{{formatCnpjCpf(item.empresaCnpj)}}</b></div>
              <div class="notification notification-time d-flex justify-content-end w-auto text-black-50">{{obterTempoCorrido(item.dataRegistro)}}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
