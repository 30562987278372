<div *ngIf="isEnableNfePage; else notEnabledNfe">
  <div class="container">
    <div class="container-width">
      <!-- TITULO -->
      <div class="d-flex flex-row align-items-baseline" style="margin-top: -10px;">
        <div class="flex-grow-1">
          <div style="width: 200px;">
            <h4 style="line-height: 2">
              NF-e
            </h4>
          </div>
        </div>
        <div style="margin-top: -10px;">
          <button pButton class="p-button-rounded p-button-text me-5 ms-4 pt-2" pTooltip="Recarrega dados da tabela" [showDelay]="500" (click)="refresh()">
            <i class="pi pi-refresh"></i>
          </button>
        </div>
      </div>

      <!-- Página CAPTURA DE NOTAS-->
      <app-captura-nfe #capturaNfeComponent
        *ngIf="selectedContextoPagina == contextoSistema.CAPTURA">
      </app-captura-nfe>

      <!-- Página EMISSÃO DE NOTAS-->
      <app-emissao-nfe #emissaoNfeComponent
        *ngIf="selectedContextoPagina == contextoSistema.EMISSAO">
      </app-emissao-nfe>
    </div>
  </div>
</div>

<ng-template #notEnabledNfe>
  <div class="centered-container">
    <div class="centered-content">
      <img src="assets/images/danger.png" alt="Danger">
      <p *ngIf="selectedContextoPagina == contextoSistema.CAPTURA">Módulo <b>Captura NF-e</b> não habilitado para essa empresa</p>
      <p *ngIf="selectedContextoPagina == contextoSistema.EMISSAO">Módulo <b>Emissão NF-e</b> não habilitado para essa empresa</p>
    </div>
  </div>
</ng-template>

<p-toast position="bottom-center"></p-toast>
