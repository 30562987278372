
<!-- Certificado Digital -->
<div class="dados-certificado">
  <label *ngIf="certificado?.validoAte" class="block">{{label}}</label>
  <label *ngIf="!certificado?.validoAte" class="block">Sem certificado</label>
  <div class="d-flex justify-content-between mt-2 p-1">
    <div>
      <label class="fw-bold text-black-50">Válido a partir de</label><br>
      <span class="border border-1 border-white" style="height: 1rem;">
        {{certificado ? (certificado.validoAPartirDe | date: 'dd/MM/yyyy HH:mm') : ''}}
      </span>
    </div>
    <div>
      <label class="fw-bold text-black-50">Válido até</label><br>
      <span>
        {{certificado ? (certificado.validoAte | date: 'dd/MM/yyyy HH:mm') : ''}}
      </span>
    </div>
    <div>
      <button
        pButton
        icon="pi pi-plus"
        label="Certificado"
        class="p-button-outlined p-button-sm"
        pTooltip="Adiciona o certificado digital"
        [showDelay]="500"
        (click)="openModalCertificado()"
        [disabled]="somenteVisualizar">
      </button>
      <p-button
        class="botao-remove"
        icon="pi pi-trash"
        [rounded]="true"
        [text]="true"
        severity="info"
        pTooltip="Remove o certificado da empresa"
        [showDelay]="500"
        (click)="removeCertificado()"
        [disabled]="somenteVisualizar">
      </p-button>
    </div>
  </div>
</div>

<!-- Modal - Upload Certificado Digital A1 -->
<p-dialog
  header="Upload do Certificado Digital A1"
  [modal]="true"
  [(visible)]="showModalCertificado"
  [breakpoints]="{'960px': '75vw'}"
  [style]="{width: '37vw'}"
  [draggable]="false"
  [resizable]="false"
  [closable]="false">
  <div>
    <div>
      <p-fileUpload #fileUpload
        (multiple)="false"
        accept=".pfx"
        [auto]="true"
        chooseLabel="Escolher certificado"
        (onSelect)="onCarregadoCertificado($event)"
        (onRemove)="onRemoveCertificado()">
      </p-fileUpload>
    </div>
    <div class="field mt-3">
      <label for="certificado-senha">Senha do Certificado:</label>
      <input pInputText type="text" #certificadoSenha id="certificado-senha" name="certificado-senha" style="font-family: 'password'; letter-spacing: 3px; width: 20rem;"
        autocomplete="off" spellcheck="false" [(ngModel)]="senhaCertificado" (ngModelChange)="onChangeSenhaCertificado()">
    </div>
    <div style="height: 1rem;">
      <div id="mensagem-erro-preenchimento" class="mensagem-erro">Certificado e senha devem ser preenchidos</div>
    </div>
    <div style="height: 1rem;">
      <div id="mensagem-erro-senha" class="mensagem-erro">Senha do certificado está incorreta</div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <button (click)="cancelarCarregarCertificado()" pButton label="Cancelar" class="p-button-text ms-3"></button>
    <button (click)="getDadosCertificado()" pButton label="Validar e confirmar" [disabled]="!(senhaCertificado && certificadoCarregado)" class="p-button-primary botao-custom ms-5"></button>
  </ng-template>
</p-dialog>
