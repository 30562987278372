import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmpresaPlugNotas } from 'src/model/empresa-plugnotas.model';

const API_DANFSE_PADRAO_NACIONAL = environment.API_MAIN + '/plugnotas';

@Injectable({
  providedIn: 'root'
})
export class PlugNotasService {

  constructor(private http: HttpClient) {}

  consultarEmpresa(cnpj: string): Observable<EmpresaPlugNotas> {
    return this.http.get<any>(`${API_DANFSE_PADRAO_NACIONAL}/empresa/${cnpj}`);
  }

  cadastrarEmpresa(empresa: any): Observable<any> {
    return this.http.post<any>(`${API_DANFSE_PADRAO_NACIONAL}/empresa`, empresa);
  }

  atualizarEmpresa(cnpj: string, atualizacoes: any): Observable<any> {
    return this.http.patch<any>(`${API_DANFSE_PADRAO_NACIONAL}/empresa/${cnpj}`, atualizacoes);
  }
}
