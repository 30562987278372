<div class="mt-3">

  <h5 class="mb-4">Monitor de emissão de notas</h5>

  <!-- Tabela com dados -->
  <p-table
    class="table-monitor-emissao"
    stripedRows
    [value]="monitorEmissaoPage.content"
    dataKey="id"
    [loading]="loading"
    [scrollable]="true"
    [expandedRowKeys]="expandedRows"
    scrollHeight="calc(100% - 200px)">
    <!-- HEADER -->
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 4rem"></th>
        <th pSortableColumn="statusEmissao" style="min-width: 90px">
          Status emissão
          <p-sortIcon field="statusEmissao"></p-sortIcon>
        </th>
        <th pSortableColumn="dataEmissao" style="min-width: 90px">
          Data emissão
          <p-sortIcon field="dataEmissao"></p-sortIcon>
        </th>
        <th pSortableColumn="cpfCnpjEmitente" style="min-width: 165px">
          Emitente
          <p-sortIcon field="cpfCnpjEmitente"></p-sortIcon>
        </th>
        <th pSortableColumn="cpfCnpjEmitente" style="min-width: 165px">
          Destinatário
          <p-sortIcon field="cpfCnpjEmitente"></p-sortIcon>
        </th>
        <th pSortableColumn="emitente" style="min-width: auto">
          Nome do Destinatário
          <p-sortIcon field="emitente"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <!-- BODY -->
    <ng-template pTemplate="body" let-item let-expanded="expanded">
      <tr class="table-row-body">
        <td style="padding: 0 .5rem;">
          <p-button
            *ngIf="item.mensagem"
            type="button"
            pRipple
            [pRowToggler]="item"
            [text]="true"
            [rounded]="true"
            [plain]="true"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" />
        </td>
        <td>
          <span [ngClass]="getStatusClass(item.statusEmissao)">
            {{ getStatusNome(item.statusEmissao) }}
          </span>
        </td>
        <td>
          {{ item.dataEmissao | date: 'dd/MM/yyyy' }}
        </td>
        <td>
          {{ item.cpfCnpjEmitente | cpfCnpj }}
        </td>
        <td>
          {{ item.cpfCnpjDestinatario | cpfCnpj }}
        </td>
        <td>
          <span
            class="truncate-text"
            [pTooltip]="item.nomeDestinatario"
            tooltipPosition="bottom"
            tooltipStyleClass="custom-tooltip"
            [tooltipDisabled]="item.nomeDestinatario.length <= textSizeToTruncate">
            {{ item.nomeDestinatario.length > textSizeToTruncate ? (item.nomeDestinatario | slice:0:textSizeToTruncate) + '...' : item.nomeDestinatario }}
          </span>
        </td>
      </tr>
    </ng-template>
    <!-- EXPANDED ROW -->
    <ng-template pTemplate="rowexpansion" let-item>
      <tr style="scale: 0.995;" class="shadow-inset">
        <td colspan="6" style="padding: 1rem 5rem;">
          <table *ngIf="item.statusEmissao == 'ERRO'">
            <tr>
              <td style="width: 70px;"><b>Motivo:</b></td>
              <td>{{item.mensagem}}</td>
            </tr>
          </table>
          <table *ngIf="item.statusEmissao == 'CONCLUIDO'">
            <tr>
              <td style="width: 140px;"><b>Mensagem:</b></td>
              <td>{{ item.mensagem }}</td>
            </tr>
            <tr *ngIf="item.cStat == 100">
              <td><b>Data autorização:</b></td>
              <td>{{ item.dataAutorizacao | date: 'dd/MM/yyyy' }}</td>
            </tr>
            <tr>
              <td><b>Chave de acesso:</b></td>
              <td>{{ item.chaveAcesso }}</td>
            </tr>
            <tr>
              <td><b>Valor:</b></td>
              <td>{{ item.valor | currency: 'BRL' : 'symbol' : '1.2-2' }}</td>
            </tr>
          </table>
        </td>
      </tr>
    </ng-template>
  </p-table>

</div>
