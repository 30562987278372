import { CteResponse } from './../../../../model/cte-response.model';
import { CteService } from './../../../../services/cte.service';
import { AuthService } from './../../../../services/auth.service';
import { DfeCteSefazRequest } from 'src/model/dfe-cte-sefaz-request.model';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MessageService, MenuItem } from 'primeng/api';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { saveAs } from 'file-saver';
import { Page } from 'src/model/page.model';
import { DfeRequest } from 'src/model/dfe-request.model';
import { Cliente } from 'src/model/cliente.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MainComponent } from '../main.component';
import { Periodo } from 'src/model/periodo.model';
import { Empresa } from 'src/model/empresa.model';
import { NotificacoesService } from 'src/services/notificacoes.service';

@Component({
  selector: 'app-cte',
  templateUrl: './cte.component.html',
  styleUrls: ['./cte.component.scss']
})
export class CteComponent implements OnInit, OnDestroy {

  @ViewChild('dt') table!: Table;

  userLogged!: Cliente;

  timeout: any;

  cteRequest: DfeRequest = new DfeRequest();
  pageSizes = [10, 15, 20, 50, 100];
  periodo: Periodo | undefined;

  selectedEmpresa?: Empresa;

  rowsPerPageSelected?: number = 10;
  checkedSelectionAll: boolean = false;

  ctePage: Page<CteResponse> = new Page<CteResponse>();
  selectedRows: any[] = [];

  // Context Menu - Linha da Grid
  selectedCteContextMenu?: CteResponse;
  itemsContextMenu: MenuItem[];

  loading: boolean = false;

  base64Pdf?: any;

  subscriptionEmpresaChange: any;
  subscriptionPeriodoChange: any;
  subscriptionNotificacoes: any;

  isEnableCtePage = true;

  constructor(
    private cteService: CteService,
    private messageService: MessageService,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    private main: MainComponent,
    private notificacoesService: NotificacoesService
) {
    this.base64Pdf = this.domSanitizer.bypassSecurityTrustResourceUrl('');
    this.itemsContextMenu = [
      {label: 'Visualizar nota', icon: 'pi pi-fw pi-eye', command: () => { this.openModalDanfe(this.selectedCteContextMenu?.chaveAcesso!) }},
      {label: 'Baixar XML', icon: 'pi pi-fw pi-download', command: () => { this.downloadCte(this.selectedCteContextMenu?.chaveAcesso!) }}
    ];
  }

  ngOnInit(): void {

    this.ctePage.totalElements = 0;

    this.userLogged = this.authService.getUsuario()!;

    if (this.main.selectedEmpresa) {
      this.selectedEmpresa = this.main.selectedEmpresa;
      this.onChangeEmpresa();
    }

    this.subscriptionEmpresaChange = this.main.empresaChange.subscribe((empresa: Empresa) => {
      this.selectedEmpresa = empresa;
      this.onChangeEmpresa();
      this.isEnableCtePage = empresa.modulos.cteModulo.cteEnable;
    });

    this.subscriptionPeriodoChange = this.main.periodoChange.subscribe(() => {
      this.cteRequest.chaveAcesso = "";
      this.cteRequest.dataInicio = this.main.periodo.dataInicio;
      this.cteRequest.dataFim = this.main.periodo.dataFim;
      this.buscaCteConsultadas(this.cteRequest);
    });

    this.subscriptionNotificacoes = this.notificacoesService.onReceiveNovasNotas.subscribe(notificacao => {
      if (notificacao.modulo == "CT-e" && notificacao.empresaCnpj == this.selectedEmpresa?.id) {
        this.refresh();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptionEmpresaChange?.unsubscribe();
    this.subscriptionPeriodoChange?.unsubscribe();
    this.subscriptionNotificacoes?.unsubscribe();
  }

  onChangeContextOptions() {
    this.onChangeEmpresa();
  }

  removeHighlightContextmenu() {
    $(".p-highlight-contextmenu").removeClass("p-highlight-contextmenu");
  }

  onClickBusca() {
    this.selectedRows = [];
    this.buscaCteConsultadas(this.cteRequest);
  }

  private onChangeEmpresa() {
    this.selectedRows = [];
    this.cteRequest.cnpjCpfDestinatario = this.selectedEmpresa?.id;
    this.cteRequest.cnpjCpfEmitente = undefined;
    this.cteRequest.pageNum = 0;
    if (this.rowsPerPageSelected) {
      this.cteRequest.pageSize = this.rowsPerPageSelected;
    }
    this.buscaCteConsultadas(this.cteRequest);
  }

  onSelectionChange(event: []) {
    this.selectedRows = event;
  }

  onClickFiltrarChaveAcesso() {
    this.cteRequest.chaveAcesso = this.cteRequest.chaveAcesso?.trim();
    this.cteRequest.dataInicio = undefined;
    this.cteRequest.dataFim = undefined;
    this.buscaCteConsultadas(this.cteRequest);
  }

  limpaFiltro(campo: string) {
    (this.cteRequest as any)[campo] = "";
    this.cteRequest.dataInicio = this.main.periodo.dataInicio;
    this.cteRequest.dataFim = this.main.periodo.dataFim;
    this.buscaCteConsultadas(this.cteRequest);
  }

  private buscaCteConsultadas(cteRequest: DfeRequest) {
    if (cteRequest.cnpjCpfDestinatario || cteRequest.cnpjCpfEmitente) {

      if (!this.cteRequest.chaveAcesso) {
        const dataInicio = cteRequest.dataInicio ? cteRequest.dataInicio.toISOString().split('T')[0] : this.main.periodo.dataInicio?.toISOString().split('T')[0];
        const dataFim = cteRequest.dataFim ? cteRequest.dataFim.toISOString().split('T')[0] : this.main.periodo.dataFim?.toISOString().split('T')[0];

        this.cteRequest.dataEmissaoInicioStr = dataInicio;
        this.cteRequest.dataEmissaoFimStr = dataFim;
      } else {
        this.cteRequest.dataEmissaoInicioStr = undefined;
        this.cteRequest.dataEmissaoFimStr = undefined;
      }

      this.loading = true;
      this.cteService.consultaCte(cteRequest).subscribe({
        next: (data) => {
          this.ctePage = data;
          this.loading = false;
        },
        error: (e) => {
          this.showMensagemErro("Erro", e.message);
        }
      });
    }
  }

  public lazyLoad(event: TableLazyLoadEvent) {
    if (event.first != undefined && event.rows != undefined) {
      this.cteRequest.pageNum = event.first / event.rows;
      this.cteRequest.pageSize = event.rows;
      this.buscaCteConsultadas(this.cteRequest);
    }
  }

  refresh() {
    this.buscaCteConsultadas(this.cteRequest);
  }

  public onClickSelectedAllRows() {
    let selection: any = [];
    this.selectedRows.forEach(item => {
      selection.push(item);
    });
    this.selectAllPageItems(selection);
  }

  private selectAllPageItems(selection: any) {
    this.ctePage.content.forEach(cte => {
      const found = this.selectedRows.find(item => cte.chaveAcesso == item.chaveAcesso);
      if (found == undefined) {
        selection.push(cte);
      }
    });
    this.selectedRows = selection;
  }

  downloadCte(chaveAcesso: String) {
    this.cteService.downloadCte(chaveAcesso).subscribe(res => {
      let filename = res.headers.get('content-disposition')?.split('filename=')[1];
      if (res.body) {
        saveAs(res.body, filename);
      }
    });
  }

  downloadCteSelecionadas() {
    let ids: String[] = [];
    this.selectedRows.forEach(item => {
      ids.push(item.chaveAcesso);
    });
    if (ids.length == 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Nenhum registro selecionado contendo NF-e para download.',
        life: 10000
      });
    } else {
      this.cteService.downloadCteLote(ids).subscribe(res => {
        let filename = res.headers.get('content-disposition')?.split('filename=')[1];
        if (res.body) {
          saveAs(res.body, filename);
        }
      });
    }
  }

  consultaWebserviceNfeDistribuicaoCte() {
    let request: DfeCteSefazRequest = new DfeCteSefazRequest();
    if (this.selectedEmpresa?.id) {
      let currentDate: Date = new Date();
      request.dataConsulta = currentDate.toLocaleString();
      request.tpAmb = '1';
      request.cnpj = this.selectedEmpresa.id;
      request.clienteLogin = this.userLogged.login;
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Consulta DF-e',
        detail: 'CNPJ não informado',
        life: 10000
      });
      return
    }
    this.cteService.consultaSefaz(request).subscribe({
      next: () => {
        this.showMensagemInfo("Requisição enviada", "Aguardar a notificação da conclusão do processamento.");
      },
      error: (e) => {
        this.showMensagemErro("Erro", e.message);
      }
    });
  }

  private showMensagemInfo(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'info',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 5000
    });
  }

  private showMensagemErro(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  private showMensagemAlerta(mensagem: string, detalhe: string) {
    this.messageService.add({
      key: 'toastBottomRigth800px',
      severity: 'error',
      summary: '' + mensagem,
      detail: '' + detalhe,
      life: 10000
    });
  }

  private showModalLoading() {
    this.loading = this.main.showModalLoading();
  }

  private hideModalLoading() {
    this.loading = this.main.hideModalLoading();
  }

  showModalDanfe = false;
  showProgressSpinModalDanfe = true;

  openModalDanfe(chaveAcesso: String) {
    this.showModalDanfe = true;
    this.showProgressSpinModalDanfe = true;
    this.base64Pdf = this.domSanitizer.bypassSecurityTrustResourceUrl(this.cteService.generateUrlGetPdf(chaveAcesso));
    setTimeout(() => {
      document.getElementById("pdfViewer")!.onload = () => {
        this.showProgressSpinModalDanfe = false;
      };
    });
  }

  closeModalDanfe() {
    ($('#modal-danfe') as any).modal('hide');
  }

}
