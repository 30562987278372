import { ContextoSistema } from 'src/model/enums/contexto-sistema.enum';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CapturaNfeComponent } from './captura-nfe/captura-nfe.component';
import { MainComponent } from '../main.component';
import { Empresa } from 'src/model/empresa.model';
import { EmissaoNfeComponent } from './emissao-nfe/emissao-nfe.component';

@Component({
  selector: 'app-dfe',
  templateUrl: './dfe.component.html',
  styleUrls: ['./dfe.component.scss'],
})
export class DfeComponent implements OnInit, OnDestroy {

  @ViewChild('capturaNfeComponent') capturaNfeComponent: CapturaNfeComponent | undefined;
  @ViewChild('emissaoNfeComponent') emissaoNfeComponent: EmissaoNfeComponent | undefined;

  selectedContextoPagina: ContextoSistema | undefined;
  contextoSistema = ContextoSistema;

  isEnableNfePage = true;

  onEmpresaChangeSub: any;
  onContextoChangeSub: any;

  constructor(
    private main: MainComponent
   ) { }

  ngOnInit(): void {
    this.selectedContextoPagina = this.main.selectedContextoOption;

    this.onEmpresaChangeSub = this.main.empresaChange.subscribe((empresa: Empresa) => {
      this.isEnableNfePage = true;
      this.definirContextoPagina(empresa);
    });

    this.onContextoChangeSub = this.main.changeContexto.subscribe(contexto => {
      this.selectedContextoPagina = contexto;
      if (this.main.selectedEmpresa) {
        this.definirContextoPagina(this.main.selectedEmpresa);
      }
    });
  }

  private definirContextoPagina(empresa: Empresa) {
    if (this.selectedContextoPagina == ContextoSistema.EMISSAO) {
      this.isEnableNfePage = empresa.modulos.nfeModulo.emissaoEnable;
    } else if (this.selectedContextoPagina == ContextoSistema.CAPTURA) {
      this.isEnableNfePage = empresa.modulos.nfeModulo.capturaEnable;
    }
  }

  refresh() {
    if (this.contextoSistema.CAPTURA == this.selectedContextoPagina) {
      this.capturaNfeComponent?.refresh();
    } else if (this.selectedContextoPagina == ContextoSistema.EMISSAO) {
      this.emissaoNfeComponent?.loadMonitorEmissao(this.main.selectedEmpresa?.id!);
    }
  }

  ngOnDestroy(): void {
    this.onEmpresaChangeSub?.unsubscribe();
    this.onContextoChangeSub?.unsubscribe();
  }
}
