import { DfeRequest } from '../model/dfe-request.model';
import { DFeResponse } from '../model/dfe-response.model';
import { environment } from '../environments/environment';
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Page } from 'src/model/page.model';
import { DfeCteSefazRequest } from 'src/model/dfe-cte-sefaz-request.model';
import { DfeCteSefazResponse } from 'src/model/dfe-cte-sefaz-response.model';
import { UploadRequest } from 'src/model/upload-request';
import { DownloadGeralRequest } from 'src/model/download-notas-geral.request';
import { DanfseApiRequest } from 'src/model/danfse-api-request-model';

const ENDPOINT_CONSULTA_SEFAZ_DFE = environment.API_MAIN + "/dfe/consulta-sefaz";
const ENDPOINT_GET_PDF_NFE = environment.API_MAIN + "/dfe/danfe/pdf/";
const ENDPOINT_CONSULTA_NFE_BAIXADAS = environment.API_MAIN + "/dfe/consulta";
const ENDPOINT_CONSULTA_NFE_RELATORIO = environment.API_MAIN + "/dfe/relatorio";
const ENDPOINT_DOWNLOAD_NSU_CONSULTADAS = environment.API_MAIN + "/dfe/download";
const ENDPOINT_DOWNLOAD_NSU_CONSULTADAS_LOTE = environment.API_MAIN + "/dfe/download-lote";
const ENDPOINT_DOWNLOAD_PACOTE_NFE = environment.API_MAIN + "/dfe/pacote-nfe";
const ENDPOINT_DOWNLOAD_PACOTE_PDF = environment.API_MAIN + "/dfe/pacote-pdf";
const ENDPOINT_UPLOAD_NFE = environment.API_MAIN + "/dfe/upload-xml";
const ENDPOINT_RELATORIO_API = environment.API_MAIN + "/dfe/danfse-api/";

@Injectable({
  providedIn: 'root'
})
export class DfeService {

  constructor(private http: HttpClient) {}

  /*
    Obtem o status da solicitação de consulta NSU de interesse
  */
  public consultaSefaz(request: DfeCteSefazRequest): Observable<DfeCteSefazResponse> {
    let params: URLSearchParams = this.getParams(request);
    const url = `${ENDPOINT_CONSULTA_SEFAZ_DFE}?${params.toString()}`;
    return this.http.get<DfeCteSefazResponse>(url);
  }

  /*
    Obtem a lista de NFe consultadas
  */
  public consultaDFe(request: DfeRequest): Observable<Page<DFeResponse>> {
    let params: URLSearchParams = this.getParams(request);
    const url = `${ENDPOINT_CONSULTA_NFE_BAIXADAS}?${params.toString()}`;
    return this.http.get<Page<DFeResponse>>(url);
  }

  /*
    Obtem a lista de NFe para geração da planilha do relatório
  */
  public consultaDFeRelatorio(request: DfeRequest): Observable<Page<DFeResponse>> {
    let params: URLSearchParams = this.getParams(request);
    const url = `${ENDPOINT_CONSULTA_NFE_RELATORIO}?${params.toString()}`;
    return this.http.get<Page<DFeResponse>>(url);
  }

  /*
    Download de todas as notas fiscais por data
  */
  public downloadPacoteNotasFiscais(request: DownloadGeralRequest): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.post<HttpResponse<Blob>>(ENDPOINT_DOWNLOAD_PACOTE_NFE, request, options);
  }

     /*
    Download de todos os PDFs por data
  */
    public downloadPacotePdfNotasFiscais(request: DownloadGeralRequest): Observable<HttpResponse<Blob>> {
      let options: {} = {
        responseType: 'blob',
        observe: 'response'
      }
      return this.http.post<HttpResponse<Blob>>(ENDPOINT_DOWNLOAD_PACOTE_PDF, request, options);
  }

  /*
    Download da NFe contida no banco de dados
  */
  public downloadDFe(id: String): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.post<HttpResponse<Blob>>(ENDPOINT_DOWNLOAD_NSU_CONSULTADAS, id, options);
  }

  /*
    Download da NFe selecionadas contida no banco de dados
  */
  public downloadDFeLote(ids: String[]): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.post<HttpResponse<Blob>>(ENDPOINT_DOWNLOAD_NSU_CONSULTADAS_LOTE, ids, options);
  }

  /*
    Faz o envio de uma lista de Arquivos
  */
  public sendUploadedXml(request: UploadRequest): Observable<void> {
    const formData = new FormData();
    if (request.arquivos?.length == 0 || request.idEmpresa == undefined || request.clienteLogin == undefined) {
      throw Error("Todos os campos da request devem ser preenchidos")
    } else {
      formData.append('idEmpresa', request.idEmpresa);
      formData.append('clienteLogin', request.clienteLogin);
      for (let i = 0; i < request.arquivos.length; i++) {
        formData.append('arquivos', request.arquivos[i]);
      }
    }
    return this.http.post<void>(ENDPOINT_UPLOAD_NFE, formData);
  }

  /*
    Obtem a nota pela chave de acesso
  */
  public getDanfePdf(chaveAcesso: String): Observable<HttpResponse<Blob>> {
    let options: {} = {
      responseType: 'blob',
      observe: 'response'
    }
    return this.http.get<HttpResponse<Blob>>(ENDPOINT_GET_PDF_NFE + chaveAcesso, options);
  }

  public generateUrlGetDanfePdf(chaveAcesso: String): string {
    return ENDPOINT_GET_PDF_NFE + chaveAcesso + "#zoom=100&view=FitH";
  }

  getParams(request: any): URLSearchParams {
    let params = new URLSearchParams();
    for (var propName in request) {
      if (!(propName == "dataInicio" || propName == "dataFim")) {
        if (request[propName]) {
          params.set(propName, request[propName]);
        }
      }
    }
    return params;
  }
}
